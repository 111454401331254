.jeeta-app .slot-card,
.jeeta-app * .slot-card,
.customModal .slot-card, .customModal * .slot-card {
  width: 100%;
  min-height: 20rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 1rem;
}
.jeeta-app .slot-card:hover,
.jeeta-app * .slot-card:hover,
.customModal .slot-card:hover, .customModal * .slot-card:hover {
  cursor: pointer;
}
.jeeta-app .slot-card .bkg-cover,
.jeeta-app * .slot-card .bkg-cover,
.customModal .slot-card .bkg-cover, .customModal * .slot-card .bkg-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.3);
}
.jeeta-app .slot-card .bkg-cover.CURRENT,
.jeeta-app * .slot-card .bkg-cover.CURRENT,
.customModal .slot-card .bkg-cover.CURRENT, .customModal * .slot-card .bkg-cover.CURRENT {
  z-index: 0;
}
.jeeta-app .slot-card .bkg-cover.FINISHED,
.jeeta-app * .slot-card .bkg-cover.FINISHED,
.customModal .slot-card .bkg-cover.FINISHED, .customModal * .slot-card .bkg-cover.FINISHED {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.jeeta-app .slot-card .classroom,
.jeeta-app .slot-card .subject,
.jeeta-app .slot-card .time,
.jeeta-app * .slot-card .classroom,
.jeeta-app * .slot-card .subject,
.jeeta-app * .slot-card .time,
.customModal .slot-card .classroom,
.customModal .slot-card .subject,
.customModal .slot-card .time, .customModal * .slot-card .classroom,
.customModal * .slot-card .subject,
.customModal * .slot-card .time {
  position: relative;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 0.1rem;
  color: var(--whiteColor);
  z-index: 1;
}
.jeeta-app .slot-card .date,
.jeeta-app * .slot-card .date,
.customModal .slot-card .date, .customModal * .slot-card .date {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--PrimaryColor);
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  border: none;
  color: var(--whiteColor);
  font-size: 1rem;
  font-weight: 500;
}
.jeeta-app .slot-card .slot-status,
.jeeta-app * .slot-card .slot-status,
.customModal .slot-card .slot-status, .customModal * .slot-card .slot-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  border: none;
  color: var(--whiteColor);
  z-index: 3;
  font-size: 1rem;
  font-weight: 500;
}
.jeeta-app .slot-card .slot-status.FINISHED,
.jeeta-app * .slot-card .slot-status.FINISHED,
.customModal .slot-card .slot-status.FINISHED, .customModal * .slot-card .slot-status.FINISHED {
  background-color: var(--finished);
}
.jeeta-app .slot-card .slot-status.CURRENT,
.jeeta-app * .slot-card .slot-status.CURRENT,
.customModal .slot-card .slot-status.CURRENT, .customModal * .slot-card .slot-status.CURRENT {
  background-color: var(--started);
}
.jeeta-app .slot-card .subject.Mathematics,
.jeeta-app * .slot-card .subject.Mathematics,
.customModal .slot-card .subject.Mathematics, .customModal * .slot-card .subject.Mathematics {
  background-color: var(--Mathematics);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .slot-card .subject.Physics,
.jeeta-app * .slot-card .subject.Physics,
.customModal .slot-card .subject.Physics, .customModal * .slot-card .subject.Physics {
  background-color: var(--Physics);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .slot-card .subject.Chemistry,
.jeeta-app * .slot-card .subject.Chemistry,
.customModal .slot-card .subject.Chemistry, .customModal * .slot-card .subject.Chemistry {
  background-color: var(--Chemistry);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .slot-card .matchingSegment,
.jeeta-app * .slot-card .matchingSegment,
.customModal .slot-card .matchingSegment, .customModal * .slot-card .matchingSegment {
  width: 95%;
  max-height: 2.4rem;
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.2rem;
  text-align: center;
  color: var(--textColor);
  background: var(--glass);
  border-radius: 0.5rem;
  font-size: 0.95rem;
  line-height: 1.1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.jeeta-app .slot-card .matchingSegment::-webkit-scrollbar,
.jeeta-app * .slot-card .matchingSegment::-webkit-scrollbar,
.customModal .slot-card .matchingSegment::-webkit-scrollbar, .customModal * .slot-card .matchingSegment::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.jeeta-app .slot-card.Mathematics,
.jeeta-app * .slot-card.Mathematics,
.customModal .slot-card.Mathematics, .customModal * .slot-card.Mathematics {
  background-image: url("../../../Assets/Mathematics.jpg");
}
.jeeta-app .slot-card.Physics,
.jeeta-app * .slot-card.Physics,
.customModal .slot-card.Physics, .customModal * .slot-card.Physics {
  background-image: url("../../../Assets/Physics.jpg");
}
.jeeta-app .slot-card.Chemistry,
.jeeta-app * .slot-card.Chemistry,
.customModal .slot-card.Chemistry, .customModal * .slot-card.Chemistry {
  background-image: url("../../../Assets/Chemistry.jpg");
}
.jeeta-app .slotCardList,
.jeeta-app * .slotCardList,
.customModal .slotCardList, .customModal * .slotCardList {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .slotCardList .message,
.jeeta-app * .slotCardList .message,
.customModal .slotCardList .message, .customModal * .slotCardList .message {
  margin: 1rem;
  color: var(--PrimaryColor);
}
.jeeta-app .slotCardList .slotCards,
.jeeta-app * .slotCardList .slotCards,
.customModal .slotCardList .slotCards, .customModal * .slotCardList .slotCards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  background: var(--cardBG);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 1rem;
}
.jeeta-app .slotCardList .change-page-btns,
.jeeta-app * .slotCardList .change-page-btns,
.customModal .slotCardList .change-page-btns, .customModal * .slotCardList .change-page-btns {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .slotCardList .change-page-btns .left-btn,
.jeeta-app .slotCardList .change-page-btns .right-btn,
.jeeta-app .slotCardList .change-page-btns .page-btns,
.jeeta-app * .slotCardList .change-page-btns .left-btn,
.jeeta-app * .slotCardList .change-page-btns .right-btn,
.jeeta-app * .slotCardList .change-page-btns .page-btns,
.customModal .slotCardList .change-page-btns .left-btn,
.customModal .slotCardList .change-page-btns .right-btn,
.customModal .slotCardList .change-page-btns .page-btns, .customModal * .slotCardList .change-page-btns .left-btn,
.customModal * .slotCardList .change-page-btns .right-btn,
.customModal * .slotCardList .change-page-btns .page-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.85rem;
  /* Circle size */
  height: 1.85rem;
  /* Circle size */
  border-radius: 50%;
  /* Makes the button circular */
  border: none;
  /* Border styling */
  color: var(--whiteColor);
  /* Text color */
  font-size: 0.78rem;
  /* Adjust the font size as needed */
  outline: none;
}
.jeeta-app .slotCardList .change-page-btns .left-btn:hover,
.jeeta-app .slotCardList .change-page-btns .right-btn:hover,
.jeeta-app .slotCardList .change-page-btns .page-btns:hover,
.jeeta-app * .slotCardList .change-page-btns .left-btn:hover,
.jeeta-app * .slotCardList .change-page-btns .right-btn:hover,
.jeeta-app * .slotCardList .change-page-btns .page-btns:hover,
.customModal .slotCardList .change-page-btns .left-btn:hover,
.customModal .slotCardList .change-page-btns .right-btn:hover,
.customModal .slotCardList .change-page-btns .page-btns:hover, .customModal * .slotCardList .change-page-btns .left-btn:hover,
.customModal * .slotCardList .change-page-btns .right-btn:hover,
.customModal * .slotCardList .change-page-btns .page-btns:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
  /* Change background color on hover */
}
.jeeta-app .slotCardList .change-page-btns .left-btn,
.jeeta-app .slotCardList .change-page-btns .right-btn,
.jeeta-app * .slotCardList .change-page-btns .left-btn,
.jeeta-app * .slotCardList .change-page-btns .right-btn,
.customModal .slotCardList .change-page-btns .left-btn,
.customModal .slotCardList .change-page-btns .right-btn, .customModal * .slotCardList .change-page-btns .left-btn,
.customModal * .slotCardList .change-page-btns .right-btn {
  background-color: transparent;
  color: var(--PrimaryColor);
}
.jeeta-app .slotCardList .change-page-btns .left-btn:hover,
.jeeta-app .slotCardList .change-page-btns .right-btn:hover,
.jeeta-app * .slotCardList .change-page-btns .left-btn:hover,
.jeeta-app * .slotCardList .change-page-btns .right-btn:hover,
.customModal .slotCardList .change-page-btns .left-btn:hover,
.customModal .slotCardList .change-page-btns .right-btn:hover, .customModal * .slotCardList .change-page-btns .left-btn:hover,
.customModal * .slotCardList .change-page-btns .right-btn:hover {
  background-color: var(--cardBG);
  color: var(--SecondaryColor);
}
.jeeta-app .slotCardList .change-page-btns .not-current-page-btn,
.jeeta-app * .slotCardList .change-page-btns .not-current-page-btn,
.customModal .slotCardList .change-page-btns .not-current-page-btn, .customModal * .slotCardList .change-page-btns .not-current-page-btn {
  background-color: var(--SecondaryColor);
}
.jeeta-app .slotCardList .change-page-btns .current-page-btn,
.jeeta-app * .slotCardList .change-page-btns .current-page-btn,
.customModal .slotCardList .change-page-btns .current-page-btn, .customModal * .slotCardList .change-page-btns .current-page-btn {
  background-color: var(--PrimaryColor);
}
.jeeta-app .slotCardListFilter,
.jeeta-app * .slotCardListFilter,
.customModal .slotCardListFilter, .customModal * .slotCardListFilter {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 1rem;
  z-index: 1;
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .slotCardListFilter .filterTitle,
.jeeta-app * .slotCardListFilter .filterTitle,
.customModal .slotCardListFilter .filterTitle, .customModal * .slotCardListFilter .filterTitle {
  width: 100%;
  border-radius: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}
.jeeta-app .slotCardListFilter .filterTitle .title,
.jeeta-app * .slotCardListFilter .filterTitle .title,
.customModal .slotCardListFilter .filterTitle .title, .customModal * .slotCardListFilter .filterTitle .title {
  text-align: center;
  color: var(--SecondaryColor);
}
.jeeta-app .slotCardListFilter .filterTitle span,
.jeeta-app * .slotCardListFilter .filterTitle span,
.customModal .slotCardListFilter .filterTitle span, .customModal * .slotCardListFilter .filterTitle span {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  color: var(--blackColor);
  opacity: 0.8;
}
.jeeta-app .slotCardListFilter .filters,
.jeeta-app * .slotCardListFilter .filters,
.customModal .slotCardListFilter .filters, .customModal * .slotCardListFilter .filters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .slotCardListFilter .filters .searchTextInput,
.jeeta-app * .slotCardListFilter .filters .searchTextInput,
.customModal .slotCardListFilter .filters .searchTextInput, .customModal * .slotCardListFilter .filters .searchTextInput {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .slotCardListFilter .filters .searchTextInput .title,
.jeeta-app * .slotCardListFilter .filters .searchTextInput .title,
.customModal .slotCardListFilter .filters .searchTextInput .title, .customModal * .slotCardListFilter .filters .searchTextInput .title {
  padding: 0.35rem 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  color: var(--textColor);
}
.jeeta-app .slotCardListFilter .filters .searchTextInput input,
.jeeta-app * .slotCardListFilter .filters .searchTextInput input,
.customModal .slotCardListFilter .filters .searchTextInput input, .customModal * .slotCardListFilter .filters .searchTextInput input {
  flex-grow: 1;
  border: hsl(0, 0%, 80%) solid 1px;
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: 0.95rem;
}
.jeeta-app .slotCardListFilter .filters .searchTextInput,
.jeeta-app * .slotCardListFilter .filters .searchTextInput,
.customModal .slotCardListFilter .filters .searchTextInput, .customModal * .slotCardListFilter .filters .searchTextInput {
  grid-column: span 2;
}
.jeeta-app .slotCardListFilter .btns,
.jeeta-app * .slotCardListFilter .btns,
.customModal .slotCardListFilter .btns, .customModal * .slotCardListFilter .btns {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media all and (max-width: 1200px) {
  .jeeta-app .slot-card,
  .jeeta-app * .slot-card,
  .customModal .slot-card, .customModal * .slot-card {
    min-height: 17.5rem;
  }
  .jeeta-app .slotCardList .slotCards,
  .jeeta-app * .slotCardList .slotCards,
  .customModal .slotCardList .slotCards, .customModal * .slotCardList .slotCards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media all and (max-width: 860px) {
  .jeeta-app .slot-card,
  .jeeta-app * .slot-card,
  .customModal .slot-card, .customModal * .slot-card {
    min-height: 15rem;
    font-size: 0.95rem;
  }
  .jeeta-app .slot-card .matchingSegment,
  .jeeta-app * .slot-card .matchingSegment,
  .customModal .slot-card .matchingSegment, .customModal * .slot-card .matchingSegment {
    font-size: 0.85rem;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .slot-card,
  .jeeta-app * .slot-card,
  .customModal .slot-card, .customModal * .slot-card {
    min-height: 12.5rem;
  }
  .jeeta-app .slot-card .matchingSegment,
  .jeeta-app * .slot-card .matchingSegment,
  .customModal .slot-card .matchingSegment, .customModal * .slot-card .matchingSegment {
    font-size: 0.8rem;
  }
  .jeeta-app .slotCardListFilter .filterTitle span,
  .jeeta-app * .slotCardListFilter .filterTitle span,
  .customModal .slotCardListFilter .filterTitle span, .customModal * .slotCardListFilter .filterTitle span {
    font-size: 1.35rem;
  }
  .jeeta-app .slotCardListFilter .filters,
  .jeeta-app * .slotCardListFilter .filters,
  .customModal .slotCardListFilter .filters, .customModal * .slotCardListFilter .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  .jeeta-app .slotCardList .change-page-btns,
  .jeeta-app * .slotCardList .change-page-btns,
  .customModal .slotCardList .change-page-btns, .customModal * .slotCardList .change-page-btns {
    gap: 0.3rem;
  }
  .jeeta-app .slotCardList .change-page-btns .btn,
  .jeeta-app * .slotCardList .change-page-btns .btn,
  .customModal .slotCardList .change-page-btns .btn, .customModal * .slotCardList .change-page-btns .btn {
    font-size: 0.72rem;
    margin: 0;
  }
  .jeeta-app .slotCardList .change-page-btns .page-btns,
  .jeeta-app * .slotCardList .change-page-btns .page-btns,
  .customModal .slotCardList .change-page-btns .page-btns, .customModal * .slotCardList .change-page-btns .page-btns {
    width: 1.22rem;
    height: 1.22rem;
    font-size: 0.72rem;
  }
}
@media all and (max-width: 610px) {
  .jeeta-app .slot-card,
  .jeeta-app * .slot-card,
  .customModal .slot-card, .customModal * .slot-card {
    font-size: 0.9rem;
  }
  .jeeta-app slotCardListFilter .filterTitle span,
  .jeeta-app * slotCardListFilter .filterTitle span,
  .customModal slotCardListFilter .filterTitle span, .customModal * slotCardListFilter .filterTitle span {
    font-size: 1.3rem;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .slotCardListFilter .filterTitle span,
  .jeeta-app * .slotCardListFilter .filterTitle span,
  .customModal .slotCardListFilter .filterTitle span, .customModal * .slotCardListFilter .filterTitle span {
    font-size: 1.2rem;
  }
  .jeeta-app .slotCardListFilter .filters,
  .jeeta-app * .slotCardListFilter .filters,
  .customModal .slotCardListFilter .filters, .customModal * .slotCardListFilter .filters {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .jeeta-app .slotCardListFilter .filters .searchTextInput,
  .jeeta-app * .slotCardListFilter .filters .searchTextInput,
  .customModal .slotCardListFilter .filters .searchTextInput, .customModal * .slotCardListFilter .filters .searchTextInput {
    padding: 0;
  }
  .jeeta-app .slotCardListFilter .filters .searchTextInput .title,
  .jeeta-app * .slotCardListFilter .filters .searchTextInput .title,
  .customModal .slotCardListFilter .filters .searchTextInput .title, .customModal * .slotCardListFilter .filters .searchTextInput .title {
    font-size: 0.9rem;
  }
  .jeeta-app .slotCardListFilter .filters .searchTextInput input,
  .jeeta-app * .slotCardListFilter .filters .searchTextInput input,
  .customModal .slotCardListFilter .filters .searchTextInput input, .customModal * .slotCardListFilter .filters .searchTextInput input {
    font-size: 0.9rem;
  }
  .jeeta-app .slotCardListFilter .filters .searchTextInput input input,
  .jeeta-app .slotCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .jeeta-app * .slotCardListFilter .filters .searchTextInput input input,
  .jeeta-app * .slotCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .customModal .slotCardListFilter .filters .searchTextInput input input,
  .customModal .slotCardListFilter .filters .searchTextInput input .css-13cymwt-control, .customModal * .slotCardListFilter .filters .searchTextInput input input,
  .customModal * .slotCardListFilter .filters .searchTextInput input .css-13cymwt-control {
    font-size: 0.9rem;
  }
  .jeeta-app .slotCardList,
  .jeeta-app * .slotCardList,
  .customModal .slotCardList, .customModal * .slotCardList {
    padding: 0.5rem;
  }
  .jeeta-app .slotCardList .slotCards,
  .jeeta-app * .slotCardList .slotCards,
  .customModal .slotCardList .slotCards, .customModal * .slotCardList .slotCards {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
    min-height: 6rem;
  }
  .jeeta-app .slot-card .date,
  .jeeta-app * .slot-card .date,
  .customModal .slot-card .date, .customModal * .slot-card .date {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=SlotCard.css.map */