.jeeta-app .questions,
.jeeta-app .questionCardListModal,
.jeeta-app * .questions,
.jeeta-app * .questionCardListModal,
.customModal .questions,
.customModal .questionCardListModal, .customModal * .questions,
.customModal * .questionCardListModal {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0 0 0;
  width: 100%;
}
.jeeta-app .questions .btn,
.jeeta-app .questionCardListModal .btn,
.jeeta-app * .questions .btn,
.jeeta-app * .questionCardListModal .btn,
.customModal .questions .btn,
.customModal .questionCardListModal .btn, .customModal * .questions .btn,
.customModal * .questionCardListModal .btn {
  color: var(--whiteColor);
  font-weight: 600;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}
.jeeta-app .questions .btn .icon,
.jeeta-app .questionCardListModal .btn .icon,
.jeeta-app * .questions .btn .icon,
.jeeta-app * .questionCardListModal .btn .icon,
.customModal .questions .btn .icon,
.customModal .questionCardListModal .btn .icon, .customModal * .questions .btn .icon,
.customModal * .questionCardListModal .btn .icon {
  align-self: center;
  font-size: 1rem;
}
.jeeta-app .questions #back-btn,
.jeeta-app .questionCardListModal #back-btn,
.jeeta-app * .questions #back-btn,
.jeeta-app * .questionCardListModal #back-btn,
.customModal .questions #back-btn,
.customModal .questionCardListModal #back-btn, .customModal * .questions #back-btn,
.customModal * .questionCardListModal #back-btn {
  margin-top: 2rem;
  transform: scale(1.15);
}
.jeeta-app .questionCardList,
.jeeta-app * .questionCardList,
.customModal .questionCardList, .customModal * .questionCardList {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .questionCardList .message,
.jeeta-app * .questionCardList .message,
.customModal .questionCardList .message, .customModal * .questionCardList .message {
  margin: 1rem;
  color: var(--PrimaryColor);
}
.jeeta-app .questionCardList .questionCards,
.jeeta-app * .questionCardList .questionCards,
.customModal .questionCardList .questionCards, .customModal * .questionCardList .questionCards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  background: var(--cardBG);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 1rem;
  overflow: hidden;
}
.jeeta-app .questionCardList .change-page-btns,
.jeeta-app * .questionCardList .change-page-btns,
.customModal .questionCardList .change-page-btns, .customModal * .questionCardList .change-page-btns {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .questionCardList .change-page-btns .left-btn,
.jeeta-app .questionCardList .change-page-btns .right-btn,
.jeeta-app .questionCardList .change-page-btns .page-btns,
.jeeta-app * .questionCardList .change-page-btns .left-btn,
.jeeta-app * .questionCardList .change-page-btns .right-btn,
.jeeta-app * .questionCardList .change-page-btns .page-btns,
.customModal .questionCardList .change-page-btns .left-btn,
.customModal .questionCardList .change-page-btns .right-btn,
.customModal .questionCardList .change-page-btns .page-btns, .customModal * .questionCardList .change-page-btns .left-btn,
.customModal * .questionCardList .change-page-btns .right-btn,
.customModal * .questionCardList .change-page-btns .page-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.85rem;
  /* Circle size */
  height: 1.85rem;
  /* Circle size */
  border-radius: 50%;
  /* Makes the button circular */
  border: none;
  /* Border styling */
  color: var(--whiteColor);
  /* Text color */
  font-size: 0.78rem;
  /* Adjust the font size as needed */
  outline: none;
}
.jeeta-app .questionCardList .change-page-btns .left-btn:hover,
.jeeta-app .questionCardList .change-page-btns .right-btn:hover,
.jeeta-app .questionCardList .change-page-btns .page-btns:hover,
.jeeta-app * .questionCardList .change-page-btns .left-btn:hover,
.jeeta-app * .questionCardList .change-page-btns .right-btn:hover,
.jeeta-app * .questionCardList .change-page-btns .page-btns:hover,
.customModal .questionCardList .change-page-btns .left-btn:hover,
.customModal .questionCardList .change-page-btns .right-btn:hover,
.customModal .questionCardList .change-page-btns .page-btns:hover, .customModal * .questionCardList .change-page-btns .left-btn:hover,
.customModal * .questionCardList .change-page-btns .right-btn:hover,
.customModal * .questionCardList .change-page-btns .page-btns:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
  /* Change background color on hover */
}
.jeeta-app .questionCardList .change-page-btns .left-btn,
.jeeta-app .questionCardList .change-page-btns .right-btn,
.jeeta-app * .questionCardList .change-page-btns .left-btn,
.jeeta-app * .questionCardList .change-page-btns .right-btn,
.customModal .questionCardList .change-page-btns .left-btn,
.customModal .questionCardList .change-page-btns .right-btn, .customModal * .questionCardList .change-page-btns .left-btn,
.customModal * .questionCardList .change-page-btns .right-btn {
  background-color: transparent;
  color: var(--PrimaryColor);
}
.jeeta-app .questionCardList .change-page-btns .left-btn:hover,
.jeeta-app .questionCardList .change-page-btns .right-btn:hover,
.jeeta-app * .questionCardList .change-page-btns .left-btn:hover,
.jeeta-app * .questionCardList .change-page-btns .right-btn:hover,
.customModal .questionCardList .change-page-btns .left-btn:hover,
.customModal .questionCardList .change-page-btns .right-btn:hover, .customModal * .questionCardList .change-page-btns .left-btn:hover,
.customModal * .questionCardList .change-page-btns .right-btn:hover {
  background-color: var(--cardBG);
  color: var(--SecondaryColor);
}
.jeeta-app .questionCardList .change-page-btns .not-current-page-btn,
.jeeta-app * .questionCardList .change-page-btns .not-current-page-btn,
.customModal .questionCardList .change-page-btns .not-current-page-btn, .customModal * .questionCardList .change-page-btns .not-current-page-btn {
  background-color: var(--SecondaryColor);
}
.jeeta-app .questionCardList .change-page-btns .current-page-btn,
.jeeta-app * .questionCardList .change-page-btns .current-page-btn,
.customModal .questionCardList .change-page-btns .current-page-btn, .customModal * .questionCardList .change-page-btns .current-page-btn {
  background-color: var(--PrimaryColor);
}
.jeeta-app .questionCardListModal,
.jeeta-app * .questionCardListModal,
.customModal .questionCardListModal, .customModal * .questionCardListModal {
  width: 80vw;
  /* or your desired width */
  max-height: 80vh;
  /* or your desired height */
  overflow-y: auto;
  /* display scrollbar only when necessary */
  background-color: var(--whiteColorDeam);
  position: fixed;
  /* make the modal appear above other content */
  top: 50%;
  /* centering the modal vertically */
  left: 50%;
  /* centering the modal horizontally */
  transform: translate(-50%, -50%);
  /* necessary adjustments for centering */
  /* high value to ensure modal is on top */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1rem;
}
.jeeta-app .questionCardListModal #close,
.jeeta-app * .questionCardListModal #close,
.customModal .questionCardListModal #close, .customModal * .questionCardListModal #close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 30px;
}
@media all and (max-width: 1200px) {
  .jeeta-app .questionCardList .message,
  .jeeta-app * .questionCardList .message,
  .customModal .questionCardList .message, .customModal * .questionCardList .message {
    margin: 0.5rem;
  }
  .jeeta-app .questionCardList .questionCards,
  .jeeta-app * .questionCardList .questionCards,
  .customModal .questionCardList .questionCards, .customModal * .questionCardList .questionCards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media all and (max-width: 1000px) {
  .jeeta-app .questionCardListModal,
  .jeeta-app * .questionCardListModal,
  .customModal .questionCardListModal, .customModal * .questionCardListModal {
    width: 90%;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .questions #back-btn,
  .jeeta-app .questionCardListModal #back-btn,
  .jeeta-app * .questions #back-btn,
  .jeeta-app * .questionCardListModal #back-btn,
  .customModal .questions #back-btn,
  .customModal .questionCardListModal #back-btn, .customModal * .questions #back-btn,
  .customModal * .questionCardListModal #back-btn {
    margin-top: 1.5rem;
    transform: scale(1.05);
  }
  .jeeta-app .questions .questionCardList .message,
  .jeeta-app .questionCardListModal .questionCardList .message,
  .jeeta-app * .questions .questionCardList .message,
  .jeeta-app * .questionCardListModal .questionCardList .message,
  .customModal .questions .questionCardList .message,
  .customModal .questionCardListModal .questionCardList .message, .customModal * .questions .questionCardList .message,
  .customModal * .questionCardListModal .questionCardList .message {
    margin: 0.5rem;
  }
  .jeeta-app .questions .questionCardList .questionCards,
  .jeeta-app .questionCardListModal .questionCardList .questionCards,
  .jeeta-app * .questions .questionCardList .questionCards,
  .jeeta-app * .questionCardListModal .questionCardList .questionCards,
  .customModal .questions .questionCardList .questionCards,
  .customModal .questionCardListModal .questionCardList .questionCards, .customModal * .questions .questionCardList .questionCards,
  .customModal * .questionCardListModal .questionCardList .questionCards {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: 250px;
  }
  .jeeta-app .questions .questionCardList .change-page-btns,
  .jeeta-app .questionCardListModal .questionCardList .change-page-btns,
  .jeeta-app * .questions .questionCardList .change-page-btns,
  .jeeta-app * .questionCardListModal .questionCardList .change-page-btns,
  .customModal .questions .questionCardList .change-page-btns,
  .customModal .questionCardListModal .questionCardList .change-page-btns, .customModal * .questions .questionCardList .change-page-btns,
  .customModal * .questionCardListModal .questionCardList .change-page-btns {
    gap: 0.3rem;
  }
  .jeeta-app .questions .questionCardList .change-page-btns .btn,
  .jeeta-app .questionCardListModal .questionCardList .change-page-btns .btn,
  .jeeta-app * .questions .questionCardList .change-page-btns .btn,
  .jeeta-app * .questionCardListModal .questionCardList .change-page-btns .btn,
  .customModal .questions .questionCardList .change-page-btns .btn,
  .customModal .questionCardListModal .questionCardList .change-page-btns .btn, .customModal * .questions .questionCardList .change-page-btns .btn,
  .customModal * .questionCardListModal .questionCardList .change-page-btns .btn {
    font-size: 0.72rem;
    margin: 0;
  }
  .jeeta-app .questions .questionCardList .change-page-btns .page-btns,
  .jeeta-app .questionCardListModal .questionCardList .change-page-btns .page-btns,
  .jeeta-app * .questions .questionCardList .change-page-btns .page-btns,
  .jeeta-app * .questionCardListModal .questionCardList .change-page-btns .page-btns,
  .customModal .questions .questionCardList .change-page-btns .page-btns,
  .customModal .questionCardListModal .questionCardList .change-page-btns .page-btns, .customModal * .questions .questionCardList .change-page-btns .page-btns,
  .customModal * .questionCardListModal .questionCardList .change-page-btns .page-btns {
    width: 1.22rem;
    height: 1.22rem;
    font-size: 0.72rem;
  }
  .jeeta-app .questionCardListModal,
  .jeeta-app * .questionCardListModal,
  .customModal .questionCardListModal, .customModal * .questionCardListModal {
    width: 90%;
    /* or your desired width */
    max-height: 90vh;
    /* or your desired height */
    overflow-y: auto;
    /* display scrollbar only when necessary */
    background-color: var(--whiteColorDeam);
    padding: 1.5rem 1rem;
    gap: 0.5rem;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .questions,
  .jeeta-app * .questions,
  .customModal .questions, .customModal * .questions {
    gap: 0.5rem;
    padding: 0.5rem;
  }
  .jeeta-app .questions .btn .icon,
  .jeeta-app * .questions .btn .icon,
  .customModal .questions .btn .icon, .customModal * .questions .btn .icon {
    font-size: 15px;
  }
  .jeeta-app .questions .questionCardList,
  .jeeta-app * .questions .questionCardList,
  .customModal .questions .questionCardList, .customModal * .questions .questionCardList {
    padding: 0.5rem;
  }
  .jeeta-app .questions .questionCardList .questionCards,
  .jeeta-app * .questions .questionCardList .questionCards,
  .customModal .questions .questionCardList .questionCards, .customModal * .questions .questionCardList .questionCards {
    padding: 0.5rem;
    gap: 0.5rem;
    min-height: 6rem;
  }
}/*# sourceMappingURL=questions.css.map */