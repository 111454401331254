.jeeta-app .members,
.jeeta-app * .members,
.customModal .members, .customModal * .members {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--textColor);
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .members-header,
.jeeta-app * .members-header,
.customModal .members-header, .customModal * .members-header {
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: var(--gradientBkg);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .members-header .title,
.jeeta-app * .members-header .title,
.customModal .members-header .title, .customModal * .members-header .title {
  color: var(--whiteColor);
  text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
  text-align: center;
}
.jeeta-app .members-glass,
.jeeta-app * .members-glass,
.customModal .members-glass, .customModal * .members-glass {
  width: 100%;
  min-height: 50vh;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0.5rem 1rem 0.5rem;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .members-glass .title,
.jeeta-app * .members-glass .title,
.customModal .members-glass .title, .customModal * .members-glass .title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--textColor);
}
.jeeta-app .members-glass .title .memberTypeSelectionDropdown .css-1dimb5e-singleValue,
.jeeta-app * .members-glass .title .memberTypeSelectionDropdown .css-1dimb5e-singleValue,
.customModal .members-glass .title .memberTypeSelectionDropdown .css-1dimb5e-singleValue, .customModal * .members-glass .title .memberTypeSelectionDropdown .css-1dimb5e-singleValue {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--textColor);
}
.jeeta-app .memberList,
.jeeta-app * .memberList,
.customModal .memberList, .customModal * .memberList {
  width: 100%;
  max-height: 80vh;
  color: var(--textColor);
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}
.jeeta-app .membersFilter,
.jeeta-app * .membersFilter,
.customModal .membersFilter, .customModal * .membersFilter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .membersFilter .subjectSelectionDropdown,
.jeeta-app * .membersFilter .subjectSelectionDropdown,
.customModal .membersFilter .subjectSelectionDropdown, .customModal * .membersFilter .subjectSelectionDropdown {
  width: 14rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .membersFilter .subjectSelectionDropdown .title,
.jeeta-app * .membersFilter .subjectSelectionDropdown .title,
.customModal .membersFilter .subjectSelectionDropdown .title, .customModal * .membersFilter .subjectSelectionDropdown .title {
  font-size: 0.87rem;
  font-weight: 600;
}
.jeeta-app .membersFilter .subjectSelectionDropdown .selectionDropdown,
.jeeta-app .membersFilter .subjectSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .membersFilter .subjectSelectionDropdown .selectionDropdown,
.jeeta-app * .membersFilter .subjectSelectionDropdown .selectionDropdownMultiple,
.customModal .membersFilter .subjectSelectionDropdown .selectionDropdown,
.customModal .membersFilter .subjectSelectionDropdown .selectionDropdownMultiple, .customModal * .membersFilter .subjectSelectionDropdown .selectionDropdown,
.customModal * .membersFilter .subjectSelectionDropdown .selectionDropdownMultiple {
  flex-grow: 1;
  font-size: 0.83rem;
}
.jeeta-app .membersFilter .subjectSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .membersFilter .subjectSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .membersFilter .subjectSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .membersFilter .subjectSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .membersFilter .subjectSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .membersFilter .subjectSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric, .customModal * .membersFilter .subjectSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .membersFilter .subjectSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric {
  max-width: 180px;
  overflow: hidden;
}
.jeeta-app .membersFilter .create-btn,
.jeeta-app * .membersFilter .create-btn,
.customModal .membersFilter .create-btn, .customModal * .membersFilter .create-btn {
  border: none;
  color: var(--PrimaryColor);
  background: transparent;
}
.jeeta-app .membersFilter .create-btn:hover,
.jeeta-app * .membersFilter .create-btn:hover,
.customModal .membersFilter .create-btn:hover, .customModal * .membersFilter .create-btn:hover {
  cursor: pointer;
}
.jeeta-app .membersFilter .create-btn .icon,
.jeeta-app * .membersFilter .create-btn .icon,
.customModal .membersFilter .create-btn .icon, .customModal * .membersFilter .create-btn .icon {
  align-self: center;
  font-size: 1.94rem;
}
.jeeta-app .membersTable,
.jeeta-app * .membersTable,
.customModal .membersTable, .customModal * .membersTable {
  position: relative;
  width: 95%;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 100vh;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
.jeeta-app .table-style,
.jeeta-app * .table-style,
.customModal .table-style, .customModal * .table-style {
  width: 100%;
  font-size: 0.85rem;
  box-shadow: 0 0 1.11rem rgba(0, 0, 0, 0.15);
}
.jeeta-app .table-style thead tr,
.jeeta-app * .table-style thead tr,
.customModal .table-style thead tr, .customModal * .table-style thead tr {
  background-color: var(--SecondaryBkg);
  color: var(--textColor);
  text-align: center;
  font-size: 0.9rem;
  /* Horizontally center the text. */
  vertical-align: middle;
  /* Vertically center the text. */
}
.jeeta-app .table-style th,
.jeeta-app .table-style td,
.jeeta-app * .table-style th,
.jeeta-app * .table-style td,
.customModal .table-style th,
.customModal .table-style td, .customModal * .table-style th,
.customModal * .table-style td {
  padding: 10px;
  border-bottom: 1px solid var(--cardBG);
}
.jeeta-app .table-style tbody tr:hover,
.jeeta-app * .table-style tbody tr:hover,
.customModal .table-style tbody tr:hover, .customModal * .table-style tbody tr:hover {
  font-weight: 600;
  background-color: var(--PrimaryBkg);
}
.jeeta-app .table-style tbody tr:nth-of-type(even),
.jeeta-app * .table-style tbody tr:nth-of-type(even),
.customModal .table-style tbody tr:nth-of-type(even), .customModal * .table-style tbody tr:nth-of-type(even) {
  background-color: var(--whiteColorDeam);
}
.jeeta-app .table-style tbody tr:nth-of-type(even):hover,
.jeeta-app * .table-style tbody tr:nth-of-type(even):hover,
.customModal .table-style tbody tr:nth-of-type(even):hover, .customModal * .table-style tbody tr:nth-of-type(even):hover {
  font-weight: 600;
  background-color: var(--PrimaryBkg);
}
.jeeta-app .table-style tbody td,
.jeeta-app * .table-style tbody td,
.customModal .table-style tbody td, .customModal * .table-style tbody td {
  white-space: nowrap;
}
.jeeta-app .table-style tbody td input,
.jeeta-app * .table-style tbody td input,
.customModal .table-style tbody td input, .customModal * .table-style tbody td input {
  width: 100%;
  padding: 0.3rem 0.5rem;
  border: none;
  border-radius: 0.3rem;
}
.jeeta-app .table-style tbody td .batchSelectionDropdown,
.jeeta-app .table-style tbody td .subjectSelectionDropdown,
.jeeta-app * .table-style tbody td .batchSelectionDropdown,
.jeeta-app * .table-style tbody td .subjectSelectionDropdown,
.customModal .table-style tbody td .batchSelectionDropdown,
.customModal .table-style tbody td .subjectSelectionDropdown, .customModal * .table-style tbody td .batchSelectionDropdown,
.customModal * .table-style tbody td .subjectSelectionDropdown {
  width: 100%;
}
.jeeta-app .table-style tbody tr:last-of-type td,
.jeeta-app * .table-style tbody tr:last-of-type td,
.customModal .table-style tbody tr:last-of-type td, .customModal * .table-style tbody tr:last-of-type td {
  border-bottom: 0;
}
.jeeta-app .table-style td:nth-child(2),
.jeeta-app .table-style td:nth-child(5),
.jeeta-app .table-style td:nth-child(6),
.jeeta-app .table-style td:nth-child(7),
.jeeta-app * .table-style td:nth-child(2),
.jeeta-app * .table-style td:nth-child(5),
.jeeta-app * .table-style td:nth-child(6),
.jeeta-app * .table-style td:nth-child(7),
.customModal .table-style td:nth-child(2),
.customModal .table-style td:nth-child(5),
.customModal .table-style td:nth-child(6),
.customModal .table-style td:nth-child(7), .customModal * .table-style td:nth-child(2),
.customModal * .table-style td:nth-child(5),
.customModal * .table-style td:nth-child(6),
.customModal * .table-style td:nth-child(7) {
  text-align: center;
  /* Horizontally center the text. */
  vertical-align: middle;
  /* Vertically center the text. */
}
.jeeta-app .table-style td:nth-child(2) .icon,
.jeeta-app .table-style td:nth-child(5) .icon,
.jeeta-app .table-style td:nth-child(6) .icon,
.jeeta-app .table-style td:nth-child(7) .icon,
.jeeta-app * .table-style td:nth-child(2) .icon,
.jeeta-app * .table-style td:nth-child(5) .icon,
.jeeta-app * .table-style td:nth-child(6) .icon,
.jeeta-app * .table-style td:nth-child(7) .icon,
.customModal .table-style td:nth-child(2) .icon,
.customModal .table-style td:nth-child(5) .icon,
.customModal .table-style td:nth-child(6) .icon,
.customModal .table-style td:nth-child(7) .icon, .customModal * .table-style td:nth-child(2) .icon,
.customModal * .table-style td:nth-child(5) .icon,
.customModal * .table-style td:nth-child(6) .icon,
.customModal * .table-style td:nth-child(7) .icon {
  padding-top: 3px;
  font-size: 1rem;
}
.jeeta-app .memberRegisterModal,
.jeeta-app * .memberRegisterModal,
.customModal .memberRegisterModal, .customModal * .memberRegisterModal {
  max-height: 80vh;
  overflow-y: auto;
  overflow-y: auto;
  background-color: var(--whiteColorDeam);
  padding: 2rem 1rem;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.jeeta-app .memberRegisterModal #close,
.jeeta-app * .memberRegisterModal #close,
.customModal .memberRegisterModal #close, .customModal * .memberRegisterModal #close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 1.66rem;
}
.jeeta-app .memberRegisterModal .register-container,
.jeeta-app * .memberRegisterModal .register-container,
.customModal .memberRegisterModal .register-container, .customModal * .memberRegisterModal .register-container {
  width: 100%;
  padding: 1rem;
  color: var(--textColor);
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 1rem;
}
.jeeta-app .memberRegisterModal .register-container .registerTitle,
.jeeta-app * .memberRegisterModal .register-container .registerTitle,
.customModal .memberRegisterModal .register-container .registerTitle, .customModal * .memberRegisterModal .register-container .registerTitle {
  margin-bottom: 1.5rem;
  font-size: 1.55rem;
}
.jeeta-app .memberRegisterModal .register-container .btn,
.jeeta-app * .memberRegisterModal .register-container .btn,
.customModal .memberRegisterModal .register-container .btn, .customModal * .memberRegisterModal .register-container .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: space-between;
  gap: 0.5rem;
}
.jeeta-app .memberRegisterModal .register-container .btn .icon,
.jeeta-app * .memberRegisterModal .register-container .btn .icon,
.customModal .memberRegisterModal .register-container .btn .icon, .customModal * .memberRegisterModal .register-container .btn .icon {
  align-self: center;
  font-size: 1rem;
}
.jeeta-app .memberRegisterModal .register-container .registerInfoInput,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput,
.customModal .memberRegisterModal .register-container .registerInfoInput, .customModal * .memberRegisterModal .register-container .registerInfoInput {
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .contactInput,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .contactInput,
.customModal .memberRegisterModal .register-container .registerInfoInput .contactInput, .customModal * .memberRegisterModal .register-container .registerInfoInput .contactInput {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .contactInput input,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .contactInput input,
.customModal .memberRegisterModal .register-container .registerInfoInput .contactInput input, .customModal * .memberRegisterModal .register-container .registerInfoInput .contactInput input {
  width: 350px;
  border: none;
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: 0.87rem;
}
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .contactInput .icon,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .contactInput .icon,
.customModal .memberRegisterModal .register-container .registerInfoInput .contactInput .icon, .customModal * .memberRegisterModal .register-container .registerInfoInput .contactInput .icon {
  color: var(--PrimaryColor);
  font-size: 1.38rem;
  font-weight: 600;
  margin: 0 10px 0 10px;
}
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown,
.customModal .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown, .customModal * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .title,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .title,
.customModal .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .title, .customModal * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .title {
  font-size: 0.87rem;
  font-weight: 600;
}
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdown,
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdown,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdownMultiple,
.customModal .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdown,
.customModal .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdownMultiple, .customModal * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdown,
.customModal * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdownMultiple {
  flex-grow: 1;
  font-size: 0.83rem;
}
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric, .customModal * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .memberRegisterModal .register-container .registerInfoInput .subjectSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric {
  max-width: 180px;
  overflow: hidden;
}
@media all and (max-width: 860px) {
  .jeeta-app .members,
  .jeeta-app * .members,
  .customModal .members, .customModal * .members {
    gap: 0.5rem;
  }
  .jeeta-app .membersFilter,
  .jeeta-app * .membersFilter,
  .customModal .membersFilter, .customModal * .membersFilter {
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media all and (max-width: 800px) {
  .jeeta-app .members,
  .jeeta-app * .members,
  .customModal .members, .customModal * .members {
    padding: 0.5rem;
  }
  .jeeta-app .members-header,
  .jeeta-app * .members-header,
  .customModal .members-header, .customModal * .members-header {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .members-header,
  .jeeta-app * .members-header,
  .customModal .members-header, .customModal * .members-header {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  .jeeta-app .members-glass,
  .jeeta-app * .members-glass,
  .customModal .members-glass, .customModal * .members-glass {
    margin-top: 0;
    font-size: 1rem;
    padding: 1rem 0;
  }
  .jeeta-app .members-glass .title .memberTypeSelectionDropdown .css-1dimb5e-singleValue,
  .jeeta-app * .members-glass .title .memberTypeSelectionDropdown .css-1dimb5e-singleValue,
  .customModal .members-glass .title .memberTypeSelectionDropdown .css-1dimb5e-singleValue, .customModal * .members-glass .title .memberTypeSelectionDropdown .css-1dimb5e-singleValue {
    font-size: 1.05rem;
  }
}/*# sourceMappingURL=User.css.map */