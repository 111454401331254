.drawer-toggle-button {
  position: absolute;
  top: 50%;
  right: 0;
  width: 2rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 4000;
}/*# sourceMappingURL=zoom.css.map */