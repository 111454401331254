.dashboard-sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 1rem;
  transition: all 300ms ease;
}

/* logo */
.dashboard-logo {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
}
.dashboard-logo span {
  color: var(--PrimaryColor);
}
.dashboard-logo span span {
  color: var(--SecondaryColor);
}
.dashboard-logo img {
  width: 70%;
  height: auto;
}

/* menu */
.dashboard-menu {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.dashboard-menu .menuItem {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  height: 2.5rem;
  margin-left: 1rem;
  position: relative;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 1rem;
}
.dashboard-menu .menuItem span {
  white-space: nowrap;
}
.dashboard-menu .menuItem:hover {
  cursor: pointer;
}
.dashboard-menu .active {
  background: var(--SecondaryBkg);
  margin-left: 0;
}
.dashboard-menu .active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--SecondaryColor);
}

@media all and (max-width: 800px) {
  .dashboard-sidebar {
    max-width: 3rem;
    padding-top: 5rem;
  }
  .dashboard-logo span {
    transform: rotate(90deg);
  }
  .dashboard-logo img {
    display: none;
  }
  .dashboard-menu {
    margin-top: 5rem;
    gap: 0;
  }
  .dashboard-menu .menuItem span {
    display: none;
  }
}
@media all and (max-width: 550px) {
  .dashboard-sidebar {
    width: 100%;
    max-width: none;
    flex-direction: row;
    padding: 0.5rem 0 0.5rem 1.5rem;
    gap: 1rem;
  }
  .dashboard-sidebar::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 2px; /* Thickness of the bottom line */
    background: var(--gradientBkg);
  }
  .dashboard-logo span {
    transform: none;
  }
  .dashboard-logo img {
    display: none;
  }
  .dashboard-menu {
    flex-direction: row;
    margin-top: 0;
    gap: 0.5rem;
    color: var(--PrimaryColor);
  }
  .dashboard-menu .menuItem {
    margin-left: 0;
    padding: 0.5rem;
    gap: 0.2rem;
  }
  .dashboard-menu .menuItem .icon {
    font-size: 1.25rem;
  }
  .dashboard-menu .active {
    background: transparent;
    color: var(--SecondaryColor);
    font-weight: 500;
  }
  .dashboard-menu .active span {
    display: block;
  }
  .dashboard-menu .active::before {
    content: "";
    width: 0; /* Full width of the container */
    height: 0; /* Thickness of the bottom line */
    background-color: transparent;
  }
}/*# sourceMappingURL=DashboardSidebar.css.map */