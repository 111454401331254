.jeeta-app .testReport,
.jeeta-app * .testReport,
.customModal .testReport, .customModal * .testReport {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .testReport .testReportTitle,
.jeeta-app * .testReport .testReportTitle,
.customModal .testReport .testReportTitle, .customModal * .testReport .testReportTitle {
  padding: 0.5rem 0;
  font-size: 1.7rem;
}
.jeeta-app .testReport .testReportButtons,
.jeeta-app * .testReport .testReportButtons,
.customModal .testReport .testReportButtons, .customModal * .testReport .testReportButtons {
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;
}
.jeeta-app .testReport .report-container,
.jeeta-app * .testReport .report-container,
.customModal .testReport .report-container, .customModal * .testReport .report-container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--cardBG);
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  gap: 0.5rem;
}
.jeeta-app .testReport .report-container .report-title,
.jeeta-app * .testReport .report-container .report-title,
.customModal .testReport .report-container .report-title, .customModal * .testReport .report-container .report-title {
  flex-direction: row;
  gap: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;
}
.jeeta-app .testReport .report-container .report-items,
.jeeta-app * .testReport .report-container .report-items,
.customModal .testReport .report-container .report-items, .customModal * .testReport .report-container .report-items {
  width: 100%;
  padding-left: 1rem;
  gap: 1px;
}
.jeeta-app .testReport .report-container .report-items .report-item,
.jeeta-app * .testReport .report-container .report-items .report-item,
.customModal .testReport .report-container .report-items .report-item, .customModal * .testReport .report-container .report-items .report-item {
  padding: 0.2rem 0.7rem 1rem 0.7rem;
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  border-radius: 0.5rem;
  color: var(--whiteColor);
  background-color: var(--SecondaryColor);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.jeeta-app .testReport .report-container .report-items .report-item:hover, .jeeta-app .testReport .report-container .report-items .report-item:active,
.jeeta-app * .testReport .report-container .report-items .report-item:hover,
.jeeta-app * .testReport .report-container .report-items .report-item:active,
.customModal .testReport .report-container .report-items .report-item:hover,
.customModal .testReport .report-container .report-items .report-item:active, .customModal * .testReport .report-container .report-items .report-item:hover, .customModal * .testReport .report-container .report-items .report-item:active {
  font-weight: 600;
  cursor: pointer;
  background-color: var(--PrimaryColor);
}
.jeeta-app .testReport .report-container .report-items .report-item.selected,
.jeeta-app * .testReport .report-container .report-items .report-item.selected,
.customModal .testReport .report-container .report-items .report-item.selected, .customModal * .testReport .report-container .report-items .report-item.selected {
  background-color: var(--PrimaryColor);
}
.jeeta-app .testReport .report-table-wrapper,
.jeeta-app * .testReport .report-table-wrapper,
.customModal .testReport .report-table-wrapper, .customModal * .testReport .report-table-wrapper {
  width: 100%;
  max-height: 80vh;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  transform: translateY(-1.25rem);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.jeeta-app .testReport .report-table,
.jeeta-app * .testReport .report-table,
.customModal .testReport .report-table, .customModal * .testReport .report-table {
  width: 100%;
  table-layout: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.jeeta-app .testReport .report-table thead tr,
.jeeta-app * .testReport .report-table thead tr,
.customModal .testReport .report-table thead tr, .customModal * .testReport .report-table thead tr {
  background-color: var(--PrimaryBkg);
  color: var(--textColor);
  text-align: center;
  vertical-align: middle;
}
.jeeta-app .testReport .report-table th,
.jeeta-app .testReport .report-table tr,
.jeeta-app .testReport .report-table td,
.jeeta-app * .testReport .report-table th,
.jeeta-app * .testReport .report-table tr,
.jeeta-app * .testReport .report-table td,
.customModal .testReport .report-table th,
.customModal .testReport .report-table tr,
.customModal .testReport .report-table td, .customModal * .testReport .report-table th,
.customModal * .testReport .report-table tr,
.customModal * .testReport .report-table td {
  text-align: center;
  vertical-align: middle;
  padding: 0.5rem;
  border-bottom: 1px solid var(--cardBG);
}
.jeeta-app .testReport .report-table td .detail-response-row,
.jeeta-app * .testReport .report-table td .detail-response-row,
.customModal .testReport .report-table td .detail-response-row, .customModal * .testReport .report-table td .detail-response-row {
  align-items: center;
  justify-content: center;
}
.jeeta-app .testReport .report-table tbody tr:hover,
.jeeta-app * .testReport .report-table tbody tr:hover,
.customModal .testReport .report-table tbody tr:hover, .customModal * .testReport .report-table tbody tr:hover {
  background-color: var(--whiteColor);
}
.jeeta-app .testReport .report-table tbody tr:nth-of-type(even),
.jeeta-app * .testReport .report-table tbody tr:nth-of-type(even),
.customModal .testReport .report-table tbody tr:nth-of-type(even), .customModal * .testReport .report-table tbody tr:nth-of-type(even) {
  background-color: var(--whiteColorDeam);
}
.jeeta-app .testReport .report-table tbody tr:nth-of-type(even):hover,
.jeeta-app * .testReport .report-table tbody tr:nth-of-type(even):hover,
.customModal .testReport .report-table tbody tr:nth-of-type(even):hover, .customModal * .testReport .report-table tbody tr:nth-of-type(even):hover {
  background-color: var(--whiteColor);
}
.jeeta-app .testReport .report-table tbody td .table-icon,
.jeeta-app * .testReport .report-table tbody td .table-icon,
.customModal .testReport .report-table tbody td .table-icon, .customModal * .testReport .report-table tbody td .table-icon {
  padding-top: 0.1rem;
  font-size: 1.5rem;
  font-weight: 800;
}
.jeeta-app .testReport .report-table tbody td #correct,
.jeeta-app * .testReport .report-table tbody td #correct,
.customModal .testReport .report-table tbody td #correct, .customModal * .testReport .report-table tbody td #correct {
  color: var(--not-started);
}
.jeeta-app .testReport .report-table tbody td #incorrect,
.jeeta-app * .testReport .report-table tbody td #incorrect,
.customModal .testReport .report-table tbody td #incorrect, .customModal * .testReport .report-table tbody td #incorrect {
  color: var(--hard);
}
.jeeta-app .testReport .statistics-section,
.jeeta-app * .testReport .statistics-section,
.customModal .testReport .statistics-section, .customModal * .testReport .statistics-section {
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  transform: translateY(-1.25rem);
  background-color: var(--whiteColor);
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
}
.jeeta-app .testReport .statistics-section .statistics-marks,
.jeeta-app * .testReport .statistics-section .statistics-marks,
.customModal .testReport .statistics-section .statistics-marks, .customModal * .testReport .statistics-section .statistics-marks {
  flex-direction: row;
  gap: 2rem;
}
.jeeta-app .testReport .statistics-section .statistics-buttons,
.jeeta-app * .testReport .statistics-section .statistics-buttons,
.customModal .testReport .statistics-section .statistics-buttons, .customModal * .testReport .statistics-section .statistics-buttons {
  position: fixed;
  top: 1rem;
  z-index: 2000;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}
.jeeta-app .testReport .statistics-section .statistics-buttons .statistics-sort-buttons,
.jeeta-app .testReport .statistics-section .statistics-buttons .statistics-view-buttons,
.jeeta-app * .testReport .statistics-section .statistics-buttons .statistics-sort-buttons,
.jeeta-app * .testReport .statistics-section .statistics-buttons .statistics-view-buttons,
.customModal .testReport .statistics-section .statistics-buttons .statistics-sort-buttons,
.customModal .testReport .statistics-section .statistics-buttons .statistics-view-buttons, .customModal * .testReport .statistics-section .statistics-buttons .statistics-sort-buttons,
.customModal * .testReport .statistics-section .statistics-buttons .statistics-view-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .testReport .statistics-section .statistics-buttons .statistics-sort-buttons .statistics-button,
.jeeta-app .testReport .statistics-section .statistics-buttons .statistics-view-buttons .statistics-button,
.jeeta-app * .testReport .statistics-section .statistics-buttons .statistics-sort-buttons .statistics-button,
.jeeta-app * .testReport .statistics-section .statistics-buttons .statistics-view-buttons .statistics-button,
.customModal .testReport .statistics-section .statistics-buttons .statistics-sort-buttons .statistics-button,
.customModal .testReport .statistics-section .statistics-buttons .statistics-view-buttons .statistics-button, .customModal * .testReport .statistics-section .statistics-buttons .statistics-sort-buttons .statistics-button,
.customModal * .testReport .statistics-section .statistics-buttons .statistics-view-buttons .statistics-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0.5rem;
  border: none;
  border-radius: 0.3rem;
  background-color: var(--SecondaryColor);
  color: var(--whiteColor);
  cursor: pointer;
}
.jeeta-app .testReport .statistics-section .statistics-buttons .statistics-sort-buttons .statistics-button.active,
.jeeta-app .testReport .statistics-section .statistics-buttons .statistics-view-buttons .statistics-button.active,
.jeeta-app * .testReport .statistics-section .statistics-buttons .statistics-sort-buttons .statistics-button.active,
.jeeta-app * .testReport .statistics-section .statistics-buttons .statistics-view-buttons .statistics-button.active,
.customModal .testReport .statistics-section .statistics-buttons .statistics-sort-buttons .statistics-button.active,
.customModal .testReport .statistics-section .statistics-buttons .statistics-view-buttons .statistics-button.active, .customModal * .testReport .statistics-section .statistics-buttons .statistics-sort-buttons .statistics-button.active,
.customModal * .testReport .statistics-section .statistics-buttons .statistics-view-buttons .statistics-button.active {
  background-color: var(--PrimaryColor);
  font-weight: 600;
}
.jeeta-app .testReport #close,
.jeeta-app * .testReport #close,
.customModal .testReport #close, .customModal * .testReport #close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 1.8rem;
}
.jeeta-app .question-wise-charts,
.jeeta-app .student-wise-charts,
.jeeta-app * .question-wise-charts,
.jeeta-app * .student-wise-charts,
.customModal .question-wise-charts,
.customModal .student-wise-charts, .customModal * .question-wise-charts,
.customModal * .student-wise-charts {
  padding-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto; /* Makes the div scrollable in the x-direction */
  overflow-y: hidden; /* Hides the overflow in the y-direction */
}
.jeeta-app .question-wise-charts::-webkit-scrollbar,
.jeeta-app .student-wise-charts::-webkit-scrollbar,
.jeeta-app * .question-wise-charts::-webkit-scrollbar,
.jeeta-app * .student-wise-charts::-webkit-scrollbar,
.customModal .question-wise-charts::-webkit-scrollbar,
.customModal .student-wise-charts::-webkit-scrollbar, .customModal * .question-wise-charts::-webkit-scrollbar,
.customModal * .student-wise-charts::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.jeeta-app .question-wise-charts::-webkit-scrollbar-track,
.jeeta-app .student-wise-charts::-webkit-scrollbar-track,
.jeeta-app * .question-wise-charts::-webkit-scrollbar-track,
.jeeta-app * .student-wise-charts::-webkit-scrollbar-track,
.customModal .question-wise-charts::-webkit-scrollbar-track,
.customModal .student-wise-charts::-webkit-scrollbar-track, .customModal * .question-wise-charts::-webkit-scrollbar-track,
.customModal * .student-wise-charts::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.jeeta-app .question-wise-charts::-webkit-scrollbar-thumb,
.jeeta-app .student-wise-charts::-webkit-scrollbar-thumb,
.jeeta-app * .question-wise-charts::-webkit-scrollbar-thumb,
.jeeta-app * .student-wise-charts::-webkit-scrollbar-thumb,
.customModal .question-wise-charts::-webkit-scrollbar-thumb,
.customModal .student-wise-charts::-webkit-scrollbar-thumb, .customModal * .question-wise-charts::-webkit-scrollbar-thumb,
.customModal * .student-wise-charts::-webkit-scrollbar-thumb {
  background-color: var(--SecondaryBkg);
  border-radius: 0.5rem;
  border: none;
}
.jeeta-app .question-wise-charts-legends,
.jeeta-app .student-wise-charts-legends,
.jeeta-app * .question-wise-charts-legends,
.jeeta-app * .student-wise-charts-legends,
.customModal .question-wise-charts-legends,
.customModal .student-wise-charts-legends, .customModal * .question-wise-charts-legends,
.customModal * .student-wise-charts-legends {
  position: fixed;
  top: 3.5rem;
  z-index: 2000;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .question-wise-charts-legends .question-wise-charts-legend,
.jeeta-app .question-wise-charts-legends .student-wise-charts-legend,
.jeeta-app .student-wise-charts-legends .question-wise-charts-legend,
.jeeta-app .student-wise-charts-legends .student-wise-charts-legend,
.jeeta-app * .question-wise-charts-legends .question-wise-charts-legend,
.jeeta-app * .question-wise-charts-legends .student-wise-charts-legend,
.jeeta-app * .student-wise-charts-legends .question-wise-charts-legend,
.jeeta-app * .student-wise-charts-legends .student-wise-charts-legend,
.customModal .question-wise-charts-legends .question-wise-charts-legend,
.customModal .question-wise-charts-legends .student-wise-charts-legend,
.customModal .student-wise-charts-legends .question-wise-charts-legend,
.customModal .student-wise-charts-legends .student-wise-charts-legend, .customModal * .question-wise-charts-legends .question-wise-charts-legend,
.customModal * .question-wise-charts-legends .student-wise-charts-legend,
.customModal * .student-wise-charts-legends .question-wise-charts-legend,
.customModal * .student-wise-charts-legends .student-wise-charts-legend {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.jeeta-app .question-wise-charts-legends .question-wise-charts-legend .circle-legend,
.jeeta-app .question-wise-charts-legends .student-wise-charts-legend .circle-legend,
.jeeta-app .student-wise-charts-legends .question-wise-charts-legend .circle-legend,
.jeeta-app .student-wise-charts-legends .student-wise-charts-legend .circle-legend,
.jeeta-app * .question-wise-charts-legends .question-wise-charts-legend .circle-legend,
.jeeta-app * .question-wise-charts-legends .student-wise-charts-legend .circle-legend,
.jeeta-app * .student-wise-charts-legends .question-wise-charts-legend .circle-legend,
.jeeta-app * .student-wise-charts-legends .student-wise-charts-legend .circle-legend,
.customModal .question-wise-charts-legends .question-wise-charts-legend .circle-legend,
.customModal .question-wise-charts-legends .student-wise-charts-legend .circle-legend,
.customModal .student-wise-charts-legends .question-wise-charts-legend .circle-legend,
.customModal .student-wise-charts-legends .student-wise-charts-legend .circle-legend, .customModal * .question-wise-charts-legends .question-wise-charts-legend .circle-legend,
.customModal * .question-wise-charts-legends .student-wise-charts-legend .circle-legend,
.customModal * .student-wise-charts-legends .question-wise-charts-legend .circle-legend,
.customModal * .student-wise-charts-legends .student-wise-charts-legend .circle-legend {
  width: 0.8rem;
  height: 0.8rem;
  border: 1px solid;
}
.jeeta-app .question-wise-charts-legends .question-wise-charts-legend #correct,
.jeeta-app .question-wise-charts-legends .student-wise-charts-legend #correct,
.jeeta-app .student-wise-charts-legends .question-wise-charts-legend #correct,
.jeeta-app .student-wise-charts-legends .student-wise-charts-legend #correct,
.jeeta-app * .question-wise-charts-legends .question-wise-charts-legend #correct,
.jeeta-app * .question-wise-charts-legends .student-wise-charts-legend #correct,
.jeeta-app * .student-wise-charts-legends .question-wise-charts-legend #correct,
.jeeta-app * .student-wise-charts-legends .student-wise-charts-legend #correct,
.customModal .question-wise-charts-legends .question-wise-charts-legend #correct,
.customModal .question-wise-charts-legends .student-wise-charts-legend #correct,
.customModal .student-wise-charts-legends .question-wise-charts-legend #correct,
.customModal .student-wise-charts-legends .student-wise-charts-legend #correct, .customModal * .question-wise-charts-legends .question-wise-charts-legend #correct,
.customModal * .question-wise-charts-legends .student-wise-charts-legend #correct,
.customModal * .student-wise-charts-legends .question-wise-charts-legend #correct,
.customModal * .student-wise-charts-legends .student-wise-charts-legend #correct {
  background-color: rgba(75, 192, 192, 0.2);
  border-color: rgb(75, 192, 192);
}
.jeeta-app .question-wise-charts-legends .question-wise-charts-legend #incorrect,
.jeeta-app .question-wise-charts-legends .student-wise-charts-legend #incorrect,
.jeeta-app .student-wise-charts-legends .question-wise-charts-legend #incorrect,
.jeeta-app .student-wise-charts-legends .student-wise-charts-legend #incorrect,
.jeeta-app * .question-wise-charts-legends .question-wise-charts-legend #incorrect,
.jeeta-app * .question-wise-charts-legends .student-wise-charts-legend #incorrect,
.jeeta-app * .student-wise-charts-legends .question-wise-charts-legend #incorrect,
.jeeta-app * .student-wise-charts-legends .student-wise-charts-legend #incorrect,
.customModal .question-wise-charts-legends .question-wise-charts-legend #incorrect,
.customModal .question-wise-charts-legends .student-wise-charts-legend #incorrect,
.customModal .student-wise-charts-legends .question-wise-charts-legend #incorrect,
.customModal .student-wise-charts-legends .student-wise-charts-legend #incorrect, .customModal * .question-wise-charts-legends .question-wise-charts-legend #incorrect,
.customModal * .question-wise-charts-legends .student-wise-charts-legend #incorrect,
.customModal * .student-wise-charts-legends .question-wise-charts-legend #incorrect,
.customModal * .student-wise-charts-legends .student-wise-charts-legend #incorrect {
  background-color: rgba(255, 99, 132, 0.2);
  border-color: rgb(255, 99, 132);
}
.jeeta-app .question-wise-charts-legends .question-wise-charts-legend #partial-correct,
.jeeta-app .question-wise-charts-legends .student-wise-charts-legend #partial-correct,
.jeeta-app .student-wise-charts-legends .question-wise-charts-legend #partial-correct,
.jeeta-app .student-wise-charts-legends .student-wise-charts-legend #partial-correct,
.jeeta-app * .question-wise-charts-legends .question-wise-charts-legend #partial-correct,
.jeeta-app * .question-wise-charts-legends .student-wise-charts-legend #partial-correct,
.jeeta-app * .student-wise-charts-legends .question-wise-charts-legend #partial-correct,
.jeeta-app * .student-wise-charts-legends .student-wise-charts-legend #partial-correct,
.customModal .question-wise-charts-legends .question-wise-charts-legend #partial-correct,
.customModal .question-wise-charts-legends .student-wise-charts-legend #partial-correct,
.customModal .student-wise-charts-legends .question-wise-charts-legend #partial-correct,
.customModal .student-wise-charts-legends .student-wise-charts-legend #partial-correct, .customModal * .question-wise-charts-legends .question-wise-charts-legend #partial-correct,
.customModal * .question-wise-charts-legends .student-wise-charts-legend #partial-correct,
.customModal * .student-wise-charts-legends .question-wise-charts-legend #partial-correct,
.customModal * .student-wise-charts-legends .student-wise-charts-legend #partial-correct {
  background-color: rgba(255, 206, 86, 0.2);
  border-color: rgb(255, 206, 86);
}
.jeeta-app .question-wise-charts-legends .question-wise-charts-legend #no-response,
.jeeta-app .question-wise-charts-legends .student-wise-charts-legend #no-response,
.jeeta-app .student-wise-charts-legends .question-wise-charts-legend #no-response,
.jeeta-app .student-wise-charts-legends .student-wise-charts-legend #no-response,
.jeeta-app * .question-wise-charts-legends .question-wise-charts-legend #no-response,
.jeeta-app * .question-wise-charts-legends .student-wise-charts-legend #no-response,
.jeeta-app * .student-wise-charts-legends .question-wise-charts-legend #no-response,
.jeeta-app * .student-wise-charts-legends .student-wise-charts-legend #no-response,
.customModal .question-wise-charts-legends .question-wise-charts-legend #no-response,
.customModal .question-wise-charts-legends .student-wise-charts-legend #no-response,
.customModal .student-wise-charts-legends .question-wise-charts-legend #no-response,
.customModal .student-wise-charts-legends .student-wise-charts-legend #no-response, .customModal * .question-wise-charts-legends .question-wise-charts-legend #no-response,
.customModal * .question-wise-charts-legends .student-wise-charts-legend #no-response,
.customModal * .student-wise-charts-legends .question-wise-charts-legend #no-response,
.customModal * .student-wise-charts-legends .student-wise-charts-legend #no-response {
  background-color: rgba(54, 162, 235, 0.2);
  border-color: rgb(54, 162, 235);
}
.jeeta-app .question-wise-charts-legends .question-wise-charts-legend .legend-text,
.jeeta-app .question-wise-charts-legends .student-wise-charts-legend .legend-text,
.jeeta-app .student-wise-charts-legends .question-wise-charts-legend .legend-text,
.jeeta-app .student-wise-charts-legends .student-wise-charts-legend .legend-text,
.jeeta-app * .question-wise-charts-legends .question-wise-charts-legend .legend-text,
.jeeta-app * .question-wise-charts-legends .student-wise-charts-legend .legend-text,
.jeeta-app * .student-wise-charts-legends .question-wise-charts-legend .legend-text,
.jeeta-app * .student-wise-charts-legends .student-wise-charts-legend .legend-text,
.customModal .question-wise-charts-legends .question-wise-charts-legend .legend-text,
.customModal .question-wise-charts-legends .student-wise-charts-legend .legend-text,
.customModal .student-wise-charts-legends .question-wise-charts-legend .legend-text,
.customModal .student-wise-charts-legends .student-wise-charts-legend .legend-text, .customModal * .question-wise-charts-legends .question-wise-charts-legend .legend-text,
.customModal * .question-wise-charts-legends .student-wise-charts-legend .legend-text,
.customModal * .student-wise-charts-legends .question-wise-charts-legend .legend-text,
.customModal * .student-wise-charts-legends .student-wise-charts-legend .legend-text {
  font-size: 0.8rem;
}
.jeeta-app .question-wise-table,
.jeeta-app .student-wise-table,
.jeeta-app * .question-wise-table,
.jeeta-app * .student-wise-table,
.customModal .question-wise-table,
.customModal .student-wise-table, .customModal * .question-wise-table,
.customModal * .student-wise-table {
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.85rem;
}
.jeeta-app .question-wise-table thead tr,
.jeeta-app .student-wise-table thead tr,
.jeeta-app * .question-wise-table thead tr,
.jeeta-app * .student-wise-table thead tr,
.customModal .question-wise-table thead tr,
.customModal .student-wise-table thead tr, .customModal * .question-wise-table thead tr,
.customModal * .student-wise-table thead tr {
  background-color: var(--whiteColorDeam);
  color: var(--textColor);
  text-align: center;
  font-size: 0.9rem;
  /* Horizontally center the text. */
  vertical-align: middle;
  /* Vertically center the text. */
}
.jeeta-app .question-wise-table td:nth-child(2),
.jeeta-app .question-wise-table th:nth-child(2),
.jeeta-app .student-wise-table td:nth-child(2),
.jeeta-app .student-wise-table th:nth-child(2),
.jeeta-app * .question-wise-table td:nth-child(2),
.jeeta-app * .question-wise-table th:nth-child(2),
.jeeta-app * .student-wise-table td:nth-child(2),
.jeeta-app * .student-wise-table th:nth-child(2),
.customModal .question-wise-table td:nth-child(2),
.customModal .question-wise-table th:nth-child(2),
.customModal .student-wise-table td:nth-child(2),
.customModal .student-wise-table th:nth-child(2), .customModal * .question-wise-table td:nth-child(2),
.customModal * .question-wise-table th:nth-child(2),
.customModal * .student-wise-table td:nth-child(2),
.customModal * .student-wise-table th:nth-child(2) {
  background-color: rgba(75, 192, 192, 0.2);
}
.jeeta-app .question-wise-table td:nth-child(3),
.jeeta-app .question-wise-table th:nth-child(3),
.jeeta-app .student-wise-table td:nth-child(3),
.jeeta-app .student-wise-table th:nth-child(3),
.jeeta-app * .question-wise-table td:nth-child(3),
.jeeta-app * .question-wise-table th:nth-child(3),
.jeeta-app * .student-wise-table td:nth-child(3),
.jeeta-app * .student-wise-table th:nth-child(3),
.customModal .question-wise-table td:nth-child(3),
.customModal .question-wise-table th:nth-child(3),
.customModal .student-wise-table td:nth-child(3),
.customModal .student-wise-table th:nth-child(3), .customModal * .question-wise-table td:nth-child(3),
.customModal * .question-wise-table th:nth-child(3),
.customModal * .student-wise-table td:nth-child(3),
.customModal * .student-wise-table th:nth-child(3) {
  background-color: rgba(255, 99, 132, 0.2);
}
.jeeta-app .question-wise-table td:nth-child(4),
.jeeta-app .question-wise-table th:nth-child(4),
.jeeta-app .student-wise-table td:nth-child(4),
.jeeta-app .student-wise-table th:nth-child(4),
.jeeta-app * .question-wise-table td:nth-child(4),
.jeeta-app * .question-wise-table th:nth-child(4),
.jeeta-app * .student-wise-table td:nth-child(4),
.jeeta-app * .student-wise-table th:nth-child(4),
.customModal .question-wise-table td:nth-child(4),
.customModal .question-wise-table th:nth-child(4),
.customModal .student-wise-table td:nth-child(4),
.customModal .student-wise-table th:nth-child(4), .customModal * .question-wise-table td:nth-child(4),
.customModal * .question-wise-table th:nth-child(4),
.customModal * .student-wise-table td:nth-child(4),
.customModal * .student-wise-table th:nth-child(4) {
  background-color: rgba(255, 206, 86, 0.2);
}
.jeeta-app .question-wise-table td:nth-child(5),
.jeeta-app .question-wise-table th:nth-child(5),
.jeeta-app .student-wise-table td:nth-child(5),
.jeeta-app .student-wise-table th:nth-child(5),
.jeeta-app * .question-wise-table td:nth-child(5),
.jeeta-app * .question-wise-table th:nth-child(5),
.jeeta-app * .student-wise-table td:nth-child(5),
.jeeta-app * .student-wise-table th:nth-child(5),
.customModal .question-wise-table td:nth-child(5),
.customModal .question-wise-table th:nth-child(5),
.customModal .student-wise-table td:nth-child(5),
.customModal .student-wise-table th:nth-child(5), .customModal * .question-wise-table td:nth-child(5),
.customModal * .question-wise-table th:nth-child(5),
.customModal * .student-wise-table td:nth-child(5),
.customModal * .student-wise-table th:nth-child(5) {
  background-color: rgba(54, 162, 235, 0.2);
}
.jeeta-app .question-wise-table th,
.jeeta-app .question-wise-table td,
.jeeta-app .student-wise-table th,
.jeeta-app .student-wise-table td,
.jeeta-app * .question-wise-table th,
.jeeta-app * .question-wise-table td,
.jeeta-app * .student-wise-table th,
.jeeta-app * .student-wise-table td,
.customModal .question-wise-table th,
.customModal .question-wise-table td,
.customModal .student-wise-table th,
.customModal .student-wise-table td, .customModal * .question-wise-table th,
.customModal * .question-wise-table td,
.customModal * .student-wise-table th,
.customModal * .student-wise-table td {
  padding: 10px;
  border-bottom: 1px solid var(--cardBG);
}
.jeeta-app .question-wise-table tbody tr:hover,
.jeeta-app .student-wise-table tbody tr:hover,
.jeeta-app * .question-wise-table tbody tr:hover,
.jeeta-app * .student-wise-table tbody tr:hover,
.customModal .question-wise-table tbody tr:hover,
.customModal .student-wise-table tbody tr:hover, .customModal * .question-wise-table tbody tr:hover,
.customModal * .student-wise-table tbody tr:hover {
  font-weight: 600;
  background-color: var(--PrimaryBkg);
}
.jeeta-app .question-wise-table tbody td,
.jeeta-app .student-wise-table tbody td,
.jeeta-app * .question-wise-table tbody td,
.jeeta-app * .student-wise-table tbody td,
.customModal .question-wise-table tbody td,
.customModal .student-wise-table tbody td, .customModal * .question-wise-table tbody td,
.customModal * .student-wise-table tbody td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
.jeeta-app .question-wise-table tbody tr:last-of-type td,
.jeeta-app .student-wise-table tbody tr:last-of-type td,
.jeeta-app * .question-wise-table tbody tr:last-of-type td,
.jeeta-app * .student-wise-table tbody tr:last-of-type td,
.customModal .question-wise-table tbody tr:last-of-type td,
.customModal .student-wise-table tbody tr:last-of-type td, .customModal * .question-wise-table tbody tr:last-of-type td,
.customModal * .student-wise-table tbody tr:last-of-type td {
  border-bottom: 0;
}
.jeeta-app .question-wise-table td:nth-child(1),
.jeeta-app .student-wise-table td:nth-child(1),
.jeeta-app * .question-wise-table td:nth-child(1),
.jeeta-app * .student-wise-table td:nth-child(1),
.customModal .question-wise-table td:nth-child(1),
.customModal .student-wise-table td:nth-child(1), .customModal * .question-wise-table td:nth-child(1),
.customModal * .student-wise-table td:nth-child(1) {
  font-weight: 600;
}
.jeeta-app .testReportModal,
.jeeta-app * .testReportModal,
.customModal .testReportModal, .customModal * .testReportModal {
  width: 80%;
  height: 80%;
  max-width: 100%;
  max-height: 80%;
  overflow-y: auto;
  background-color: var(--whiteColorDeam);
  padding: 1.5rem 1rem;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.jeeta-app .chart-container,
.jeeta-app * .chart-container,
.customModal .chart-container, .customModal * .chart-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
  position: relative;
  overflow-x: auto !important; /* Makes the div scrollable in the x-direction */
  overflow-y: hidden !important; /* Hides the overflow in the y-direction */
}
.jeeta-app .chart-container::-webkit-scrollbar,
.jeeta-app * .chart-container::-webkit-scrollbar,
.customModal .chart-container::-webkit-scrollbar, .customModal * .chart-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.jeeta-app .chart-container::-webkit-scrollbar-track,
.jeeta-app * .chart-container::-webkit-scrollbar-track,
.customModal .chart-container::-webkit-scrollbar-track, .customModal * .chart-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.jeeta-app .chart-container::-webkit-scrollbar-thumb,
.jeeta-app * .chart-container::-webkit-scrollbar-thumb,
.customModal .chart-container::-webkit-scrollbar-thumb, .customModal * .chart-container::-webkit-scrollbar-thumb {
  background-color: var(--SecondaryBkg);
  border-radius: 0.5rem;
  border: none;
}
.jeeta-app #general-statistics canvas,
.jeeta-app * #general-statistics canvas,
.customModal #general-statistics canvas, .customModal * #general-statistics canvas {
  width: auto !important;
  min-width: 50% !important;
  max-width: 100% !important;
  height: auto !important;
  max-height: 500px !important;
  margin: auto !important;
}
.jeeta-app .chart-wrapper,
.jeeta-app * .chart-wrapper,
.customModal .chart-wrapper, .customModal * .chart-wrapper {
  width: 500px; /* Adjust based on your layout needs */
  height: 300px; /* Fixed height for each chart */
  margin-bottom: 20px; /* Space between charts */
}
.jeeta-app .chart-title,
.jeeta-app * .chart-title,
.customModal .chart-title, .customModal * .chart-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
@media all and (max-width: 720px) {
  .jeeta-app .testReport .testReportTitle,
  .jeeta-app * .testReport .testReportTitle,
  .customModal .testReport .testReportTitle, .customModal * .testReport .testReportTitle {
    padding-top: 0;
  }
  .jeeta-app .testReport .testReportButtons,
  .jeeta-app * .testReport .testReportButtons,
  .customModal .testReport .testReportButtons, .customModal * .testReport .testReportButtons {
    padding: 0;
  }
  .jeeta-app .testReport .testReportButtons .btn,
  .jeeta-app * .testReport .testReportButtons .btn,
  .customModal .testReport .testReportButtons .btn, .customModal * .testReport .testReportButtons .btn {
    margin-top: 0;
  }
  .jeeta-app .testReport .report-container,
  .jeeta-app * .testReport .report-container,
  .customModal .testReport .report-container, .customModal * .testReport .report-container {
    font-size: 0.9rem;
  }
  .jeeta-app .testReport .report-container .report-title,
  .jeeta-app * .testReport .report-container .report-title,
  .customModal .testReport .report-container .report-title, .customModal * .testReport .report-container .report-title {
    margin-bottom: 1.5rem;
  }
  .jeeta-app .testReport .report-container .report-items .report-item,
  .jeeta-app * .testReport .report-container .report-items .report-item,
  .customModal .testReport .report-container .report-items .report-item, .customModal * .testReport .report-container .report-items .report-item {
    font-size: 0.85rem;
  }
  .jeeta-app .testReport .report-container .statistics-section .statistics-marks,
  .jeeta-app * .testReport .report-container .statistics-section .statistics-marks,
  .customModal .testReport .report-container .statistics-section .statistics-marks, .customModal * .testReport .report-container .statistics-section .statistics-marks {
    gap: 1rem;
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .testReport .report-container .report-title,
  .jeeta-app * .testReport .report-container .report-title,
  .customModal .testReport .report-container .report-title, .customModal * .testReport .report-container .report-title {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .jeeta-app .testReport .report-container .statistics-section .statistics-marks,
  .jeeta-app * .testReport .report-container .statistics-section .statistics-marks,
  .customModal .testReport .report-container .statistics-section .statistics-marks, .customModal * .testReport .report-container .statistics-section .statistics-marks {
    flex-direction: column;
    gap: 0.5rem;
  }
}/*# sourceMappingURL=testReport.css.map */