.jeeta-app .footer,
.jeeta-app * .footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  background: var(--bodyColor);
  color: var(--SecondaryColor);
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .footer-title,
.jeeta-app * .footer-title {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1rem;
}
.jeeta-app .footer-title span,
.jeeta-app * .footer-title span {
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--SecondaryColor);
}
.jeeta-app .footer-title h2,
.jeeta-app * .footer-title h2 {
  font-size: 1.66rem;
  font-style: italic;
  color: var(--PrimaryColor);
}
.jeeta-app .footerDiv,
.jeeta-app * .footerDiv {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--SecondaryColor);
  font-size: 0.8rem;
}
.jeeta-app .footerDiv p,
.jeeta-app * .footerDiv p {
  font-size: 0.78rem;
}
@media all and (max-width: 860px) {
  .jeeta-app .footer .contactDiv,
  .jeeta-app * .footer .contactDiv {
    margin-top: 0.5rem;
    gap: 1rem;
  }
  .jeeta-app .footer .contactDiv .contactText small,
  .jeeta-app * .footer .contactDiv .contactText small {
    font-size: 0.78rem;
  }
  .jeeta-app .footer .contactDiv .contactText h2,
  .jeeta-app * .footer .contactDiv .contactText h2 {
    font-size: 1.38rem;
  }
  .jeeta-app .footer .contactDiv .contactInput,
  .jeeta-app * .footer .contactDiv .contactInput {
    width: 90%;
    gap: 0.5rem;
    font-size: 0.83rem;
  }
  .jeeta-app .footer .contactDiv .contactInput input,
  .jeeta-app * .footer .contactDiv .contactInput input {
    width: 310px;
  }
  .jeeta-app .footer .footerContent,
  .jeeta-app * .footer .footerContent {
    width: 95%;
    padding: 1rem;
  }
  .jeeta-app .footer .footerContent .footerCard,
  .jeeta-app * .footer .footerContent .footerCard {
    padding: 1.5rem 1rem 3.5rem 1rem;
    gap: 0.5rem;
  }
  .jeeta-app .footer .footerContent .footerCard .footerIntro,
  .jeeta-app * .footer .footerContent .footerCard .footerIntro {
    row-gap: 0.3rem;
  }
  .jeeta-app .footer .footerContent .footerCard .footerIntro .logoDiv,
  .jeeta-app * .footer .footerContent .footerCard .footerIntro .logoDiv {
    font-size: 0.87rem;
  }
  .jeeta-app .footer .footerContent .footerCard .footerIntro .logoDiv .icon,
  .jeeta-app * .footer .footerContent .footerCard .footerIntro .logoDiv .icon {
    font-size: 1.27rem;
  }
  .jeeta-app .footer .footerContent .footerCard .footerIntro .footerParagraph,
  .jeeta-app * .footer .footerContent .footerCard .footerIntro .footerParagraph {
    font-size: 0.78rem;
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .footer,
  .jeeta-app * .footer {
    padding: 0.5rem 0.5rem 0.1rem 0.5rem;
    gap: 0.5rem;
  }
  .jeeta-app .footer-title,
  .jeeta-app * .footer-title {
    transform: scale(0.9);
  }
  .jeeta-app .footer-feedback,
  .jeeta-app * .footer-feedback {
    width: 95%;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    gap: 0.5rem;
  }
  .jeeta-app .footer-feedback .footer-question,
  .jeeta-app * .footer-feedback .footer-question {
    font-size: 0.95rem;
  }
  .jeeta-app .footer-feedback .footer-answer .answer-input,
  .jeeta-app * .footer-feedback .footer-answer .answer-input {
    font-size: 0.9rem;
  }
  .jeeta-app .footerDiv,
  .jeeta-app * .footerDiv {
    font-size: 0.68rem;
  }
}
@media screen and (max-width: 450px) {
  .jeeta-app .footerDiv,
  .jeeta-app * .footerDiv {
    flex-direction: column;
  }
}/*# sourceMappingURL=footer.css.map */