.jeeta-app .reading-material-card,
.jeeta-app * .reading-material-card,
.customModal .reading-material-card, .customModal * .reading-material-card {
  width: 100%;
  min-height: 20rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-image: url("../../../Assets/ReadingMaterials.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 1rem;
}
.jeeta-app .reading-material-card:hover,
.jeeta-app * .reading-material-card:hover,
.customModal .reading-material-card:hover, .customModal * .reading-material-card:hover {
  cursor: pointer;
}
.jeeta-app .reading-material-card .bkg-cover,
.jeeta-app * .reading-material-card .bkg-cover,
.customModal .reading-material-card .bkg-cover, .customModal * .reading-material-card .bkg-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.3);
}
.jeeta-app .reading-material-card .material,
.jeeta-app .reading-material-card .subject,
.jeeta-app * .reading-material-card .material,
.jeeta-app * .reading-material-card .subject,
.customModal .reading-material-card .material,
.customModal .reading-material-card .subject, .customModal * .reading-material-card .material,
.customModal * .reading-material-card .subject {
  position: relative;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 0.1rem;
  color: var(--whiteColor);
  z-index: 1;
}
.jeeta-app .reading-material-card .material::first-letter,
.jeeta-app * .reading-material-card .material::first-letter,
.customModal .reading-material-card .material::first-letter, .customModal * .reading-material-card .material::first-letter {
  text-transform: uppercase;
}
.jeeta-app .reading-material-card .subject.Mathematics,
.jeeta-app * .reading-material-card .subject.Mathematics,
.customModal .reading-material-card .subject.Mathematics, .customModal * .reading-material-card .subject.Mathematics {
  background-color: var(--Mathematics);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .reading-material-card .subject.Physics,
.jeeta-app * .reading-material-card .subject.Physics,
.customModal .reading-material-card .subject.Physics, .customModal * .reading-material-card .subject.Physics {
  background-color: var(--Physics);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .reading-material-card .subject.Chemistry,
.jeeta-app * .reading-material-card .subject.Chemistry,
.customModal .reading-material-card .subject.Chemistry, .customModal * .reading-material-card .subject.Chemistry {
  background-color: var(--Chemistry);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .reading-material-card.module,
.jeeta-app * .reading-material-card.module,
.customModal .reading-material-card.module, .customModal * .reading-material-card.module {
  background-image: url("../../../Assets/Modules.jpg");
}
.jeeta-app .reading-material-card.book,
.jeeta-app * .reading-material-card.book,
.customModal .reading-material-card.book, .customModal * .reading-material-card.book {
  background-image: url("../../../Assets/Books.jpg");
}
.jeeta-app .reading-material-card.note,
.jeeta-app * .reading-material-card.note,
.customModal .reading-material-card.note, .customModal * .reading-material-card.note {
  background-image: url("../../../Assets/Notebooks.jpg");
}
.jeeta-app .readingMaterialCardList,
.jeeta-app * .readingMaterialCardList,
.customModal .readingMaterialCardList, .customModal * .readingMaterialCardList {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  animation: fadeUp 1.2s ease-out forwards;
  /* Example duration and timing */
}
.jeeta-app .readingMaterialCardList .message,
.jeeta-app * .readingMaterialCardList .message,
.customModal .readingMaterialCardList .message, .customModal * .readingMaterialCardList .message {
  margin: 1rem;
  color: var(--PrimaryColor);
}
.jeeta-app .readingMaterialCardList .readingMaterialCards,
.jeeta-app * .readingMaterialCardList .readingMaterialCards,
.customModal .readingMaterialCardList .readingMaterialCards, .customModal * .readingMaterialCardList .readingMaterialCards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  background: var(--cardBG);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 1rem;
  overflow: hidden;
}
.jeeta-app .readingMaterialCardList .change-page-btns,
.jeeta-app * .readingMaterialCardList .change-page-btns,
.customModal .readingMaterialCardList .change-page-btns, .customModal * .readingMaterialCardList .change-page-btns {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .readingMaterialCardList .change-page-btns .left-btn,
.jeeta-app .readingMaterialCardList .change-page-btns .right-btn,
.jeeta-app .readingMaterialCardList .change-page-btns .page-btns,
.jeeta-app * .readingMaterialCardList .change-page-btns .left-btn,
.jeeta-app * .readingMaterialCardList .change-page-btns .right-btn,
.jeeta-app * .readingMaterialCardList .change-page-btns .page-btns,
.customModal .readingMaterialCardList .change-page-btns .left-btn,
.customModal .readingMaterialCardList .change-page-btns .right-btn,
.customModal .readingMaterialCardList .change-page-btns .page-btns, .customModal * .readingMaterialCardList .change-page-btns .left-btn,
.customModal * .readingMaterialCardList .change-page-btns .right-btn,
.customModal * .readingMaterialCardList .change-page-btns .page-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.85rem;
  /* Circle size */
  height: 1.85rem;
  /* Circle size */
  border-radius: 50%;
  /* Makes the button circular */
  border: none;
  /* Border styling */
  color: var(--whiteColor);
  /* Text color */
  font-size: 0.78rem;
  /* Adjust the font size as needed */
  outline: none;
}
.jeeta-app .readingMaterialCardList .change-page-btns .left-btn:hover,
.jeeta-app .readingMaterialCardList .change-page-btns .right-btn:hover,
.jeeta-app .readingMaterialCardList .change-page-btns .page-btns:hover,
.jeeta-app * .readingMaterialCardList .change-page-btns .left-btn:hover,
.jeeta-app * .readingMaterialCardList .change-page-btns .right-btn:hover,
.jeeta-app * .readingMaterialCardList .change-page-btns .page-btns:hover,
.customModal .readingMaterialCardList .change-page-btns .left-btn:hover,
.customModal .readingMaterialCardList .change-page-btns .right-btn:hover,
.customModal .readingMaterialCardList .change-page-btns .page-btns:hover, .customModal * .readingMaterialCardList .change-page-btns .left-btn:hover,
.customModal * .readingMaterialCardList .change-page-btns .right-btn:hover,
.customModal * .readingMaterialCardList .change-page-btns .page-btns:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
  /* Change background color on hover */
}
.jeeta-app .readingMaterialCardList .change-page-btns .left-btn,
.jeeta-app .readingMaterialCardList .change-page-btns .right-btn,
.jeeta-app * .readingMaterialCardList .change-page-btns .left-btn,
.jeeta-app * .readingMaterialCardList .change-page-btns .right-btn,
.customModal .readingMaterialCardList .change-page-btns .left-btn,
.customModal .readingMaterialCardList .change-page-btns .right-btn, .customModal * .readingMaterialCardList .change-page-btns .left-btn,
.customModal * .readingMaterialCardList .change-page-btns .right-btn {
  background-color: transparent;
  color: var(--PrimaryColor);
}
.jeeta-app .readingMaterialCardList .change-page-btns .left-btn:hover,
.jeeta-app .readingMaterialCardList .change-page-btns .right-btn:hover,
.jeeta-app * .readingMaterialCardList .change-page-btns .left-btn:hover,
.jeeta-app * .readingMaterialCardList .change-page-btns .right-btn:hover,
.customModal .readingMaterialCardList .change-page-btns .left-btn:hover,
.customModal .readingMaterialCardList .change-page-btns .right-btn:hover, .customModal * .readingMaterialCardList .change-page-btns .left-btn:hover,
.customModal * .readingMaterialCardList .change-page-btns .right-btn:hover {
  background-color: var(--cardBG);
  color: var(--SecondaryColor);
}
.jeeta-app .readingMaterialCardList .change-page-btns .not-current-page-btn,
.jeeta-app * .readingMaterialCardList .change-page-btns .not-current-page-btn,
.customModal .readingMaterialCardList .change-page-btns .not-current-page-btn, .customModal * .readingMaterialCardList .change-page-btns .not-current-page-btn {
  background-color: var(--SecondaryColor);
}
.jeeta-app .readingMaterialCardList .change-page-btns .current-page-btn,
.jeeta-app * .readingMaterialCardList .change-page-btns .current-page-btn,
.customModal .readingMaterialCardList .change-page-btns .current-page-btn, .customModal * .readingMaterialCardList .change-page-btns .current-page-btn {
  background-color: var(--PrimaryColor);
}
.jeeta-app .readingMaterialCardListFilter,
.jeeta-app * .readingMaterialCardListFilter,
.customModal .readingMaterialCardListFilter, .customModal * .readingMaterialCardListFilter {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 1;
  animation: fadeUp 1.2s ease-out forwards;
}
.jeeta-app .readingMaterialCardListFilter .filterTitle,
.jeeta-app * .readingMaterialCardListFilter .filterTitle,
.customModal .readingMaterialCardListFilter .filterTitle, .customModal * .readingMaterialCardListFilter .filterTitle {
  width: 100%;
  border-radius: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}
.jeeta-app .readingMaterialCardListFilter .filterTitle .title,
.jeeta-app * .readingMaterialCardListFilter .filterTitle .title,
.customModal .readingMaterialCardListFilter .filterTitle .title, .customModal * .readingMaterialCardListFilter .filterTitle .title {
  text-align: center;
  color: var(--SecondaryColor);
}
.jeeta-app .readingMaterialCardListFilter .filterTitle span,
.jeeta-app * .readingMaterialCardListFilter .filterTitle span,
.customModal .readingMaterialCardListFilter .filterTitle span, .customModal * .readingMaterialCardListFilter .filterTitle span {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}
.jeeta-app .readingMaterialCardListFilter .checkInput,
.jeeta-app * .readingMaterialCardListFilter .checkInput,
.customModal .readingMaterialCardListFilter .checkInput, .customModal * .readingMaterialCardListFilter .checkInput {
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  font-weight: 600;
  font-size: 0.94rem;
}
.jeeta-app .readingMaterialCardListFilter .checkInput .unattemptedInput,
.jeeta-app * .readingMaterialCardListFilter .checkInput .unattemptedInput,
.customModal .readingMaterialCardListFilter .checkInput .unattemptedInput, .customModal * .readingMaterialCardListFilter .checkInput .unattemptedInput {
  color: var(--peach);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .readingMaterialCardListFilter .checkInput .unattemptedInput .icon,
.jeeta-app * .readingMaterialCardListFilter .checkInput .unattemptedInput .icon,
.customModal .readingMaterialCardListFilter .checkInput .unattemptedInput .icon, .customModal * .readingMaterialCardListFilter .checkInput .unattemptedInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .readingMaterialCardListFilter .checkInput .starInput,
.jeeta-app * .readingMaterialCardListFilter .checkInput .starInput,
.customModal .readingMaterialCardListFilter .checkInput .starInput, .customModal * .readingMaterialCardListFilter .checkInput .starInput {
  color: var(--tiger-lilly);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .readingMaterialCardListFilter .checkInput .starInput .icon,
.jeeta-app * .readingMaterialCardListFilter .checkInput .starInput .icon,
.customModal .readingMaterialCardListFilter .checkInput .starInput .icon, .customModal * .readingMaterialCardListFilter .checkInput .starInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .readingMaterialCardListFilter .checkInput .reviewInput,
.jeeta-app * .readingMaterialCardListFilter .checkInput .reviewInput,
.customModal .readingMaterialCardListFilter .checkInput .reviewInput, .customModal * .readingMaterialCardListFilter .checkInput .reviewInput {
  color: var(--kelly-green);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .readingMaterialCardListFilter .checkInput .reviewInput .icon,
.jeeta-app * .readingMaterialCardListFilter .checkInput .reviewInput .icon,
.customModal .readingMaterialCardListFilter .checkInput .reviewInput .icon, .customModal * .readingMaterialCardListFilter .checkInput .reviewInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .readingMaterialCardListFilter .filters,
.jeeta-app * .readingMaterialCardListFilter .filters,
.customModal .readingMaterialCardListFilter .filters, .customModal * .readingMaterialCardListFilter .filters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .readingMaterialCardListFilter .filters .filterSelect,
.jeeta-app .readingMaterialCardListFilter .filters .searchTextInput,
.jeeta-app * .readingMaterialCardListFilter .filters .filterSelect,
.jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput,
.customModal .readingMaterialCardListFilter .filters .filterSelect,
.customModal .readingMaterialCardListFilter .filters .searchTextInput, .customModal * .readingMaterialCardListFilter .filters .filterSelect,
.customModal * .readingMaterialCardListFilter .filters .searchTextInput {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .readingMaterialCardListFilter .filters .filterSelect .title,
.jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .title,
.jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .title,
.jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .title,
.customModal .readingMaterialCardListFilter .filters .filterSelect .title,
.customModal .readingMaterialCardListFilter .filters .searchTextInput .title, .customModal * .readingMaterialCardListFilter .filters .filterSelect .title,
.customModal * .readingMaterialCardListFilter .filters .searchTextInput .title {
  padding: 0.35rem 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  color: var(--textColor);
}
.jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown,
.jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown,
.jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
.jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown,
.jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown,
.jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
.customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown,
.customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown,
.customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple, .customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown,
.customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown,
.customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple {
  flex-grow: 1;
}
.jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control, .customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control {
  font-size: 0.95rem;
}
.jeeta-app .readingMaterialCardListFilter .filters .filterSelect input,
.jeeta-app .readingMaterialCardListFilter .filters .searchTextInput input,
.jeeta-app * .readingMaterialCardListFilter .filters .filterSelect input,
.jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput input,
.customModal .readingMaterialCardListFilter .filters .filterSelect input,
.customModal .readingMaterialCardListFilter .filters .searchTextInput input, .customModal * .readingMaterialCardListFilter .filters .filterSelect input,
.customModal * .readingMaterialCardListFilter .filters .searchTextInput input {
  flex-grow: 1;
  border: hsl(0, 0%, 80%) solid 1px;
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: 0.95rem;
}
.jeeta-app .readingMaterialCardListFilter .filters .searchTextInput,
.jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput,
.customModal .readingMaterialCardListFilter .filters .searchTextInput, .customModal * .readingMaterialCardListFilter .filters .searchTextInput {
  grid-column: span 2;
}
.jeeta-app .readingMaterialCardListFilter .btns,
.jeeta-app * .readingMaterialCardListFilter .btns,
.customModal .readingMaterialCardListFilter .btns, .customModal * .readingMaterialCardListFilter .btns {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .readingMaterialCardListFilter .btns #filter,
.jeeta-app .readingMaterialCardListFilter .btns #create,
.jeeta-app * .readingMaterialCardListFilter .btns #filter,
.jeeta-app * .readingMaterialCardListFilter .btns #create,
.customModal .readingMaterialCardListFilter .btns #filter,
.customModal .readingMaterialCardListFilter .btns #create, .customModal * .readingMaterialCardListFilter .btns #filter,
.customModal * .readingMaterialCardListFilter .btns #create {
  font-size: 0.83rem;
}
.jeeta-app .readingMaterialCardListFilter .btns #filter .icon,
.jeeta-app .readingMaterialCardListFilter .btns #create .icon,
.jeeta-app * .readingMaterialCardListFilter .btns #filter .icon,
.jeeta-app * .readingMaterialCardListFilter .btns #create .icon,
.customModal .readingMaterialCardListFilter .btns #filter .icon,
.customModal .readingMaterialCardListFilter .btns #create .icon, .customModal * .readingMaterialCardListFilter .btns #filter .icon,
.customModal * .readingMaterialCardListFilter .btns #create .icon {
  font-size: 1.05rem;
}
@media all and (max-width: 1200px) {
  .jeeta-app .reading-material-card,
  .jeeta-app * .reading-material-card,
  .customModal .reading-material-card, .customModal * .reading-material-card {
    min-height: 17.5rem;
  }
  .jeeta-app .readingMaterialCardList .readingMaterialCards,
  .jeeta-app * .readingMaterialCardList .readingMaterialCards,
  .customModal .readingMaterialCardList .readingMaterialCards, .customModal * .readingMaterialCardList .readingMaterialCards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media all and (max-width: 860px) {
  .jeeta-app .reading-material-card,
  .jeeta-app * .reading-material-card,
  .customModal .reading-material-card, .customModal * .reading-material-card {
    min-height: 15rem;
    font-size: 0.95rem;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .reading-material-card,
  .jeeta-app * .reading-material-card,
  .customModal .reading-material-card, .customModal * .reading-material-card {
    min-height: 12.5rem;
  }
  .jeeta-app .readingMaterialCardListFilter .filterTitle span,
  .jeeta-app * .readingMaterialCardListFilter .filterTitle span,
  .customModal .readingMaterialCardListFilter .filterTitle span, .customModal * .readingMaterialCardListFilter .filterTitle span {
    font-size: 1.35rem;
  }
  .jeeta-app .readingMaterialCardListFilter .filters,
  .jeeta-app * .readingMaterialCardListFilter .filters,
  .customModal .readingMaterialCardListFilter .filters, .customModal * .readingMaterialCardListFilter .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  .jeeta-app .readingMaterialCardList .change-page-btns,
  .jeeta-app * .readingMaterialCardList .change-page-btns,
  .customModal .readingMaterialCardList .change-page-btns, .customModal * .readingMaterialCardList .change-page-btns {
    gap: 0.3rem;
  }
  .jeeta-app .readingMaterialCardList .change-page-btns .btn,
  .jeeta-app * .readingMaterialCardList .change-page-btns .btn,
  .customModal .readingMaterialCardList .change-page-btns .btn, .customModal * .readingMaterialCardList .change-page-btns .btn {
    font-size: 0.72rem;
    margin: 0;
  }
  .jeeta-app .readingMaterialCardList .change-page-btns .page-btns,
  .jeeta-app * .readingMaterialCardList .change-page-btns .page-btns,
  .customModal .readingMaterialCardList .change-page-btns .page-btns, .customModal * .readingMaterialCardList .change-page-btns .page-btns {
    width: 1.22rem;
    height: 1.22rem;
    font-size: 0.72rem;
  }
}
@media all and (max-width: 610px) {
  .jeeta-app .reading-material-card,
  .jeeta-app * .reading-material-card,
  .customModal .reading-material-card, .customModal * .reading-material-card {
    font-size: 0.9rem;
  }
  .jeeta-app readingMaterialCardListFilter .filterTitle span,
  .jeeta-app * readingMaterialCardListFilter .filterTitle span,
  .customModal readingMaterialCardListFilter .filterTitle span, .customModal * readingMaterialCardListFilter .filterTitle span {
    font-size: 1.3rem;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .readingMaterialCardListFilter,
  .jeeta-app * .readingMaterialCardListFilter,
  .customModal .readingMaterialCardListFilter, .customModal * .readingMaterialCardListFilter {
    gap: 0.5rem;
  }
  .jeeta-app .readingMaterialCardListFilter .filterTitle span,
  .jeeta-app * .readingMaterialCardListFilter .filterTitle span,
  .customModal .readingMaterialCardListFilter .filterTitle span, .customModal * .readingMaterialCardListFilter .filterTitle span {
    font-size: 1.25rem;
  }
  .jeeta-app .readingMaterialCardListFilter .filters,
  .jeeta-app * .readingMaterialCardListFilter .filters,
  .customModal .readingMaterialCardListFilter .filters, .customModal * .readingMaterialCardListFilter .filters {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput,
  .customModal .readingMaterialCardListFilter .filters .filterSelect,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput, .customModal * .readingMaterialCardListFilter .filters .filterSelect,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput {
    padding: 0;
  }
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect .title,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .title,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .title,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .title,
  .customModal .readingMaterialCardListFilter .filters .filterSelect .title,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput .title, .customModal * .readingMaterialCardListFilter .filters .filterSelect .title,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput .title {
    font-size: 0.9rem;
  }
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown,
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect input,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput input,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect input,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput input,
  .customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown,
  .customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .customModal .readingMaterialCardListFilter .filters .filterSelect input,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput input, .customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown,
  .customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .customModal * .readingMaterialCardListFilter .filters .filterSelect input,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput input {
    font-size: 0.9rem;
  }
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown input,
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect input input,
  .jeeta-app .readingMaterialCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown input,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput input input,
  .jeeta-app .readingMaterialCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown input,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect input input,
  .jeeta-app * .readingMaterialCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown input,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput input input,
  .jeeta-app * .readingMaterialCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown input,
  .customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .customModal .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .customModal .readingMaterialCardListFilter .filters .filterSelect input input,
  .customModal .readingMaterialCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown input,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput input input,
  .customModal .readingMaterialCardListFilter .filters .searchTextInput input .css-13cymwt-control, .customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown input,
  .customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .customModal * .readingMaterialCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .customModal * .readingMaterialCardListFilter .filters .filterSelect input input,
  .customModal * .readingMaterialCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown input,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput input input,
  .customModal * .readingMaterialCardListFilter .filters .searchTextInput input .css-13cymwt-control {
    font-size: 0.9rem;
  }
  .jeeta-app .readingMaterialCardListFilter .checkInput,
  .jeeta-app * .readingMaterialCardListFilter .checkInput,
  .customModal .readingMaterialCardListFilter .checkInput, .customModal * .readingMaterialCardListFilter .checkInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.5rem;
    gap: 0.5rem;
  }
  .jeeta-app .readingMaterialCardList,
  .jeeta-app * .readingMaterialCardList,
  .customModal .readingMaterialCardList, .customModal * .readingMaterialCardList {
    padding: 0.5rem;
  }
  .jeeta-app .readingMaterialCardList .readingMaterialCards,
  .jeeta-app * .readingMaterialCardList .readingMaterialCards,
  .customModal .readingMaterialCardList .readingMaterialCards, .customModal * .readingMaterialCardList .readingMaterialCards {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
    min-height: 6rem;
  }
  .jeeta-app .reading-material-card .date,
  .jeeta-app * .reading-material-card .date,
  .customModal .reading-material-card .date, .customModal * .reading-material-card .date {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=ReadingMaterialCard.css.map */