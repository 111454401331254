.jeeta-app .videoInputContainer,
.jeeta-app * .videoInputContainer,
.customModal .videoInputContainer,
.customModal * .videoInputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .videoInput,
.jeeta-app * .videoInput,
.customModal .videoInput,
.customModal * .videoInput {
  width: 100%;
  justify-content: center;
  align-items: center;
}
.jeeta-app .videoInput .dropzone,
.jeeta-app * .videoInput .dropzone,
.customModal .videoInput .dropzone,
.customModal * .videoInput .dropzone {
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  border: 2px dashed var(--PrimaryBkg);
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: border 0.2s ease-in-out;
}
.jeeta-app .videoInput .dropzone .drag-n-drop-msg,
.jeeta-app * .videoInput .dropzone .drag-n-drop-msg,
.customModal .videoInput .dropzone .drag-n-drop-msg,
.customModal * .videoInput .dropzone .drag-n-drop-msg {
  font-size: 0.95rem;
  color: var(--PrimaryColor);
  white-space: auto;
  font-weight: 600;
}
.jeeta-app .videoInput .dropzone.active,
.jeeta-app * .videoInput .dropzone.active,
.customModal .videoInput .dropzone.active,
.customModal * .videoInput .dropzone.active {
  border-color: var(--PrimaryColor);
}
.jeeta-app .videoInput .upload-video-number,
.jeeta-app * .videoInput .upload-video-number,
.customModal .videoInput .upload-video-number,
.customModal * .videoInput .upload-video-number {
  width: 100%;
  padding: 0.3rem 1rem;
  text-align: center;
  font-weight: 600;
  background-color: var(--SecondaryColor);
  color: var(--whiteColor);
  font-size: 0.83rem;
}
.jeeta-app .videoInput #error,
.jeeta-app * .videoInput #error,
.customModal .videoInput #error,
.customModal * .videoInput #error {
  background-color: var(--tiger-lilly);
}
.jeeta-app .previewVideosContainer,
.jeeta-app * .previewVideosContainer,
.customModal .previewVideosContainer,
.customModal * .previewVideosContainer {
  flex-direction: row;
}
.jeeta-app .previewVideosContainer .previewVideos,
.jeeta-app * .previewVideosContainer .previewVideos,
.customModal .previewVideosContainer .previewVideos,
.customModal * .previewVideosContainer .previewVideos {
  position: relative;
  justify-content: center;
  padding: 0.5rem;
  box-shadow: 2px 2px var(--greyText);
  background-color: var(--whiteColor);
  /* Ensure container takes up full column width */
  max-height: 40vh;
  min-height: 2rem;
  /* Fixed height for all containers */
  /* To handle larger videos */
}
.jeeta-app .previewVideosContainer .previewVideos .previewVideo,
.jeeta-app * .previewVideosContainer .previewVideos .previewVideo,
.customModal .previewVideosContainer .previewVideos .previewVideo,
.customModal * .previewVideosContainer .previewVideos .previewVideo {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .previewVideosContainer .previewVideos .delete-btn,
.jeeta-app * .previewVideosContainer .previewVideos .delete-btn,
.customModal .previewVideosContainer .previewVideos .delete-btn,
.customModal * .previewVideosContainer .previewVideos .delete-btn {
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  border: none;
  color: white;
  background-color: var(--SecondaryColor);
  display: flex;
  justify-content: center;
  padding: 0.3rem;
}
.jeeta-app .previewVideosContainer .previewVideos .delete-btn .icon,
.jeeta-app * .previewVideosContainer .previewVideos .delete-btn .icon,
.customModal .previewVideosContainer .previewVideos .delete-btn .icon,
.customModal * .previewVideosContainer .previewVideos .delete-btn .icon {
  font-size: 1rem;
}
.jeeta-app .previewVideosContainer .previewVideos .delete-btn:hover,
.jeeta-app * .previewVideosContainer .previewVideos .delete-btn:hover,
.customModal .previewVideosContainer .previewVideos .delete-btn:hover,
.customModal * .previewVideosContainer .previewVideos .delete-btn:hover {
  cursor: pointer;
  background-color: var(--tiger-lilly);
}
.jeeta-app .previewVideosContainer .previewVideos p,
.jeeta-app * .previewVideosContainer .previewVideos p,
.customModal .previewVideosContainer .previewVideos p,
.customModal * .previewVideosContainer .previewVideos p {
  border: none;
  color: white;
  background-color: var(--PrimaryColor);
  padding: 0 0.5rem;
}
.jeeta-app .videoViewer,
.jeeta-app * .videoViewer,
.customModal .videoViewer,
.customModal * .videoViewer {
  padding: 0.5rem;
  position: relative;
  width: 95%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .videoViewer .videoTitle,
.jeeta-app * .videoViewer .videoTitle,
.customModal .videoViewer .videoTitle,
.customModal * .videoViewer .videoTitle {
  margin: 1rem 0;
  font-size: 1.38rem;
  text-align: center;
}
.jeeta-app .videoViewer .videoPlayer,
.jeeta-app * .videoViewer .videoPlayer,
.customModal .videoViewer .videoPlayer,
.customModal * .videoViewer .videoPlayer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColorDeam);
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
}
.jeeta-app .chapters-table-wrapper,
.jeeta-app * .chapters-table-wrapper,
.customModal .chapters-table-wrapper,
.customModal * .chapters-table-wrapper {
  width: 100%;
  font-size: 0.85rem;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
.jeeta-app .chapters-table,
.jeeta-app * .chapters-table,
.customModal .chapters-table,
.customModal * .chapters-table {
  width: 100%;
  border-collapse: collapse;
}
.jeeta-app .chapters-table thead th,
.jeeta-app * .chapters-table thead th,
.customModal .chapters-table thead th,
.customModal * .chapters-table thead th {
  background-color: transparent;
  color: var(--SecondaryColor);
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  border: none;
  padding: 0 0.5rem;
}
.jeeta-app .chapters-table thead th .chapter-pin,
.jeeta-app * .chapters-table thead th .chapter-pin,
.customModal .chapters-table thead th .chapter-pin,
.customModal * .chapters-table thead th .chapter-pin {
  font-size: 1.2rem;
  color: var(--PrimaryColor);
}
.jeeta-app .chapters-table thead th:hover,
.jeeta-app * .chapters-table thead th:hover,
.customModal .chapters-table thead th:hover,
.customModal * .chapters-table thead th:hover {
  cursor: pointer;
}
.jeeta-app .chapters-table thead th:hover .chapter-pin,
.jeeta-app * .chapters-table thead th:hover .chapter-pin,
.customModal .chapters-table thead th:hover .chapter-pin,
.customModal * .chapters-table thead th:hover .chapter-pin {
  color: var(--tiger-lilly);
}
.jeeta-app .chapters-table tbody td,
.jeeta-app * .chapters-table tbody td,
.customModal .chapters-table tbody td,
.customModal * .chapters-table tbody td {
  text-align: center;
  vertical-align: top;
  max-width: 20rem;
  overflow: word-wrap;
  border: none;
  padding: 0 0.5rem 0.5rem 0.5rem;
}
.jeeta-app .chapters-table tbody td:hover,
.jeeta-app * .chapters-table tbody td:hover,
.customModal .chapters-table tbody td:hover,
.customModal * .chapters-table tbody td:hover {
  font-weight: 600;
  cursor: pointer;
}
.jeeta-app .chapters-table-vertical,
.jeeta-app * .chapters-table-vertical,
.customModal .chapters-table-vertical,
.customModal * .chapters-table-vertical {
  width: 100%;
  border-collapse: collapse;
}
.jeeta-app .chapters-table-vertical thead th,
.jeeta-app * .chapters-table-vertical thead th,
.customModal .chapters-table-vertical thead th,
.customModal * .chapters-table-vertical thead th {
  background-color: transparent;
  color: var(--SecondaryColor);
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  border: none;
  padding: 0 0.5rem;
}
.jeeta-app .chapters-table-vertical tbody td,
.jeeta-app * .chapters-table-vertical tbody td,
.customModal .chapters-table-vertical tbody td,
.customModal * .chapters-table-vertical tbody td {
  vertical-align: top;
  border: none;
}
.jeeta-app .chapters-table-vertical tbody td .start-time,
.jeeta-app * .chapters-table-vertical tbody td .start-time,
.customModal .chapters-table-vertical tbody td .start-time,
.customModal * .chapters-table-vertical tbody td .start-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;
  white-space: nowrap;
  background-color: transparent;
  color: var(--SecondaryColor);
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  border: none;
  padding: 0 0.5rem;
}
.jeeta-app .chapters-table-vertical tbody td .segment-title,
.jeeta-app * .chapters-table-vertical tbody td .segment-title,
.customModal .chapters-table-vertical tbody td .segment-title,
.customModal * .chapters-table-vertical tbody td .segment-title {
  overflow: word-wrap;
}
.jeeta-app .chapters-table-vertical tbody td .chapter-pin,
.jeeta-app * .chapters-table-vertical tbody td .chapter-pin,
.customModal .chapters-table-vertical tbody td .chapter-pin,
.customModal * .chapters-table-vertical tbody td .chapter-pin {
  font-size: 1.2rem;
  color: var(--PrimaryColor);
}
.jeeta-app .chapters-table-vertical tbody td:hover,
.jeeta-app * .chapters-table-vertical tbody td:hover,
.customModal .chapters-table-vertical tbody td:hover,
.customModal * .chapters-table-vertical tbody td:hover {
  font-weight: 600;
  cursor: pointer;
}
.jeeta-app .chapters-table-vertical tbody td:hover .chapter-pin,
.jeeta-app * .chapters-table-vertical tbody td:hover .chapter-pin,
.customModal .chapters-table-vertical tbody td:hover .chapter-pin,
.customModal * .chapters-table-vertical tbody td:hover .chapter-pin {
  color: var(--tiger-lilly);
}
@media all and (max-width: 860px) {
  .jeeta-app .videoInputContainer .videoInput .video-add-btn,
  .jeeta-app * .videoInputContainer .videoInput .video-add-btn,
  .customModal .videoInputContainer .videoInput .video-add-btn,
  .customModal * .videoInputContainer .videoInput .video-add-btn {
    font-size: 0.89rem;
  }
  .jeeta-app .videoInputContainer .videoInput .video-add-btn .upload-video-number,
  .jeeta-app * .videoInputContainer .videoInput .video-add-btn .upload-video-number,
  .customModal .videoInputContainer .videoInput .video-add-btn .upload-video-number,
  .customModal * .videoInputContainer .videoInput .video-add-btn .upload-video-number {
    font-size: 14px;
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .videoInputContainer .videoInput .dropzone,
  .jeeta-app * .videoInputContainer .videoInput .dropzone,
  .customModal .videoInputContainer .videoInput .dropzone,
  .customModal * .videoInputContainer .videoInput .dropzone {
    height: 7rem;
  }
}/*# sourceMappingURL=Video.css.map */