.customTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.customTable .title {
  padding: 0.5rem;
  color: var(--textColor);
}

.MuiTableRow-root > * {
  padding: 0.6rem;
}

.MuiTableRow-root.MuiTableRow-head > * {
  font-weight: bold !important;
}

.customTable td,
th {
  border: none !important;
}

.customTable :first-child {
  border-radius: 0.7rem !important;
}/*# sourceMappingURL=CustomTable.css.map */