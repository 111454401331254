.jeeta-app .analytics-chart-container,
.jeeta-app * .analytics-chart-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}
.jeeta-app .analytics-chart-section,
.jeeta-app * .analytics-chart-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 0.5rem;
}
.jeeta-app .analytics-chart-section .analytics-chart-section-title,
.jeeta-app * .analytics-chart-section .analytics-chart-section-title {
  width: 100%;
  color: var(--textColor);
  text-align: center;
}/*# sourceMappingURL=StudentAnalytics.css.map */