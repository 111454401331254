.jeeta-app .roboto-regular,
.jeeta-app * .roboto-regular,
.customModal .roboto-regular, .customModal * .roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.jeeta-app .roboto-medium,
.jeeta-app * .roboto-medium,
.customModal .roboto-medium, .customModal * .roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.jeeta-app .roboto-bold,
.jeeta-app * .roboto-bold,
.customModal .roboto-bold, .customModal * .roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.jeeta-app .NTA,
.jeeta-app * .NTA,
.customModal .NTA, .customModal * .NTA {
  width: 100%;
}
.jeeta-app .NTA .title,
.jeeta-app * .NTA .title,
.customModal .NTA .title, .customModal * .NTA .title {
  justify-content: flex-start;
  width: 100%;
}
.jeeta-app .NTAModal,
.jeeta-app * .NTAModal,
.customModal .NTAModal, .customModal * .NTAModal {
  width: 90vw;
  height: 90vh;
  overflow-y: auto;
  background-color: var(--whiteColor);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.jeeta-app .NTAInstructions,
.jeeta-app * .NTAInstructions,
.customModal .NTAInstructions, .customModal * .NTAInstructions {
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
}
.jeeta-app .NTAInstructions .title,
.jeeta-app * .NTAInstructions .title,
.customModal .NTAInstructions .title, .customModal * .NTAInstructions .title {
  padding: 1.5rem;
  background-color: var(--whiteColorDeam);
  color: var(--midnight-blue);
}
.jeeta-app .NTAInstructions .main,
.jeeta-app * .NTAInstructions .main,
.customModal .NTAInstructions .main, .customModal * .NTAInstructions .main {
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.jeeta-app .NTAInstructions .main .caution,
.jeeta-app * .NTAInstructions .main .caution,
.customModal .NTAInstructions .main .caution, .customModal * .NTAInstructions .main .caution {
  text-align: center;
}
.jeeta-app .NTAInstructions .language,
.jeeta-app * .NTAInstructions .language,
.customModal .NTAInstructions .language, .customModal * .NTAInstructions .language {
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  color: var(--hard);
}
.jeeta-app .NTAInstructions .agree-input,
.jeeta-app * .NTAInstructions .agree-input,
.customModal .NTAInstructions .agree-input, .customModal * .NTAInstructions .agree-input {
  padding: 1rem 2rem;
}
.jeeta-app .NTAInstructions .agree-input #agreeCheckbox,
.jeeta-app * .NTAInstructions .agree-input #agreeCheckbox,
.customModal .NTAInstructions .agree-input #agreeCheckbox, .customModal * .NTAInstructions .agree-input #agreeCheckbox {
  vertical-align: middle;
  display: inline;
}
.jeeta-app .NTAInstructions .olInstruction,
.jeeta-app * .NTAInstructions .olInstruction,
.customModal .NTAInstructions .olInstruction, .customModal * .NTAInstructions .olInstruction {
  padding: 1rem 0 0.5rem 0;
  list-style: none; /* Removes default numbering */
  counter-reset: item; /* Creates a counter named 'item' */
  padding-left: 2rem; /* Optional: Adjusts padding */
}
.jeeta-app .NTAInstructions .olInstruction .list-title,
.jeeta-app * .NTAInstructions .olInstruction .list-title,
.customModal .NTAInstructions .olInstruction .list-title, .customModal * .NTAInstructions .olInstruction .list-title {
  text-decoration: underline;
  padding: 0.5rem 0;
}
.jeeta-app .NTAInstructions .olInstruction .olItem,
.jeeta-app * .NTAInstructions .olInstruction .olItem,
.customModal .NTAInstructions .olInstruction .olItem, .customModal * .NTAInstructions .olInstruction .olItem {
  counter-increment: item; /* Increments the counter */
  margin-bottom: 0.5rem; /* Optional: Adds space between items */
}
.jeeta-app .NTAInstructions .olInstruction .olItem:before,
.jeeta-app * .NTAInstructions .olInstruction .olItem:before,
.customModal .NTAInstructions .olInstruction .olItem:before, .customModal * .NTAInstructions .olInstruction .olItem:before {
  content: counter(item) "."; /* Displays the counter */
  padding-right: 0.5rem; /* Adds space between the number and text */
}
.jeeta-app .NTAInstructions .olInstruction .olItem img,
.jeeta-app * .NTAInstructions .olInstruction .olItem img,
.customModal .NTAInstructions .olInstruction .olItem img, .customModal * .NTAInstructions .olInstruction .olItem img {
  display: inline !important;
  vertical-align: middle;
}
.jeeta-app .NTAInstructions .olInstruction-child,
.jeeta-app * .NTAInstructions .olInstruction-child,
.customModal .NTAInstructions .olInstruction-child, .customModal * .NTAInstructions .olInstruction-child {
  padding: 1rem 0 0.5rem 0;
  list-style: none; /* Removes default numbering */
  counter-reset: item; /* Creates a counter named 'item' */
  padding-left: 2rem; /* Optional: Adjusts padding */
}
.jeeta-app .NTAInstructions .ulInstruction,
.jeeta-app * .NTAInstructions .ulInstruction,
.customModal .NTAInstructions .ulInstruction, .customModal * .NTAInstructions .ulInstruction {
  list-style-type: circle; /* Uses hollow circles for list item markers */
  padding-left: 1rem; /* Adjusts padding for better alignment */
}
.jeeta-app .NTAInstructions .ulInstruction .ulItem,
.jeeta-app * .NTAInstructions .ulInstruction .ulItem,
.customModal .NTAInstructions .ulInstruction .ulItem, .customModal * .NTAInstructions .ulInstruction .ulItem {
  margin-bottom: 0.5rem; /* Optional: Adds space between items for better readability */
}
.jeeta-app .NTATestPaper,
.jeeta-app * .NTATestPaper,
.customModal .NTATestPaper, .customModal * .NTATestPaper {
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .NTATestPaper .test-name,
.jeeta-app * .NTATestPaper .test-name,
.customModal .NTATestPaper .test-name, .customModal * .NTATestPaper .test-name {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--whiteColorDeam);
}
.jeeta-app .NTATestPaperSection,
.jeeta-app * .NTATestPaperSection,
.customModal .NTATestPaperSection, .customModal * .NTATestPaperSection {
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}
.jeeta-app .NTATestPaperSection .sectionTitle,
.jeeta-app * .NTATestPaperSection .sectionTitle,
.customModal .NTATestPaperSection .sectionTitle, .customModal * .NTATestPaperSection .sectionTitle {
  padding-left: 1rem;
}
.jeeta-app .NTATestPaperQuestion,
.jeeta-app .NTATestPaperParagraph,
.jeeta-app * .NTATestPaperQuestion,
.jeeta-app * .NTATestPaperParagraph,
.customModal .NTATestPaperQuestion,
.customModal .NTATestPaperParagraph, .customModal * .NTATestPaperQuestion,
.customModal * .NTATestPaperParagraph {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  font-size: 1rem;
  width: 100%; /* Ensures it takes up 100% of the parent's width */
  box-sizing: border-box; /* Includes padding and border in the element's width */
}
.jeeta-app .NTATestPaperQuestion .question-text,
.jeeta-app .NTATestPaperParagraph .question-text,
.jeeta-app * .NTATestPaperQuestion .question-text,
.jeeta-app * .NTATestPaperParagraph .question-text,
.customModal .NTATestPaperQuestion .question-text,
.customModal .NTATestPaperParagraph .question-text, .customModal * .NTATestPaperQuestion .question-text,
.customModal * .NTATestPaperParagraph .question-text {
  flex-shrink: 0;
}
.jeeta-app .NTATestPaperQuestion .question-text span,
.jeeta-app .NTATestPaperParagraph .question-text span,
.jeeta-app * .NTATestPaperQuestion .question-text span,
.jeeta-app * .NTATestPaperParagraph .question-text span,
.customModal .NTATestPaperQuestion .question-text span,
.customModal .NTATestPaperParagraph .question-text span, .customModal * .NTATestPaperQuestion .question-text span,
.customModal * .NTATestPaperParagraph .question-text span {
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTATestPaperQuestion .question-image,
.jeeta-app .NTATestPaperQuestion .paragraph-images,
.jeeta-app .NTATestPaperParagraph .question-image,
.jeeta-app .NTATestPaperParagraph .paragraph-images,
.jeeta-app * .NTATestPaperQuestion .question-image,
.jeeta-app * .NTATestPaperQuestion .paragraph-images,
.jeeta-app * .NTATestPaperParagraph .question-image,
.jeeta-app * .NTATestPaperParagraph .paragraph-images,
.customModal .NTATestPaperQuestion .question-image,
.customModal .NTATestPaperQuestion .paragraph-images,
.customModal .NTATestPaperParagraph .question-image,
.customModal .NTATestPaperParagraph .paragraph-images, .customModal * .NTATestPaperQuestion .question-image,
.customModal * .NTATestPaperQuestion .paragraph-images,
.customModal * .NTATestPaperParagraph .question-image,
.customModal * .NTATestPaperParagraph .paragraph-images {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 0.5rem;
}
.jeeta-app .NTATestPaperQuestion .question-image .img,
.jeeta-app .NTATestPaperQuestion .paragraph-images .img,
.jeeta-app .NTATestPaperParagraph .question-image .img,
.jeeta-app .NTATestPaperParagraph .paragraph-images .img,
.jeeta-app * .NTATestPaperQuestion .question-image .img,
.jeeta-app * .NTATestPaperQuestion .paragraph-images .img,
.jeeta-app * .NTATestPaperParagraph .question-image .img,
.jeeta-app * .NTATestPaperParagraph .paragraph-images .img,
.customModal .NTATestPaperQuestion .question-image .img,
.customModal .NTATestPaperQuestion .paragraph-images .img,
.customModal .NTATestPaperParagraph .question-image .img,
.customModal .NTATestPaperParagraph .paragraph-images .img, .customModal * .NTATestPaperQuestion .question-image .img,
.customModal * .NTATestPaperQuestion .paragraph-images .img,
.customModal * .NTATestPaperParagraph .question-image .img,
.customModal * .NTATestPaperParagraph .paragraph-images .img {
  max-width: 100%;
  max-height: 50vh;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .NTATestPaperQuestion .question-image-only,
.jeeta-app .NTATestPaperParagraph .question-image-only,
.jeeta-app * .NTATestPaperQuestion .question-image-only,
.jeeta-app * .NTATestPaperParagraph .question-image-only,
.customModal .NTATestPaperQuestion .question-image-only,
.customModal .NTATestPaperParagraph .question-image-only, .customModal * .NTATestPaperQuestion .question-image-only,
.customModal * .NTATestPaperParagraph .question-image-only {
  flex-shrink: 0;
  display: block;
  max-width: 90%;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .NTATestPaperQuestion .question-image-only .wide,
.jeeta-app .NTATestPaperParagraph .question-image-only .wide,
.jeeta-app * .NTATestPaperQuestion .question-image-only .wide,
.jeeta-app * .NTATestPaperParagraph .question-image-only .wide,
.customModal .NTATestPaperQuestion .question-image-only .wide,
.customModal .NTATestPaperParagraph .question-image-only .wide, .customModal * .NTATestPaperQuestion .question-image-only .wide,
.customModal * .NTATestPaperParagraph .question-image-only .wide {
  max-width: 100%;
  height: auto;
}
.jeeta-app .NTATestPaperQuestion .question-image-only .long,
.jeeta-app .NTATestPaperParagraph .question-image-only .long,
.jeeta-app * .NTATestPaperQuestion .question-image-only .long,
.jeeta-app * .NTATestPaperParagraph .question-image-only .long,
.customModal .NTATestPaperQuestion .question-image-only .long,
.customModal .NTATestPaperParagraph .question-image-only .long, .customModal * .NTATestPaperQuestion .question-image-only .long,
.customModal * .NTATestPaperParagraph .question-image-only .long {
  max-width: 80%;
  height: auto;
}
.jeeta-app .NTATestPaperQuestion .solution,
.jeeta-app .NTATestPaperParagraph .solution,
.jeeta-app * .NTATestPaperQuestion .solution,
.jeeta-app * .NTATestPaperParagraph .solution,
.customModal .NTATestPaperQuestion .solution,
.customModal .NTATestPaperParagraph .solution, .customModal * .NTATestPaperQuestion .solution,
.customModal * .NTATestPaperParagraph .solution {
  flex-shrink: 0;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers,
.jeeta-app .NTATestPaperParagraph .NTAanswers,
.jeeta-app * .NTATestPaperQuestion .NTAanswers,
.jeeta-app * .NTATestPaperParagraph .NTAanswers,
.customModal .NTATestPaperQuestion .NTAanswers,
.customModal .NTATestPaperParagraph .NTAanswers, .customModal * .NTATestPaperQuestion .NTAanswers,
.customModal * .NTATestPaperParagraph .NTAanswers {
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0 1rem;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container {
  width: 100%;
  display: flex;
  align-items: center;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .option,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .option,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .option,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .option,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .option,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .option, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .option,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .option {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-check,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .option .answer-check,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-check,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .option .answer-check,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-check,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .option .answer-check, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-check,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .option .answer-check {
  margin-top: 1px;
  transform: scale(1.2);
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-label,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .option .answer-label,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-label,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .option .answer-label,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-label,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .option .answer-label, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-label,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .option .answer-label {
  font-weight: 700;
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .option span,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .option span,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .option span,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .option span,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .option span,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .option span, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .option span,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .option span {
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .subjective,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .subjective,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .subjective, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .subjective {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective span, .jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective input,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .subjective span,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .subjective input,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective span,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective input,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .subjective span,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .subjective input,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective span,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective input,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .subjective span,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .subjective input, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective span, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective input,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .subjective span,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .subjective input {
  width: 100%;
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-input,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .subjective .subjective-input,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-input,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .subjective .subjective-input,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-input,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .subjective .subjective-input, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-input,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .subjective .subjective-input {
  padding: 0.2rem 0.5rem;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-given-answer,
.jeeta-app .NTATestPaperParagraph .NTAanswers .answer-container .subjective .subjective-given-answer,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-given-answer,
.jeeta-app * .NTATestPaperParagraph .NTAanswers .answer-container .subjective .subjective-given-answer,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-given-answer,
.customModal .NTATestPaperParagraph .NTAanswers .answer-container .subjective .subjective-given-answer, .customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-given-answer,
.customModal * .NTATestPaperParagraph .NTAanswers .answer-container .subjective .subjective-given-answer {
  font-weight: 600;
}
.jeeta-app .NTATestPaperQuestion .NTAanswers.correct,
.jeeta-app .NTATestPaperParagraph .NTAanswers.correct,
.jeeta-app * .NTATestPaperQuestion .NTAanswers.correct,
.jeeta-app * .NTATestPaperParagraph .NTAanswers.correct,
.customModal .NTATestPaperQuestion .NTAanswers.correct,
.customModal .NTATestPaperParagraph .NTAanswers.correct, .customModal * .NTATestPaperQuestion .NTAanswers.correct,
.customModal * .NTATestPaperParagraph .NTAanswers.correct {
  border-color: green;
}
.jeeta-app .NTATestPaperQuestion .NTAAnswerResponse,
.jeeta-app .NTATestPaperParagraph .NTAAnswerResponse,
.jeeta-app * .NTATestPaperQuestion .NTAAnswerResponse,
.jeeta-app * .NTATestPaperParagraph .NTAAnswerResponse,
.customModal .NTATestPaperQuestion .NTAAnswerResponse,
.customModal .NTATestPaperParagraph .NTAAnswerResponse, .customModal * .NTATestPaperQuestion .NTAAnswerResponse,
.customModal * .NTATestPaperParagraph .NTAAnswerResponse {
  padding: 1rem 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.jeeta-app .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns,
.jeeta-app .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns,
.jeeta-app * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns,
.jeeta-app * .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns,
.customModal .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns,
.customModal .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns, .customModal * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns,
.customModal * .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns {
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn,
.jeeta-app .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns .NTAbtn,
.jeeta-app * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn,
.jeeta-app * .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns .NTAbtn,
.customModal .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn,
.customModal .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns .NTAbtn, .customModal * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn,
.customModal * .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns .NTAbtn {
  padding: 0.2rem 0.5rem;
  border: 1px solid #ddd;
}
.jeeta-app .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.jeeta-app .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.jeeta-app * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.jeeta-app * .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.customModal .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.customModal .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns .NTAbtn:hover, .customModal * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.customModal * .NTATestPaperParagraph .NTAAnswerResponse .NTAbtns .NTAbtn:hover {
  cursor: pointer;
  color: var(--whiteColor);
  background-color: var(--PrimaryColor);
}
.jeeta-app .instruction_area span.not_visited,
.jeeta-app * .instruction_area span.not_visited,
.customModal .instruction_area span.not_visited, .customModal * .instruction_area span.not_visited {
  background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
  background-position: -105px -49px;
  color: var(--whiteColor);
  cursor: pointer;
  float: left;
  font-weight: bold;
  width: 34px;
  height: 38px;
  line-height: 42px;
  margin: 3px;
  text-align: center;
}
.jeeta-app .instruction_area span.not_answered,
.jeeta-app * .instruction_area span.not_answered,
.customModal .instruction_area span.not_answered, .customModal * .instruction_area span.not_answered {
  background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
  background-position: -39px -48px;
  color: var(--whiteColor);
  cursor: pointer;
  float: left;
  font-weight: bold;
  width: 34px;
  height: 38px;
  line-height: 42px;
  margin: 3px;
  text-align: center;
}
.jeeta-app .instruction_area span.answered,
.jeeta-app * .instruction_area span.answered,
.customModal .instruction_area span.answered, .customModal * .instruction_area span.answered {
  background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
  background-position: -5px -48px;
  color: var(--whiteColor);
  cursor: pointer;
  float: left;
  font-weight: bold;
  width: 34px;
  height: 38px;
  line-height: 42px;
  margin: 3px;
  text-align: center;
}
.jeeta-app .instruction_area span.review,
.jeeta-app * .instruction_area span.review,
.customModal .instruction_area span.review, .customModal * .instruction_area span.review {
  background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
  background-position: -72px -48px;
  color: var(--whiteColor);
  cursor: pointer;
  float: left;
  font-weight: bold;
  width: 34px;
  height: 38px;
  line-height: 42px;
  margin: 3px;
  text-align: center;
}
.jeeta-app .instruction_area span.review_marked_considered,
.jeeta-app * .instruction_area span.review_marked_considered,
.customModal .instruction_area span.review_marked_considered, .customModal * .instruction_area span.review_marked_considered {
  background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
  background-position: -6px -81px;
  color: var(--whiteColor);
  cursor: pointer;
  float: left;
  font-weight: bold;
  width: 34px;
  height: 38px;
  line-height: 42px;
  margin: 3px;
  text-align: center;
}
.jeeta-app .NTA-btn,
.jeeta-app * .NTA-btn,
.customModal .NTA-btn, .customModal * .NTA-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  font-weight: 700;
  border-color: var(--whiteColor);
  border: none;
  font-size: 1.2rem;
}
.jeeta-app .NTA-btn .icon,
.jeeta-app * .NTA-btn .icon,
.customModal .NTA-btn .icon, .customModal * .NTA-btn .icon {
  font-size: 1.5rem;
}
.jeeta-app .NTA-btn:hover,
.jeeta-app * .NTA-btn:hover,
.customModal .NTA-btn:hover, .customModal * .NTA-btn:hover {
  cursor: pointer;
  color: var(--whiteColor);
  background-color: var(--PrimaryColor);
}
.jeeta-app .NTA-btn.not-agreed,
.jeeta-app * .NTA-btn.not-agreed,
.customModal .NTA-btn.not-agreed, .customModal * .NTA-btn.not-agreed {
  opacity: 0.5; /* Makes the button look faded */
  color: #aaa; /* Changes the text color to light grey */
}
.jeeta-app .NTA-btn.not-agreed:hover,
.jeeta-app * .NTA-btn.not-agreed:hover,
.customModal .NTA-btn.not-agreed:hover, .customModal * .NTA-btn.not-agreed:hover {
  cursor: not-allowed;
}
.jeeta-app #NTA-popup,
.jeeta-app * #NTA-popup,
.customModal #NTA-popup, .customModal * #NTA-popup {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  background-color: var(--whiteColorDeam);
  z-index: 1001;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
}
.jeeta-app #NTA-popup .NTA-popup-btns,
.jeeta-app * #NTA-popup .NTA-popup-btns,
.customModal #NTA-popup .NTA-popup-btns, .customModal * #NTA-popup .NTA-popup-btns {
  gap: 1rem;
}
.jeeta-app #NTA-popup .NTA-popup-close,
.jeeta-app * #NTA-popup .NTA-popup-close,
.customModal #NTA-popup .NTA-popup-close, .customModal * #NTA-popup .NTA-popup-close {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--PrimaryColor);
  border: none;
  padding: 0.5rem 1rem;
  color: var(--whiteColor);
}
.jeeta-app #NTA-popup .NTA-popup-close div,
.jeeta-app * #NTA-popup .NTA-popup-close div,
.customModal #NTA-popup .NTA-popup-close div, .customModal * #NTA-popup .NTA-popup-close div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app #NTA-popup .NTA-popup-close div .icon,
.jeeta-app * #NTA-popup .NTA-popup-close div .icon,
.customModal #NTA-popup .NTA-popup-close div .icon, .customModal * #NTA-popup .NTA-popup-close div .icon {
  font-size: 1.2rem;
}
.jeeta-app #NTA-popup .NTA-popup-close div .icon:hover,
.jeeta-app * #NTA-popup .NTA-popup-close div .icon:hover,
.customModal #NTA-popup .NTA-popup-close div .icon:hover, .customModal * #NTA-popup .NTA-popup-close div .icon:hover {
  cursor: pointer;
}
.jeeta-app #NTA-popup .NTA-popup-msg,
.jeeta-app * #NTA-popup .NTA-popup-msg,
.customModal #NTA-popup .NTA-popup-msg, .customModal * #NTA-popup .NTA-popup-msg {
  padding: 1rem;
  white-space: nowrap;
  text-align: center;
  line-height: 2;
}
.jeeta-app #NTA-popup .NTA-popup-OK,
.jeeta-app * #NTA-popup .NTA-popup-OK,
.customModal #NTA-popup .NTA-popup-OK, .customModal * #NTA-popup .NTA-popup-OK {
  width: auto;
  background-color: #248653;
  border: none;
  padding: 0.5rem 1rem;
  color: var(--whiteColor);
}
.jeeta-app #NTA-popup .NTA-popup-OK:hover,
.jeeta-app * #NTA-popup .NTA-popup-OK:hover,
.customModal #NTA-popup .NTA-popup-OK:hover, .customModal * #NTA-popup .NTA-popup-OK:hover {
  cursor: pointer;
}
.jeeta-app .instruction_icon,
.jeeta-app * .instruction_icon,
.customModal .instruction_icon, .customModal * .instruction_icon {
  background: url(../../../Assets/Icon-sprite.png) no-repeat -140px -7px;
  width: 18px;
  height: 18px;
}
.jeeta-app .NTATestHeader,
.jeeta-app * .NTATestHeader,
.customModal .NTATestHeader, .customModal * .NTATestHeader {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--blackColor);
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
}
.jeeta-app .NTATestHeader .test-name,
.jeeta-app * .NTATestHeader .test-name,
.customModal .NTATestHeader .test-name, .customModal * .NTATestHeader .test-name {
  gap: 0.2rem;
  align-items: center;
  color: var(--amber);
}
.jeeta-app .NTATestHeader .test-name span:hover,
.jeeta-app * .NTATestHeader .test-name span:hover,
.customModal .NTATestHeader .test-name span:hover, .customModal * .NTATestHeader .test-name span:hover {
  cursor: pointer;
}
.jeeta-app .NTATestHeader .test-refresh, .jeeta-app .NTATestHeader .test-status, .jeeta-app .NTATestHeader .test-report-btn,
.jeeta-app * .NTATestHeader .test-refresh,
.jeeta-app * .NTATestHeader .test-status,
.jeeta-app * .NTATestHeader .test-report-btn,
.customModal .NTATestHeader .test-refresh,
.customModal .NTATestHeader .test-status,
.customModal .NTATestHeader .test-report-btn, .customModal * .NTATestHeader .test-refresh, .customModal * .NTATestHeader .test-status, .customModal * .NTATestHeader .test-report-btn {
  position: relative;
  gap: 0.5rem;
  color: var(--whiteColor);
}
.jeeta-app .NTATestHeader .test-refresh .msg, .jeeta-app .NTATestHeader .test-status .msg, .jeeta-app .NTATestHeader .test-report-btn .msg,
.jeeta-app * .NTATestHeader .test-refresh .msg,
.jeeta-app * .NTATestHeader .test-status .msg,
.jeeta-app * .NTATestHeader .test-report-btn .msg,
.customModal .NTATestHeader .test-refresh .msg,
.customModal .NTATestHeader .test-status .msg,
.customModal .NTATestHeader .test-report-btn .msg, .customModal * .NTATestHeader .test-refresh .msg, .customModal * .NTATestHeader .test-status .msg, .customModal * .NTATestHeader .test-report-btn .msg {
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}
.jeeta-app .NTATestHeader .test-refresh #NTAreport, .jeeta-app .NTATestHeader .test-status #NTAreport, .jeeta-app .NTATestHeader .test-report-btn #NTAreport,
.jeeta-app * .NTATestHeader .test-refresh #NTAreport,
.jeeta-app * .NTATestHeader .test-status #NTAreport,
.jeeta-app * .NTATestHeader .test-report-btn #NTAreport,
.customModal .NTATestHeader .test-refresh #NTAreport,
.customModal .NTATestHeader .test-status #NTAreport,
.customModal .NTATestHeader .test-report-btn #NTAreport, .customModal * .NTATestHeader .test-refresh #NTAreport, .customModal * .NTATestHeader .test-status #NTAreport, .customModal * .NTATestHeader .test-report-btn #NTAreport {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  color: var(--whiteColor);
  background-color: var(--SecondaryColor);
  font-size: 0.9rem;
}
.jeeta-app .NTATestHeader .test-refresh #NTAreport .icon, .jeeta-app .NTATestHeader .test-status #NTAreport .icon, .jeeta-app .NTATestHeader .test-report-btn #NTAreport .icon,
.jeeta-app * .NTATestHeader .test-refresh #NTAreport .icon,
.jeeta-app * .NTATestHeader .test-status #NTAreport .icon,
.jeeta-app * .NTATestHeader .test-report-btn #NTAreport .icon,
.customModal .NTATestHeader .test-refresh #NTAreport .icon,
.customModal .NTATestHeader .test-status #NTAreport .icon,
.customModal .NTATestHeader .test-report-btn #NTAreport .icon, .customModal * .NTATestHeader .test-refresh #NTAreport .icon, .customModal * .NTATestHeader .test-status #NTAreport .icon, .customModal * .NTATestHeader .test-report-btn #NTAreport .icon {
  font-size: 1.2rem;
}
.jeeta-app .NTATestHeader .test-refresh #NTAreport:hover, .jeeta-app .NTATestHeader .test-status #NTAreport:hover, .jeeta-app .NTATestHeader .test-report-btn #NTAreport:hover,
.jeeta-app * .NTATestHeader .test-refresh #NTAreport:hover,
.jeeta-app * .NTATestHeader .test-status #NTAreport:hover,
.jeeta-app * .NTATestHeader .test-report-btn #NTAreport:hover,
.customModal .NTATestHeader .test-refresh #NTAreport:hover,
.customModal .NTATestHeader .test-status #NTAreport:hover,
.customModal .NTATestHeader .test-report-btn #NTAreport:hover, .customModal * .NTATestHeader .test-refresh #NTAreport:hover, .customModal * .NTATestHeader .test-status #NTAreport:hover, .customModal * .NTATestHeader .test-report-btn #NTAreport:hover {
  cursor: pointer;
}
.jeeta-app .NTATestHeader .test-header-popup,
.jeeta-app * .NTATestHeader .test-header-popup,
.customModal .NTATestHeader .test-header-popup, .customModal * .NTATestHeader .test-header-popup {
  gap: 1rem;
}
.jeeta-app .NTATestHeader .test-header-popup .test-instruction,
.jeeta-app * .NTATestHeader .test-header-popup .test-instruction,
.customModal .NTATestHeader .test-header-popup .test-instruction, .customModal * .NTATestHeader .test-header-popup .test-instruction {
  align-items: center;
  color: var(--whiteColor);
  gap: 0.2rem;
}
.jeeta-app .NTATestHeader .test-header-popup .test-instruction:hover,
.jeeta-app * .NTATestHeader .test-header-popup .test-instruction:hover,
.customModal .NTATestHeader .test-header-popup .test-instruction:hover, .customModal * .NTATestHeader .test-header-popup .test-instruction:hover {
  cursor: pointer;
}
.jeeta-app .NTATestSectionsContainerAndProfile,
.jeeta-app * .NTATestSectionsContainerAndProfile,
.customModal .NTATestSectionsContainerAndProfile, .customModal * .NTATestSectionsContainerAndProfile {
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;
}
.jeeta-app .NTASectionTabsandInfo,
.jeeta-app * .NTASectionTabsandInfo,
.customModal .NTASectionTabsandInfo, .customModal * .NTASectionTabsandInfo {
  flex-grow: 1;
  flex-direction: column;
  gap: 0.5rem;
  overflow-x: auto;
}
.jeeta-app .NTASectionTabsHeaderandContainer,
.jeeta-app * .NTASectionTabsHeaderandContainer,
.customModal .NTASectionTabsHeaderandContainer, .customModal * .NTASectionTabsHeaderandContainer {
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
}
.jeeta-app .NTASectionTabsHeader,
.jeeta-app * .NTASectionTabsHeader,
.customModal .NTASectionTabsHeader, .customModal * .NTASectionTabsHeader {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.1rem;
  padding-left: 0.5rem;
}
.jeeta-app .NTATimer,
.jeeta-app * .NTATimer,
.customModal .NTATimer, .customModal * .NTATimer {
  width: 10rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .NTATimer-label.half,
.jeeta-app * .NTATimer-label.half,
.customModal .NTATimer-label.half, .customModal * .NTATimer-label.half {
  color: var(--medium);
}
.jeeta-app .NTATimer-label.little,
.jeeta-app * .NTATimer-label.little,
.customModal .NTATimer-label.little, .customModal * .NTATimer-label.little {
  color: var(--hard);
}
.jeeta-app .NTASectionTabsContainer,
.jeeta-app * .NTASectionTabsContainer,
.customModal .NTASectionTabsContainer, .customModal * .NTASectionTabsContainer {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #ddd;
}
.jeeta-app .NTASectionTabsContainer .not-started-msg,
.jeeta-app * .NTASectionTabsContainer .not-started-msg,
.customModal .NTASectionTabsContainer .not-started-msg, .customModal * .NTASectionTabsContainer .not-started-msg {
  font-size: 1.5rem;
  color: red;
  padding: 0.95rem;
}
.jeeta-app .NTASectionTabsContainer .left-btn, .jeeta-app .NTASectionTabsContainer .right-btn,
.jeeta-app * .NTASectionTabsContainer .left-btn,
.jeeta-app * .NTASectionTabsContainer .right-btn,
.customModal .NTASectionTabsContainer .left-btn,
.customModal .NTASectionTabsContainer .right-btn, .customModal * .NTASectionTabsContainer .left-btn, .customModal * .NTASectionTabsContainer .right-btn {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0.2rem 0;
}
.jeeta-app .NTASectionTabsContainer .left-btn .icon, .jeeta-app .NTASectionTabsContainer .right-btn .icon,
.jeeta-app * .NTASectionTabsContainer .left-btn .icon,
.jeeta-app * .NTASectionTabsContainer .right-btn .icon,
.customModal .NTASectionTabsContainer .left-btn .icon,
.customModal .NTASectionTabsContainer .right-btn .icon, .customModal * .NTASectionTabsContainer .left-btn .icon, .customModal * .NTASectionTabsContainer .right-btn .icon {
  font-size: 1rem;
}
.jeeta-app .NTASectionTabsContainer .left-btn:hover, .jeeta-app .NTASectionTabsContainer .right-btn:hover,
.jeeta-app * .NTASectionTabsContainer .left-btn:hover,
.jeeta-app * .NTASectionTabsContainer .right-btn:hover,
.customModal .NTASectionTabsContainer .left-btn:hover,
.customModal .NTASectionTabsContainer .right-btn:hover, .customModal * .NTASectionTabsContainer .left-btn:hover, .customModal * .NTASectionTabsContainer .right-btn:hover {
  cursor: pointer;
}
.jeeta-app .NTASectionTabsContainer .left-btn,
.jeeta-app * .NTASectionTabsContainer .left-btn,
.customModal .NTASectionTabsContainer .left-btn, .customModal * .NTASectionTabsContainer .left-btn {
  float: left;
}
.jeeta-app .NTASectionTabsContainer .right-btn,
.jeeta-app * .NTASectionTabsContainer .right-btn,
.customModal .NTASectionTabsContainer .right-btn, .customModal * .NTASectionTabsContainer .right-btn {
  float: right;
}
.jeeta-app .NTASectionTabsContainer .NTASectionTabs,
.jeeta-app * .NTASectionTabsContainer .NTASectionTabs,
.customModal .NTASectionTabsContainer .NTASectionTabs, .customModal * .NTASectionTabsContainer .NTASectionTabs {
  flex-grow: 1;
  overflow-x: auto;
}
.jeeta-app .NTASectionTabsContainer .NTASectionTabs::-webkit-scrollbar,
.jeeta-app * .NTASectionTabsContainer .NTASectionTabs::-webkit-scrollbar,
.customModal .NTASectionTabsContainer .NTASectionTabs::-webkit-scrollbar, .customModal * .NTASectionTabsContainer .NTASectionTabs::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}
.jeeta-app .NTASectionTabsContainer .NTASectionTabs .tabItem,
.jeeta-app * .NTASectionTabsContainer .NTASectionTabs .tabItem,
.customModal .NTASectionTabsContainer .NTASectionTabs .tabItem, .customModal * .NTASectionTabsContainer .NTASectionTabs .tabItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  background-color: transparent;
  border: 1px solid #ddd;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}
.jeeta-app .NTASectionTabsContainer .NTASectionTabs .tabItem:hover,
.jeeta-app * .NTASectionTabsContainer .NTASectionTabs .tabItem:hover,
.customModal .NTASectionTabsContainer .NTASectionTabs .tabItem:hover, .customModal * .NTASectionTabsContainer .NTASectionTabs .tabItem:hover {
  cursor: pointer;
}
.jeeta-app .NTASectionTabsContainer .NTASectionTabs .tabItem.selected,
.jeeta-app * .NTASectionTabsContainer .NTASectionTabs .tabItem.selected,
.customModal .NTASectionTabsContainer .NTASectionTabs .tabItem.selected, .customModal * .NTASectionTabsContainer .NTASectionTabs .tabItem.selected {
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
}
.jeeta-app .NTAProfile,
.jeeta-app * .NTAProfile,
.customModal .NTAProfile, .customModal * .NTAProfile {
  width: 20rem;
  flex-shrink: 0;
  gap: 0.5rem;
  border: 1px solid #ddd;
}
.jeeta-app .NTAProfile .profile_image,
.jeeta-app * .NTAProfile .profile_image,
.customModal .NTAProfile .profile_image, .customModal * .NTAProfile .profile_image {
  width: 7.5rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .NTAAnswersCountsTooltipSection,
.jeeta-app .NTAAnswersCountsTooltipOverall,
.jeeta-app * .NTAAnswersCountsTooltipSection,
.jeeta-app * .NTAAnswersCountsTooltipOverall,
.customModal .NTAAnswersCountsTooltipSection,
.customModal .NTAAnswersCountsTooltipOverall, .customModal * .NTAAnswersCountsTooltipSection,
.customModal * .NTAAnswersCountsTooltipOverall {
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  font-size: small;
  left: 0;
  background-color: var(--whiteColorDeam);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  border: none;
  border-radius: 0.1rem;
  color: var(--textColor);
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  padding: 0.5rem;
  z-index: 10;
}
.jeeta-app .NTAAnswersCountsTooltipSection,
.jeeta-app * .NTAAnswersCountsTooltipSection,
.customModal .NTAAnswersCountsTooltipSection, .customModal * .NTAAnswersCountsTooltipSection {
  margin-top: 1.65rem;
}
.jeeta-app .NTAAnswersCountsTooltipOverall,
.jeeta-app * .NTAAnswersCountsTooltipOverall,
.customModal .NTAAnswersCountsTooltipOverall, .customModal * .NTAAnswersCountsTooltipOverall {
  top: 2.55rem;
}
.jeeta-app .NTASectionInfo,
.jeeta-app * .NTASectionInfo,
.customModal .NTASectionInfo, .customModal * .NTASectionInfo {
  width: 100%;
  justify-content: space-between;
  padding: 0 0.5rem;
  font-size: 0.8rem;
}
.jeeta-app .NTASectionQuestionType,
.jeeta-app * .NTASectionQuestionType,
.customModal .NTASectionQuestionType, .customModal * .NTASectionQuestionType {
  flex-grow: 1;
  align-items: center;
  gap: 0.2rem;
  text-transform: capitalize;
}
.jeeta-app .NTASectionBriefMarkingScheme,
.jeeta-app * .NTASectionBriefMarkingScheme,
.customModal .NTASectionBriefMarkingScheme, .customModal * .NTASectionBriefMarkingScheme {
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .NTASectionBriefMarkingScheme .NTA-marks-correct,
.jeeta-app * .NTASectionBriefMarkingScheme .NTA-marks-correct,
.customModal .NTASectionBriefMarkingScheme .NTA-marks-correct, .customModal * .NTASectionBriefMarkingScheme .NTA-marks-correct {
  color: blue;
}
.jeeta-app .NTASectionBriefMarkingScheme .NTA-marks-partial,
.jeeta-app * .NTASectionBriefMarkingScheme .NTA-marks-partial,
.customModal .NTASectionBriefMarkingScheme .NTA-marks-partial, .customModal * .NTASectionBriefMarkingScheme .NTA-marks-partial {
  color: green;
}
.jeeta-app .NTASectionBriefMarkingScheme .NTA-marks-wrong,
.jeeta-app * .NTASectionBriefMarkingScheme .NTA-marks-wrong,
.customModal .NTASectionBriefMarkingScheme .NTA-marks-wrong, .customModal * .NTASectionBriefMarkingScheme .NTA-marks-wrong {
  color: red;
}
.jeeta-app .NTATestLanguage,
.jeeta-app * .NTATestLanguage,
.customModal .NTATestLanguage, .customModal * .NTATestLanguage {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--PrimaryColor);
}
.jeeta-app .NTATestView,
.jeeta-app * .NTATestView,
.customModal .NTATestView, .customModal * .NTATestView {
  width: 100%;
  box-sizing: border-box; /* Includes padding and border in the element's width */
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
.jeeta-app .NTATestView .NTATestViewLeft,
.jeeta-app * .NTATestView .NTATestViewLeft,
.customModal .NTATestView .NTATestViewLeft, .customModal * .NTATestView .NTATestViewLeft {
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
}
.jeeta-app .NTATestView .NTATestViewLeft .expand_icon,
.jeeta-app * .NTATestView .NTATestViewLeft .expand_icon,
.customModal .NTATestView .NTATestViewLeft .expand_icon, .customModal * .NTATestView .NTATestViewLeft .expand_icon {
  background: url(../../../Assets/Icon-sprite.png) no-repeat -30px -63px;
  width: 17px;
  height: 48px;
  cursor: pointer;
  display: inline;
  right: 0;
  position: absolute;
  top: 53%;
  z-index: 100;
}
.jeeta-app .NTATestView .NTATestViewLeft #hideExpandIcon,
.jeeta-app * .NTATestView .NTATestViewLeft #hideExpandIcon,
.customModal .NTATestView .NTATestViewLeft #hideExpandIcon, .customModal * .NTATestView .NTATestViewLeft #hideExpandIcon {
  display: none;
}
.jeeta-app .NTATestView .NTATestViewRight,
.jeeta-app * .NTATestView .NTATestViewRight,
.customModal .NTATestView .NTATestViewRight, .customModal * .NTATestView .NTATestViewRight {
  position: relative;
  width: 20rem;
  height: 100%;
  flex-shrink: 0;
  border: 2px solid var(--blackColor);
  box-sizing: border-box; /* Includes padding and border in the element's width */
}
.jeeta-app .NTATestView .NTATestViewRight .collapse_icon,
.jeeta-app * .NTATestView .NTATestViewRight .collapse_icon,
.customModal .NTATestView .NTATestViewRight .collapse_icon, .customModal * .NTATestView .NTATestViewRight .collapse_icon {
  background: url(../../../Assets/Icon-sprite.png) no-repeat -5px -63px;
  width: 17px;
  height: 48px;
  cursor: pointer;
  display: inline-block;
  left: -5.5%;
  position: absolute;
  top: 43%;
  z-index: 100;
}
.jeeta-app .NTATestView #hideQuestionBox,
.jeeta-app * .NTATestView #hideQuestionBox,
.customModal .NTATestView #hideQuestionBox, .customModal * .NTATestView #hideQuestionBox {
  display: none;
}
.jeeta-app .NTAQuestionNumber,
.jeeta-app * .NTAQuestionNumber,
.customModal .NTAQuestionNumber, .customModal * .NTAQuestionNumber {
  width: 100%;
  max-height: 5%;
  padding: 1rem;
  flex-shrink: 0;
}
.jeeta-app .NTAMarkingScheme,
.jeeta-app * .NTAMarkingScheme,
.customModal .NTAMarkingScheme, .customModal * .NTAMarkingScheme {
  flex-shrink: 0;
  flex-direction: column;
  padding: 0.5rem;
  margin: 0.5rem 1rem 0 1rem;
  border: 2px solid var(--blackColor);
  gap: 0.5rem;
  font-size: 0.9rem;
}
.jeeta-app .NTAMarkingScheme .instructions,
.jeeta-app * .NTAMarkingScheme .instructions,
.customModal .NTAMarkingScheme .instructions, .customModal * .NTAMarkingScheme .instructions {
  padding-left: 1rem;
}
.jeeta-app .NTAMarkingScheme .instructions li,
.jeeta-app * .NTAMarkingScheme .instructions li,
.customModal .NTAMarkingScheme .instructions li, .customModal * .NTAMarkingScheme .instructions li {
  font-family: "Frank Ruhl Libre", serif;
  list-style-type: disc;
}
.jeeta-app .NTAMarkingScheme .markingSchemeTitle,
.jeeta-app * .NTAMarkingScheme .markingSchemeTitle,
.customModal .NTAMarkingScheme .markingSchemeTitle, .customModal * .NTAMarkingScheme .markingSchemeTitle {
  text-align: center;
}
.jeeta-app .NTAMarkingScheme .testMarksList,
.jeeta-app * .NTAMarkingScheme .testMarksList,
.customModal .NTAMarkingScheme .testMarksList, .customModal * .NTAMarkingScheme .testMarksList {
  margin-left: 1rem;
  line-height: 1.5;
}
.jeeta-app .NTAMarkingScheme .testMarksList .numbersInput,
.jeeta-app * .NTAMarkingScheme .testMarksList .numbersInput,
.customModal .NTAMarkingScheme .testMarksList .numbersInput, .customModal * .NTAMarkingScheme .testMarksList .numbersInput {
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 0.3rem;
  font-size: 0.85rem;
}
.jeeta-app .NTAMarkingScheme .testMarksList .numbersInput label,
.jeeta-app * .NTAMarkingScheme .testMarksList .numbersInput label,
.customModal .NTAMarkingScheme .testMarksList .numbersInput label, .customModal * .NTAMarkingScheme .testMarksList .numbersInput label {
  font-family: "Frank Ruhl Libre", serif;
  font-weight: 600;
  white-space: nowrap;
  padding-top: 0.7px;
}
.jeeta-app .NTAMarkingScheme .testMarksList .numbersInput label:after,
.jeeta-app * .NTAMarkingScheme .testMarksList .numbersInput label:after,
.customModal .NTAMarkingScheme .testMarksList .numbersInput label:after, .customModal * .NTAMarkingScheme .testMarksList .numbersInput label:after {
  content: ":";
}
.jeeta-app .NTAMarkingScheme .testMarksList .numbersInput span, .jeeta-app .NTAMarkingScheme .testMarksList .numbersInput strong,
.jeeta-app * .NTAMarkingScheme .testMarksList .numbersInput span,
.jeeta-app * .NTAMarkingScheme .testMarksList .numbersInput strong,
.customModal .NTAMarkingScheme .testMarksList .numbersInput span,
.customModal .NTAMarkingScheme .testMarksList .numbersInput strong, .customModal * .NTAMarkingScheme .testMarksList .numbersInput span, .customModal * .NTAMarkingScheme .testMarksList .numbersInput strong {
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTAQuestionBox,
.jeeta-app * .NTAQuestionBox,
.customModal .NTAQuestionBox, .customModal * .NTAQuestionBox {
  width: 100%;
  flex-shrink: 0;
  gap: 0.5rem;
  border: 1px solid #ddd;
  flex-direction: column;
  box-sizing: border-box; /* Includes padding and border in the element's width */
}
.jeeta-app .NTANotationTypeDescription,
.jeeta-app * .NTANotationTypeDescription,
.customModal .NTANotationTypeDescription, .customModal * .NTANotationTypeDescription {
  width: 100%;
  padding: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}
.jeeta-app .NTANotationTypeDescription .NTANotationType,
.jeeta-app * .NTANotationTypeDescription .NTANotationType,
.customModal .NTANotationTypeDescription .NTANotationType, .customModal * .NTANotationTypeDescription .NTANotationType {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
}
.jeeta-app .NTANotationTypeDescription .NTANotationType .label,
.jeeta-app * .NTANotationTypeDescription .NTANotationType .label,
.customModal .NTANotationTypeDescription .NTANotationType .label, .customModal * .NTANotationTypeDescription .NTANotationType .label {
  white-space: auto;
}
.jeeta-app .NTANotationTypeDescription .NTANotationType.review_answered,
.jeeta-app * .NTANotationTypeDescription .NTANotationType.review_answered,
.customModal .NTANotationTypeDescription .NTANotationType.review_answered, .customModal * .NTANotationTypeDescription .NTANotationType.review_answered {
  grid-column: span 2;
}
.jeeta-app .NTANotationType,
.jeeta-app * .NTANotationType,
.customModal .NTANotationType, .customModal * .NTANotationType {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
}
.jeeta-app .NTANotationType .label,
.jeeta-app * .NTANotationType .label,
.customModal .NTANotationType .label, .customModal * .NTANotationType .label {
  white-space: auto;
}
.jeeta-app .NTAAnswerNotation,
.jeeta-app * .NTAAnswerNotation,
.customModal .NTAAnswerNotation, .customModal * .NTAAnswerNotation {
  background: url(../../../Assets/questions-sprite.png) no-repeat;
  color: #262626;
  line-height: 15px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  flex-shrink: 0;
}
.jeeta-app .NTAAnswerNotation.answered,
.jeeta-app .NTAAnswerNotation.correct,
.jeeta-app * .NTAAnswerNotation.answered,
.jeeta-app * .NTAAnswerNotation.correct,
.customModal .NTAAnswerNotation.answered,
.customModal .NTAAnswerNotation.correct, .customModal * .NTAAnswerNotation.answered,
.customModal * .NTAAnswerNotation.correct {
  background-position: -7px -55px;
  float: left;
  height: 26px;
  line-height: 20px;
  margin-right: 10px;
  width: 29px;
  color: #fff;
  padding-top: 3px;
}
.jeeta-app .NTAAnswerNotation.not_answered,
.jeeta-app .NTAAnswerNotation.incorrect,
.jeeta-app * .NTAAnswerNotation.not_answered,
.jeeta-app * .NTAAnswerNotation.incorrect,
.customModal .NTAAnswerNotation.not_answered,
.customModal .NTAAnswerNotation.incorrect, .customModal * .NTAAnswerNotation.not_answered,
.customModal * .NTAAnswerNotation.incorrect {
  background-position: -42px -56px;
  float: left;
  height: 26px;
  line-height: 22px;
  margin-right: 10px;
  width: 29px;
  color: #fff;
}
.jeeta-app .NTAAnswerNotation.not_visited,
.jeeta-app .NTAAnswerNotation.no_response,
.jeeta-app * .NTAAnswerNotation.not_visited,
.jeeta-app * .NTAAnswerNotation.no_response,
.customModal .NTAAnswerNotation.not_visited,
.customModal .NTAAnswerNotation.no_response, .customModal * .NTAAnswerNotation.not_visited,
.customModal * .NTAAnswerNotation.no_response {
  background-position: -107px -56px;
  float: left;
  height: 26px;
  line-height: 21px;
  margin-right: 10px;
  width: 29px;
  color: #000;
  padding-top: 2px;
}
.jeeta-app .NTAAnswerNotation.review,
.jeeta-app * .NTAAnswerNotation.review,
.customModal .NTAAnswerNotation.review, .customModal * .NTAAnswerNotation.review {
  background-position: -75px -54px;
  float: left;
  height: 28px;
  line-height: 18px;
  margin-right: 10px;
  width: 28px;
  color: #fff;
  padding-top: 5px;
}
.jeeta-app .NTAAnswerNotation.review_answered,
.jeeta-app * .NTAAnswerNotation.review_answered,
.customModal .NTAAnswerNotation.review_answered, .customModal * .NTAAnswerNotation.review_answered {
  background-position: -9px -87px;
  float: left;
  height: 29px;
  line-height: 15px;
  margin-right: 10px;
  width: 28px;
  color: #fff;
  padding-top: 5px;
}
.jeeta-app .NTAChooseAQuestion,
.jeeta-app * .NTAChooseAQuestion,
.customModal .NTAChooseAQuestion, .customModal * .NTAChooseAQuestion {
  position: relative;
  width: 100%;
  flex-grow: 1; /* Ensures it takes up 100% of the parent's width */
  flex-direction: column;
  background-color: var(--alice-blue);
}
.jeeta-app .NTAChooseAQuestion .NTATestSectionTitle,
.jeeta-app * .NTAChooseAQuestion .NTATestSectionTitle,
.customModal .NTAChooseAQuestion .NTATestSectionTitle, .customModal * .NTAChooseAQuestion .NTATestSectionTitle {
  padding: 0.5rem 1rem;
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
}
.jeeta-app .NTAChooseAQuestion .NTAQuestionBtns,
.jeeta-app * .NTAChooseAQuestion .NTAQuestionBtns,
.customModal .NTAChooseAQuestion .NTAQuestionBtns, .customModal * .NTAChooseAQuestion .NTAQuestionBtns {
  grid-template-columns: repeat(auto-fill, 50px); /* Adjust the minmax values based on your needs */
  gap: 0.5rem; /* Space between grid items */
  padding: 1rem;
  align-items: start; /* Ensures all items are aligned to the top of their cell */
  overflow-y: auto !important;
}
.jeeta-app .NTAChooseAQuestion .submit-btn,
.jeeta-app * .NTAChooseAQuestion .submit-btn,
.customModal .NTAChooseAQuestion .submit-btn, .customModal * .NTAChooseAQuestion .submit-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem;
  padding: 0.5rem 2rem;
  background-color: var(--PrimaryBkg);
  color: var(--whiteColor);
  border: 1px solid #ddd;
  border-radius: 0.3rem;
}
.jeeta-app .NTAChooseAQuestion .submit-btn:hover,
.jeeta-app * .NTAChooseAQuestion .submit-btn:hover,
.customModal .NTAChooseAQuestion .submit-btn:hover, .customModal * .NTAChooseAQuestion .submit-btn:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
}
.jeeta-app .NTATestSubmitted,
.jeeta-app * .NTATestSubmitted,
.customModal .NTATestSubmitted, .customModal * .NTATestSubmitted {
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.jeeta-app .NTATestSubmitted span,
.jeeta-app * .NTATestSubmitted span,
.customModal .NTATestSubmitted span, .customModal * .NTATestSubmitted span {
  color: var(--PrimaryColor);
}
.jeeta-app .NTAQuestionSlide,
.jeeta-app .NTATestPaperQuestion,
.jeeta-app * .NTAQuestionSlide,
.jeeta-app * .NTATestPaperQuestion,
.customModal .NTAQuestionSlide,
.customModal .NTATestPaperQuestion, .customModal * .NTAQuestionSlide,
.customModal * .NTATestPaperQuestion {
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  font-size: 1rem;
  width: 100%; /* Ensures it takes up 100% of the parent's width */
  box-sizing: border-box; /* Includes padding and border in the element's width */
}
.jeeta-app .NTAQuestionSlide .question-info,
.jeeta-app .NTATestPaperQuestion .question-info,
.jeeta-app * .NTAQuestionSlide .question-info,
.jeeta-app * .NTATestPaperQuestion .question-info,
.customModal .NTAQuestionSlide .question-info,
.customModal .NTATestPaperQuestion .question-info, .customModal * .NTAQuestionSlide .question-info,
.customModal * .NTATestPaperQuestion .question-info {
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .NTAQuestionSlide .question-info .question-id,
.jeeta-app .NTATestPaperQuestion .question-info .question-id,
.jeeta-app * .NTAQuestionSlide .question-info .question-id,
.jeeta-app * .NTATestPaperQuestion .question-info .question-id,
.customModal .NTAQuestionSlide .question-info .question-id,
.customModal .NTATestPaperQuestion .question-info .question-id, .customModal * .NTAQuestionSlide .question-info .question-id,
.customModal * .NTATestPaperQuestion .question-info .question-id {
  background-color: var(--PrimaryColor);
  align-self: flex-start;
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.94rem;
  font-weight: 600;
}
.jeeta-app .NTAQuestionSlide .question-info .question-saved,
.jeeta-app .NTATestPaperQuestion .question-info .question-saved,
.jeeta-app * .NTAQuestionSlide .question-info .question-saved,
.jeeta-app * .NTATestPaperQuestion .question-info .question-saved,
.customModal .NTAQuestionSlide .question-info .question-saved,
.customModal .NTATestPaperQuestion .question-info .question-saved, .customModal * .NTAQuestionSlide .question-info .question-saved,
.customModal * .NTATestPaperQuestion .question-info .question-saved {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.2rem;
  gap: 0.2rem;
}
.jeeta-app .NTAQuestionSlide .question-info .question-saved .saved-btn,
.jeeta-app .NTATestPaperQuestion .question-info .question-saved .saved-btn,
.jeeta-app * .NTAQuestionSlide .question-info .question-saved .saved-btn,
.jeeta-app * .NTATestPaperQuestion .question-info .question-saved .saved-btn,
.customModal .NTAQuestionSlide .question-info .question-saved .saved-btn,
.customModal .NTATestPaperQuestion .question-info .question-saved .saved-btn, .customModal * .NTAQuestionSlide .question-info .question-saved .saved-btn,
.customModal * .NTATestPaperQuestion .question-info .question-saved .saved-btn {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
.jeeta-app .NTAQuestionSlide .question-info .question-saved .saved-btn .icon,
.jeeta-app .NTATestPaperQuestion .question-info .question-saved .saved-btn .icon,
.jeeta-app * .NTAQuestionSlide .question-info .question-saved .saved-btn .icon,
.jeeta-app * .NTATestPaperQuestion .question-info .question-saved .saved-btn .icon,
.customModal .NTAQuestionSlide .question-info .question-saved .saved-btn .icon,
.customModal .NTATestPaperQuestion .question-info .question-saved .saved-btn .icon, .customModal * .NTAQuestionSlide .question-info .question-saved .saved-btn .icon,
.customModal * .NTATestPaperQuestion .question-info .question-saved .saved-btn .icon {
  font-size: 1.6rem;
}
.jeeta-app .NTAQuestionSlide .question-info .question-saved #star-btn,
.jeeta-app .NTATestPaperQuestion .question-info .question-saved #star-btn,
.jeeta-app * .NTAQuestionSlide .question-info .question-saved #star-btn,
.jeeta-app * .NTATestPaperQuestion .question-info .question-saved #star-btn,
.customModal .NTAQuestionSlide .question-info .question-saved #star-btn,
.customModal .NTATestPaperQuestion .question-info .question-saved #star-btn, .customModal * .NTAQuestionSlide .question-info .question-saved #star-btn,
.customModal * .NTATestPaperQuestion .question-info .question-saved #star-btn {
  color: var(--tiger-lilly);
}
.jeeta-app .NTAQuestionSlide .question-info .question-saved #review-btn,
.jeeta-app .NTATestPaperQuestion .question-info .question-saved #review-btn,
.jeeta-app * .NTAQuestionSlide .question-info .question-saved #review-btn,
.jeeta-app * .NTATestPaperQuestion .question-info .question-saved #review-btn,
.customModal .NTAQuestionSlide .question-info .question-saved #review-btn,
.customModal .NTATestPaperQuestion .question-info .question-saved #review-btn, .customModal * .NTAQuestionSlide .question-info .question-saved #review-btn,
.customModal * .NTATestPaperQuestion .question-info .question-saved #review-btn {
  padding-top: 0.2rem;
  color: var(--kelly-green);
}
.jeeta-app .NTAQuestionSlide #Physics,
.jeeta-app .NTATestPaperQuestion #Physics,
.jeeta-app * .NTAQuestionSlide #Physics,
.jeeta-app * .NTATestPaperQuestion #Physics,
.customModal .NTAQuestionSlide #Physics,
.customModal .NTATestPaperQuestion #Physics, .customModal * .NTAQuestionSlide #Physics,
.customModal * .NTATestPaperQuestion #Physics {
  background-color: var(--Physics);
}
.jeeta-app .NTAQuestionSlide #Chemistry,
.jeeta-app .NTATestPaperQuestion #Chemistry,
.jeeta-app * .NTAQuestionSlide #Chemistry,
.jeeta-app * .NTATestPaperQuestion #Chemistry,
.customModal .NTAQuestionSlide #Chemistry,
.customModal .NTATestPaperQuestion #Chemistry, .customModal * .NTAQuestionSlide #Chemistry,
.customModal * .NTATestPaperQuestion #Chemistry {
  background-color: var(--Chemistry);
}
.jeeta-app .NTAQuestionSlide #Mathematics,
.jeeta-app .NTATestPaperQuestion #Mathematics,
.jeeta-app * .NTAQuestionSlide #Mathematics,
.jeeta-app * .NTATestPaperQuestion #Mathematics,
.customModal .NTAQuestionSlide #Mathematics,
.customModal .NTATestPaperQuestion #Mathematics, .customModal * .NTAQuestionSlide #Mathematics,
.customModal * .NTATestPaperQuestion #Mathematics {
  background-color: var(--Mathematics);
}
.jeeta-app .NTAQuestionSlide .question-text,
.jeeta-app .NTATestPaperQuestion .question-text,
.jeeta-app * .NTAQuestionSlide .question-text,
.jeeta-app * .NTATestPaperQuestion .question-text,
.customModal .NTAQuestionSlide .question-text,
.customModal .NTATestPaperQuestion .question-text, .customModal * .NTAQuestionSlide .question-text,
.customModal * .NTATestPaperQuestion .question-text {
  flex-shrink: 0;
}
.jeeta-app .NTAQuestionSlide .question-text span,
.jeeta-app .NTATestPaperQuestion .question-text span,
.jeeta-app * .NTAQuestionSlide .question-text span,
.jeeta-app * .NTATestPaperQuestion .question-text span,
.customModal .NTAQuestionSlide .question-text span,
.customModal .NTATestPaperQuestion .question-text span, .customModal * .NTAQuestionSlide .question-text span,
.customModal * .NTATestPaperQuestion .question-text span {
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTAQuestionSlide .question-image,
.jeeta-app .NTAQuestionSlide .paragraph-images,
.jeeta-app .NTATestPaperQuestion .question-image,
.jeeta-app .NTATestPaperQuestion .paragraph-images,
.jeeta-app * .NTAQuestionSlide .question-image,
.jeeta-app * .NTAQuestionSlide .paragraph-images,
.jeeta-app * .NTATestPaperQuestion .question-image,
.jeeta-app * .NTATestPaperQuestion .paragraph-images,
.customModal .NTAQuestionSlide .question-image,
.customModal .NTAQuestionSlide .paragraph-images,
.customModal .NTATestPaperQuestion .question-image,
.customModal .NTATestPaperQuestion .paragraph-images, .customModal * .NTAQuestionSlide .question-image,
.customModal * .NTAQuestionSlide .paragraph-images,
.customModal * .NTATestPaperQuestion .question-image,
.customModal * .NTATestPaperQuestion .paragraph-images {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 0.5rem;
}
.jeeta-app .NTAQuestionSlide .question-image .img,
.jeeta-app .NTAQuestionSlide .paragraph-images .img,
.jeeta-app .NTATestPaperQuestion .question-image .img,
.jeeta-app .NTATestPaperQuestion .paragraph-images .img,
.jeeta-app * .NTAQuestionSlide .question-image .img,
.jeeta-app * .NTAQuestionSlide .paragraph-images .img,
.jeeta-app * .NTATestPaperQuestion .question-image .img,
.jeeta-app * .NTATestPaperQuestion .paragraph-images .img,
.customModal .NTAQuestionSlide .question-image .img,
.customModal .NTAQuestionSlide .paragraph-images .img,
.customModal .NTATestPaperQuestion .question-image .img,
.customModal .NTATestPaperQuestion .paragraph-images .img, .customModal * .NTAQuestionSlide .question-image .img,
.customModal * .NTAQuestionSlide .paragraph-images .img,
.customModal * .NTATestPaperQuestion .question-image .img,
.customModal * .NTATestPaperQuestion .paragraph-images .img {
  max-width: 100%;
  max-height: 50vh;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .NTAQuestionSlide .question-image-only,
.jeeta-app .NTATestPaperQuestion .question-image-only,
.jeeta-app * .NTAQuestionSlide .question-image-only,
.jeeta-app * .NTATestPaperQuestion .question-image-only,
.customModal .NTAQuestionSlide .question-image-only,
.customModal .NTATestPaperQuestion .question-image-only, .customModal * .NTAQuestionSlide .question-image-only,
.customModal * .NTATestPaperQuestion .question-image-only {
  flex-shrink: 0;
  display: block;
  max-width: 90%;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .NTAQuestionSlide .question-image-only .wide,
.jeeta-app .NTATestPaperQuestion .question-image-only .wide,
.jeeta-app * .NTAQuestionSlide .question-image-only .wide,
.jeeta-app * .NTATestPaperQuestion .question-image-only .wide,
.customModal .NTAQuestionSlide .question-image-only .wide,
.customModal .NTATestPaperQuestion .question-image-only .wide, .customModal * .NTAQuestionSlide .question-image-only .wide,
.customModal * .NTATestPaperQuestion .question-image-only .wide {
  max-width: 100%;
  height: auto;
}
.jeeta-app .NTAQuestionSlide .question-image-only .long,
.jeeta-app .NTATestPaperQuestion .question-image-only .long,
.jeeta-app * .NTAQuestionSlide .question-image-only .long,
.jeeta-app * .NTATestPaperQuestion .question-image-only .long,
.customModal .NTAQuestionSlide .question-image-only .long,
.customModal .NTATestPaperQuestion .question-image-only .long, .customModal * .NTAQuestionSlide .question-image-only .long,
.customModal * .NTATestPaperQuestion .question-image-only .long {
  max-width: 80%;
  height: auto;
}
.jeeta-app .NTAQuestionSlide .solution,
.jeeta-app .NTATestPaperQuestion .solution,
.jeeta-app * .NTAQuestionSlide .solution,
.jeeta-app * .NTATestPaperQuestion .solution,
.customModal .NTAQuestionSlide .solution,
.customModal .NTATestPaperQuestion .solution, .customModal * .NTAQuestionSlide .solution,
.customModal * .NTATestPaperQuestion .solution {
  flex-shrink: 0;
}
.jeeta-app .NTAQuestionSlide .NTAanswers,
.jeeta-app .NTATestPaperQuestion .NTAanswers,
.jeeta-app * .NTAQuestionSlide .NTAanswers,
.jeeta-app * .NTATestPaperQuestion .NTAanswers,
.customModal .NTAQuestionSlide .NTAanswers,
.customModal .NTATestPaperQuestion .NTAanswers, .customModal * .NTAQuestionSlide .NTAanswers,
.customModal * .NTATestPaperQuestion .NTAanswers {
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0 1rem;
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container,
.customModal .NTAQuestionSlide .NTAanswers .answer-container,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container, .customModal * .NTAQuestionSlide .NTAanswers .answer-container,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container {
  width: 100%;
  display: flex;
  align-items: center;
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .option,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .option,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .option,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .option,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .option,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .option, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .option,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .option {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .option .answer-check,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-check,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .option .answer-check,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-check,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .option .answer-check,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-check, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .option .answer-check,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-check {
  margin-top: 1px;
  transform: scale(1.2);
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .option .answer-label,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-label,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .option .answer-label,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-label,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .option .answer-label,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-label, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .option .answer-label,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .option .answer-label {
  font-weight: 700;
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .option span,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .option span,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .option span,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .option span,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .option span,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .option span, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .option span,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .option span {
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .subjective,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .subjective,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .subjective,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .subjective,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .subjective span, .jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .subjective input,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective span,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective input,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .subjective span,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .subjective input,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective span,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective input,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .subjective span,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .subjective input,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective span,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective input, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .subjective span, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .subjective input,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective span,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective input {
  width: 100%;
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .subjective .subjective-input,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-input,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .subjective .subjective-input,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-input,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .subjective .subjective-input,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-input, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .subjective .subjective-input,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-input {
  padding: 0.2rem 0.5rem;
}
.jeeta-app .NTAQuestionSlide .NTAanswers .answer-container .subjective .subjective-given-answer,
.jeeta-app .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-given-answer,
.jeeta-app * .NTAQuestionSlide .NTAanswers .answer-container .subjective .subjective-given-answer,
.jeeta-app * .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-given-answer,
.customModal .NTAQuestionSlide .NTAanswers .answer-container .subjective .subjective-given-answer,
.customModal .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-given-answer, .customModal * .NTAQuestionSlide .NTAanswers .answer-container .subjective .subjective-given-answer,
.customModal * .NTATestPaperQuestion .NTAanswers .answer-container .subjective .subjective-given-answer {
  font-weight: 600;
}
.jeeta-app .NTAQuestionSlide .NTAanswers.correct,
.jeeta-app .NTATestPaperQuestion .NTAanswers.correct,
.jeeta-app * .NTAQuestionSlide .NTAanswers.correct,
.jeeta-app * .NTATestPaperQuestion .NTAanswers.correct,
.customModal .NTAQuestionSlide .NTAanswers.correct,
.customModal .NTATestPaperQuestion .NTAanswers.correct, .customModal * .NTAQuestionSlide .NTAanswers.correct,
.customModal * .NTATestPaperQuestion .NTAanswers.correct {
  border-color: green;
}
.jeeta-app .NTAQuestionSlide .NTAAnswerResponse,
.jeeta-app .NTATestPaperQuestion .NTAAnswerResponse,
.jeeta-app * .NTAQuestionSlide .NTAAnswerResponse,
.jeeta-app * .NTATestPaperQuestion .NTAAnswerResponse,
.customModal .NTAQuestionSlide .NTAAnswerResponse,
.customModal .NTATestPaperQuestion .NTAAnswerResponse, .customModal * .NTAQuestionSlide .NTAAnswerResponse,
.customModal * .NTATestPaperQuestion .NTAAnswerResponse {
  padding: 1rem 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.jeeta-app .NTAQuestionSlide .NTAAnswerResponse .NTAbtns,
.jeeta-app .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns,
.jeeta-app * .NTAQuestionSlide .NTAAnswerResponse .NTAbtns,
.jeeta-app * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns,
.customModal .NTAQuestionSlide .NTAAnswerResponse .NTAbtns,
.customModal .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns, .customModal * .NTAQuestionSlide .NTAAnswerResponse .NTAbtns,
.customModal * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns {
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .NTAQuestionSlide .NTAAnswerResponse .NTAbtns .NTAbtn,
.jeeta-app .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn,
.jeeta-app * .NTAQuestionSlide .NTAAnswerResponse .NTAbtns .NTAbtn,
.jeeta-app * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn,
.customModal .NTAQuestionSlide .NTAAnswerResponse .NTAbtns .NTAbtn,
.customModal .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn, .customModal * .NTAQuestionSlide .NTAAnswerResponse .NTAbtns .NTAbtn,
.customModal * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn {
  padding: 0.2rem 0.5rem;
  border: 1px solid #ddd;
}
.jeeta-app .NTAQuestionSlide .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.jeeta-app .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.jeeta-app * .NTAQuestionSlide .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.jeeta-app * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.customModal .NTAQuestionSlide .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.customModal .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn:hover, .customModal * .NTAQuestionSlide .NTAAnswerResponse .NTAbtns .NTAbtn:hover,
.customModal * .NTATestPaperQuestion .NTAAnswerResponse .NTAbtns .NTAbtn:hover {
  cursor: pointer;
  color: var(--whiteColor);
  background-color: var(--PrimaryColor);
}
.jeeta-app .NTAQuestionBtn,
.jeeta-app * .NTAQuestionBtn,
.customModal .NTAQuestionBtn, .customModal * .NTAQuestionBtn {
  background: url(../../../Assets/questions-sprite.png) no-repeat;
  color: #fff;
  float: left;
  font-size: 1.417em;
  font-weight: normal;
  height: 43px;
  line-height: 42px;
  margin-bottom: 10px;
  margin-right: 2px;
  text-align: center;
  width: 50px;
}
.jeeta-app .NTAQuestionBtn:hover,
.jeeta-app * .NTAQuestionBtn:hover,
.customModal .NTAQuestionBtn:hover, .customModal * .NTAQuestionBtn:hover {
  cursor: pointer;
}
.jeeta-app .NTAQuestionBtn.not_answered,
.jeeta-app .NTAQuestionBtn.incorrect,
.jeeta-app * .NTAQuestionBtn.not_answered,
.jeeta-app * .NTAQuestionBtn.incorrect,
.customModal .NTAQuestionBtn.not_answered,
.customModal .NTAQuestionBtn.incorrect, .customModal * .NTAQuestionBtn.not_answered,
.customModal * .NTAQuestionBtn.incorrect {
  background-position: -57px -6px;
  width: 49px;
  height: 43px;
  margin-top: 5px;
}
.jeeta-app .NTAQuestionBtn.answered,
.jeeta-app .NTAQuestionBtn.correct,
.jeeta-app * .NTAQuestionBtn.answered,
.jeeta-app * .NTAQuestionBtn.correct,
.customModal .NTAQuestionBtn.answered,
.customModal .NTAQuestionBtn.correct, .customModal * .NTAQuestionBtn.answered,
.customModal * .NTAQuestionBtn.correct {
  background-position: -4px -5px;
  margin-top: 5px;
}
.jeeta-app .NTAQuestionBtn.not_visited,
.jeeta-app .NTAQuestionBtn.no_response,
.jeeta-app * .NTAQuestionBtn.not_visited,
.jeeta-app * .NTAQuestionBtn.no_response,
.customModal .NTAQuestionBtn.not_visited,
.customModal .NTAQuestionBtn.no_response, .customModal * .NTAQuestionBtn.not_visited,
.customModal * .NTAQuestionBtn.no_response {
  background-position: -157px -4px;
  color: #474747;
  height: 43px;
  line-height: 43px;
  margin-top: 5px;
}
.jeeta-app .NTAQuestionBtn.review:hover,
.jeeta-app * .NTAQuestionBtn.review:hover,
.customModal .NTAQuestionBtn.review:hover, .customModal * .NTAQuestionBtn.review:hover {
  background-position: -108px -122px;
}
.jeeta-app .NTAQuestionBtn.review,
.jeeta-app * .NTAQuestionBtn.review,
.customModal .NTAQuestionBtn.review, .customModal * .NTAQuestionBtn.review {
  background-position: -108px -1px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 3px;
}
.jeeta-app .NTAQuestionBtn.not_answered:hover,
.jeeta-app * .NTAQuestionBtn.not_answered:hover,
.customModal .NTAQuestionBtn.not_answered:hover, .customModal * .NTAQuestionBtn.not_answered:hover {
  background-position: -57px -127px;
}
.jeeta-app .NTAQuestionBtn.review_answered,
.jeeta-app * .NTAQuestionBtn.review_answered,
.customModal .NTAQuestionBtn.review_answered, .customModal * .NTAQuestionBtn.review_answered {
  background-position: -66px -178px;
  height: 53px;
  line-height: 51px;
  margin-bottom: 0;
  /* margin-right: 5px; */
  width: 49px;
}
.jeeta-app #scrollToBottom,
.jeeta-app * #scrollToBottom,
.customModal #scrollToBottom, .customModal * #scrollToBottom {
  cursor: pointer;
  margin-top: 5px;
  height: 40px;
  width: 40px;
  color: #fff;
  font-size: 16pt;
  text-align: center;
  text-decoration: none;
  line-height: 40px;
  display: none;
  animation: 2s linear 0s normal none 1 running fadeinout;
  -webkit-animation: 2s linear 0s normal none 1 running fadeinout;
}
.jeeta-app #scrollToTop,
.jeeta-app * #scrollToTop,
.customModal #scrollToTop, .customModal * #scrollToTop {
  cursor: pointer;
  margin-top: 5px;
  height: 40px;
  width: 40px;
  color: #fff;
  font-size: 16pt;
  text-align: center;
  text-decoration: none;
  line-height: 40px;
  display: none;
}/*# sourceMappingURL=NTA.css.map */