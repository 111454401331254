.jeeta-app .reading-material-view,
.jeeta-app * .reading-material-view,
.customModal .reading-material-view, .customModal * .reading-material-view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 90%;
  height: 90%;
  overflow-y: auto;
  border: none;
  background-color: var(--whiteColorDeam);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1rem;
  color: var(--textColor);
}
.jeeta-app .reading-material-view #close,
.jeeta-app * .reading-material-view #close,
.customModal .reading-material-view #close, .customModal * .reading-material-view #close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 1.67rem;
}
.jeeta-app .reading-material-view #close:hover,
.jeeta-app * .reading-material-view #close:hover,
.customModal .reading-material-view #close:hover, .customModal * .reading-material-view #close:hover {
  color: var(--tiger-lilly);
}
.jeeta-app .pdf-viewer,
.jeeta-app * .pdf-viewer,
.customModal .pdf-viewer, .customModal * .pdf-viewer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .pdf-container,
.jeeta-app * .pdf-container,
.customModal .pdf-container, .customModal * .pdf-container {
  width: 100%;
  overflow: auto;
  flex-grow: 1;
}
.jeeta-app .pdf-container::-webkit-scrollbar,
.jeeta-app * .pdf-container::-webkit-scrollbar,
.customModal .pdf-container::-webkit-scrollbar, .customModal * .pdf-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.jeeta-app .pdf-container::-webkit-scrollbar-track,
.jeeta-app * .pdf-container::-webkit-scrollbar-track,
.customModal .pdf-container::-webkit-scrollbar-track, .customModal * .pdf-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.jeeta-app .pdf-container::-webkit-scrollbar-thumb,
.jeeta-app * .pdf-container::-webkit-scrollbar-thumb,
.customModal .pdf-container::-webkit-scrollbar-thumb, .customModal * .pdf-container::-webkit-scrollbar-thumb {
  background-color: var(--SecondaryBkg);
  border-radius: 0.5rem;
  border: none;
}
.jeeta-app .react-pdf__Document,
.jeeta-app * .react-pdf__Document,
.customModal .react-pdf__Document, .customModal * .react-pdf__Document {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
}
.jeeta-app .react-pdf__Page__canvas,
.jeeta-app * .react-pdf__Page__canvas,
.customModal .react-pdf__Page__canvas, .customModal * .react-pdf__Page__canvas {
  box-shadow: none;
}
.jeeta-app .react-pdf__Page,
.jeeta-app * .react-pdf__Page,
.customModal .react-pdf__Page, .customModal * .react-pdf__Page {
  width: 100%;
  display: flex;
  justify-content: center;
}
.jeeta-app .annotation-canvas,
.jeeta-app * .annotation-canvas,
.customModal .annotation-canvas, .customModal * .annotation-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
  /* This will allow mouse events to pass through to the underlying document */
}
.jeeta-app .pdf-viewer .react-pdf__Page__annotations,
.jeeta-app * .pdf-viewer .react-pdf__Page__annotations,
.customModal .pdf-viewer .react-pdf__Page__annotations, .customModal * .pdf-viewer .react-pdf__Page__annotations {
  display: none;
  /* Hide annotations layer to prevent downloading */
}
.jeeta-app .pdf-controls,
.jeeta-app * .pdf-controls,
.customModal .pdf-controls, .customModal * .pdf-controls {
  width: 100%;
  padding: 0 2rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.jeeta-app .change-page-btns,
.jeeta-app .zoom-controls,
.jeeta-app .annotation-controls,
.jeeta-app * .change-page-btns,
.jeeta-app * .zoom-controls,
.jeeta-app * .annotation-controls,
.customModal .change-page-btns,
.customModal .zoom-controls,
.customModal .annotation-controls, .customModal * .change-page-btns,
.customModal * .zoom-controls,
.customModal * .annotation-controls {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .change-page-btns .left-btn,
.jeeta-app .change-page-btns .right-btn,
.jeeta-app .zoom-controls .left-btn,
.jeeta-app .zoom-controls .right-btn,
.jeeta-app .annotation-controls .left-btn,
.jeeta-app .annotation-controls .right-btn,
.jeeta-app * .change-page-btns .left-btn,
.jeeta-app * .change-page-btns .right-btn,
.jeeta-app * .zoom-controls .left-btn,
.jeeta-app * .zoom-controls .right-btn,
.jeeta-app * .annotation-controls .left-btn,
.jeeta-app * .annotation-controls .right-btn,
.customModal .change-page-btns .left-btn,
.customModal .change-page-btns .right-btn,
.customModal .zoom-controls .left-btn,
.customModal .zoom-controls .right-btn,
.customModal .annotation-controls .left-btn,
.customModal .annotation-controls .right-btn, .customModal * .change-page-btns .left-btn,
.customModal * .change-page-btns .right-btn,
.customModal * .zoom-controls .left-btn,
.customModal * .zoom-controls .right-btn,
.customModal * .annotation-controls .left-btn,
.customModal * .annotation-controls .right-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.85rem;
  /* Circle size */
  height: 1.85rem;
  /* Circle size */
  border-radius: 50%;
  /* Makes the button circular */
  border: none;
  /* Text color */
  font-size: 0.78rem;
  /* Adjust the font size as needed */
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
}
.jeeta-app .change-page-btns .left-btn:hover,
.jeeta-app .change-page-btns .right-btn:hover,
.jeeta-app .zoom-controls .left-btn:hover,
.jeeta-app .zoom-controls .right-btn:hover,
.jeeta-app .annotation-controls .left-btn:hover,
.jeeta-app .annotation-controls .right-btn:hover,
.jeeta-app * .change-page-btns .left-btn:hover,
.jeeta-app * .change-page-btns .right-btn:hover,
.jeeta-app * .zoom-controls .left-btn:hover,
.jeeta-app * .zoom-controls .right-btn:hover,
.jeeta-app * .annotation-controls .left-btn:hover,
.jeeta-app * .annotation-controls .right-btn:hover,
.customModal .change-page-btns .left-btn:hover,
.customModal .change-page-btns .right-btn:hover,
.customModal .zoom-controls .left-btn:hover,
.customModal .zoom-controls .right-btn:hover,
.customModal .annotation-controls .left-btn:hover,
.customModal .annotation-controls .right-btn:hover, .customModal * .change-page-btns .left-btn:hover,
.customModal * .change-page-btns .right-btn:hover,
.customModal * .zoom-controls .left-btn:hover,
.customModal * .zoom-controls .right-btn:hover,
.customModal * .annotation-controls .left-btn:hover,
.customModal * .annotation-controls .right-btn:hover {
  cursor: pointer;
  background-color: var(--SecondaryColor);
}
.jeeta-app .change-page-btns .left-btn .icon,
.jeeta-app .change-page-btns .right-btn .icon,
.jeeta-app .zoom-controls .left-btn .icon,
.jeeta-app .zoom-controls .right-btn .icon,
.jeeta-app .annotation-controls .left-btn .icon,
.jeeta-app .annotation-controls .right-btn .icon,
.jeeta-app * .change-page-btns .left-btn .icon,
.jeeta-app * .change-page-btns .right-btn .icon,
.jeeta-app * .zoom-controls .left-btn .icon,
.jeeta-app * .zoom-controls .right-btn .icon,
.jeeta-app * .annotation-controls .left-btn .icon,
.jeeta-app * .annotation-controls .right-btn .icon,
.customModal .change-page-btns .left-btn .icon,
.customModal .change-page-btns .right-btn .icon,
.customModal .zoom-controls .left-btn .icon,
.customModal .zoom-controls .right-btn .icon,
.customModal .annotation-controls .left-btn .icon,
.customModal .annotation-controls .right-btn .icon, .customModal * .change-page-btns .left-btn .icon,
.customModal * .change-page-btns .right-btn .icon,
.customModal * .zoom-controls .left-btn .icon,
.customModal * .zoom-controls .right-btn .icon,
.customModal * .annotation-controls .left-btn .icon,
.customModal * .annotation-controls .right-btn .icon {
  font-size: 1rem;
}
.jeeta-app .change-page-btns .left-btn.enabled,
.jeeta-app .change-page-btns .right-btn.enabled,
.jeeta-app .zoom-controls .left-btn.enabled,
.jeeta-app .zoom-controls .right-btn.enabled,
.jeeta-app .annotation-controls .left-btn.enabled,
.jeeta-app .annotation-controls .right-btn.enabled,
.jeeta-app * .change-page-btns .left-btn.enabled,
.jeeta-app * .change-page-btns .right-btn.enabled,
.jeeta-app * .zoom-controls .left-btn.enabled,
.jeeta-app * .zoom-controls .right-btn.enabled,
.jeeta-app * .annotation-controls .left-btn.enabled,
.jeeta-app * .annotation-controls .right-btn.enabled,
.customModal .change-page-btns .left-btn.enabled,
.customModal .change-page-btns .right-btn.enabled,
.customModal .zoom-controls .left-btn.enabled,
.customModal .zoom-controls .right-btn.enabled,
.customModal .annotation-controls .left-btn.enabled,
.customModal .annotation-controls .right-btn.enabled, .customModal * .change-page-btns .left-btn.enabled,
.customModal * .change-page-btns .right-btn.enabled,
.customModal * .zoom-controls .left-btn.enabled,
.customModal * .zoom-controls .right-btn.enabled,
.customModal * .annotation-controls .left-btn.enabled,
.customModal * .annotation-controls .right-btn.enabled {
  background-color: var(--SecondaryColor);
  color: var(--whiteColor);
}
.jeeta-app .change-page-btns .create-mark-btn,
.jeeta-app .change-page-btns .undo-mark-btn,
.jeeta-app .change-page-btns .delete-mark-btn,
.jeeta-app .zoom-controls .create-mark-btn,
.jeeta-app .zoom-controls .undo-mark-btn,
.jeeta-app .zoom-controls .delete-mark-btn,
.jeeta-app .annotation-controls .create-mark-btn,
.jeeta-app .annotation-controls .undo-mark-btn,
.jeeta-app .annotation-controls .delete-mark-btn,
.jeeta-app * .change-page-btns .create-mark-btn,
.jeeta-app * .change-page-btns .undo-mark-btn,
.jeeta-app * .change-page-btns .delete-mark-btn,
.jeeta-app * .zoom-controls .create-mark-btn,
.jeeta-app * .zoom-controls .undo-mark-btn,
.jeeta-app * .zoom-controls .delete-mark-btn,
.jeeta-app * .annotation-controls .create-mark-btn,
.jeeta-app * .annotation-controls .undo-mark-btn,
.jeeta-app * .annotation-controls .delete-mark-btn,
.customModal .change-page-btns .create-mark-btn,
.customModal .change-page-btns .undo-mark-btn,
.customModal .change-page-btns .delete-mark-btn,
.customModal .zoom-controls .create-mark-btn,
.customModal .zoom-controls .undo-mark-btn,
.customModal .zoom-controls .delete-mark-btn,
.customModal .annotation-controls .create-mark-btn,
.customModal .annotation-controls .undo-mark-btn,
.customModal .annotation-controls .delete-mark-btn, .customModal * .change-page-btns .create-mark-btn,
.customModal * .change-page-btns .undo-mark-btn,
.customModal * .change-page-btns .delete-mark-btn,
.customModal * .zoom-controls .create-mark-btn,
.customModal * .zoom-controls .undo-mark-btn,
.customModal * .zoom-controls .delete-mark-btn,
.customModal * .annotation-controls .create-mark-btn,
.customModal * .annotation-controls .undo-mark-btn,
.customModal * .annotation-controls .delete-mark-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 0.85rem;
  background-color: var(--amber);
  color: var(--textColor);
  padding: 0.2rem 0.5rem;
}
.jeeta-app .change-page-btns .create-mark-btn .icon,
.jeeta-app .change-page-btns .undo-mark-btn .icon,
.jeeta-app .change-page-btns .delete-mark-btn .icon,
.jeeta-app .zoom-controls .create-mark-btn .icon,
.jeeta-app .zoom-controls .undo-mark-btn .icon,
.jeeta-app .zoom-controls .delete-mark-btn .icon,
.jeeta-app .annotation-controls .create-mark-btn .icon,
.jeeta-app .annotation-controls .undo-mark-btn .icon,
.jeeta-app .annotation-controls .delete-mark-btn .icon,
.jeeta-app * .change-page-btns .create-mark-btn .icon,
.jeeta-app * .change-page-btns .undo-mark-btn .icon,
.jeeta-app * .change-page-btns .delete-mark-btn .icon,
.jeeta-app * .zoom-controls .create-mark-btn .icon,
.jeeta-app * .zoom-controls .undo-mark-btn .icon,
.jeeta-app * .zoom-controls .delete-mark-btn .icon,
.jeeta-app * .annotation-controls .create-mark-btn .icon,
.jeeta-app * .annotation-controls .undo-mark-btn .icon,
.jeeta-app * .annotation-controls .delete-mark-btn .icon,
.customModal .change-page-btns .create-mark-btn .icon,
.customModal .change-page-btns .undo-mark-btn .icon,
.customModal .change-page-btns .delete-mark-btn .icon,
.customModal .zoom-controls .create-mark-btn .icon,
.customModal .zoom-controls .undo-mark-btn .icon,
.customModal .zoom-controls .delete-mark-btn .icon,
.customModal .annotation-controls .create-mark-btn .icon,
.customModal .annotation-controls .undo-mark-btn .icon,
.customModal .annotation-controls .delete-mark-btn .icon, .customModal * .change-page-btns .create-mark-btn .icon,
.customModal * .change-page-btns .undo-mark-btn .icon,
.customModal * .change-page-btns .delete-mark-btn .icon,
.customModal * .zoom-controls .create-mark-btn .icon,
.customModal * .zoom-controls .undo-mark-btn .icon,
.customModal * .zoom-controls .delete-mark-btn .icon,
.customModal * .annotation-controls .create-mark-btn .icon,
.customModal * .annotation-controls .undo-mark-btn .icon,
.customModal * .annotation-controls .delete-mark-btn .icon {
  font-size: 1rem;
}
.jeeta-app .change-page-btns .create-mark-btn:hover,
.jeeta-app .change-page-btns .undo-mark-btn:hover,
.jeeta-app .change-page-btns .delete-mark-btn:hover,
.jeeta-app .zoom-controls .create-mark-btn:hover,
.jeeta-app .zoom-controls .undo-mark-btn:hover,
.jeeta-app .zoom-controls .delete-mark-btn:hover,
.jeeta-app .annotation-controls .create-mark-btn:hover,
.jeeta-app .annotation-controls .undo-mark-btn:hover,
.jeeta-app .annotation-controls .delete-mark-btn:hover,
.jeeta-app * .change-page-btns .create-mark-btn:hover,
.jeeta-app * .change-page-btns .undo-mark-btn:hover,
.jeeta-app * .change-page-btns .delete-mark-btn:hover,
.jeeta-app * .zoom-controls .create-mark-btn:hover,
.jeeta-app * .zoom-controls .undo-mark-btn:hover,
.jeeta-app * .zoom-controls .delete-mark-btn:hover,
.jeeta-app * .annotation-controls .create-mark-btn:hover,
.jeeta-app * .annotation-controls .undo-mark-btn:hover,
.jeeta-app * .annotation-controls .delete-mark-btn:hover,
.customModal .change-page-btns .create-mark-btn:hover,
.customModal .change-page-btns .undo-mark-btn:hover,
.customModal .change-page-btns .delete-mark-btn:hover,
.customModal .zoom-controls .create-mark-btn:hover,
.customModal .zoom-controls .undo-mark-btn:hover,
.customModal .zoom-controls .delete-mark-btn:hover,
.customModal .annotation-controls .create-mark-btn:hover,
.customModal .annotation-controls .undo-mark-btn:hover,
.customModal .annotation-controls .delete-mark-btn:hover, .customModal * .change-page-btns .create-mark-btn:hover,
.customModal * .change-page-btns .undo-mark-btn:hover,
.customModal * .change-page-btns .delete-mark-btn:hover,
.customModal * .zoom-controls .create-mark-btn:hover,
.customModal * .zoom-controls .undo-mark-btn:hover,
.customModal * .zoom-controls .delete-mark-btn:hover,
.customModal * .annotation-controls .create-mark-btn:hover,
.customModal * .annotation-controls .undo-mark-btn:hover,
.customModal * .annotation-controls .delete-mark-btn:hover {
  cursor: pointer;
  background-color: var(--SecondaryColor);
  color: var(--whiteColor);
  font-weight: 600;
}
.jeeta-app .change-page-btns .create-mark-btn.enabled,
.jeeta-app .change-page-btns .undo-mark-btn.enabled,
.jeeta-app .change-page-btns .delete-mark-btn.enabled,
.jeeta-app .zoom-controls .create-mark-btn.enabled,
.jeeta-app .zoom-controls .undo-mark-btn.enabled,
.jeeta-app .zoom-controls .delete-mark-btn.enabled,
.jeeta-app .annotation-controls .create-mark-btn.enabled,
.jeeta-app .annotation-controls .undo-mark-btn.enabled,
.jeeta-app .annotation-controls .delete-mark-btn.enabled,
.jeeta-app * .change-page-btns .create-mark-btn.enabled,
.jeeta-app * .change-page-btns .undo-mark-btn.enabled,
.jeeta-app * .change-page-btns .delete-mark-btn.enabled,
.jeeta-app * .zoom-controls .create-mark-btn.enabled,
.jeeta-app * .zoom-controls .undo-mark-btn.enabled,
.jeeta-app * .zoom-controls .delete-mark-btn.enabled,
.jeeta-app * .annotation-controls .create-mark-btn.enabled,
.jeeta-app * .annotation-controls .undo-mark-btn.enabled,
.jeeta-app * .annotation-controls .delete-mark-btn.enabled,
.customModal .change-page-btns .create-mark-btn.enabled,
.customModal .change-page-btns .undo-mark-btn.enabled,
.customModal .change-page-btns .delete-mark-btn.enabled,
.customModal .zoom-controls .create-mark-btn.enabled,
.customModal .zoom-controls .undo-mark-btn.enabled,
.customModal .zoom-controls .delete-mark-btn.enabled,
.customModal .annotation-controls .create-mark-btn.enabled,
.customModal .annotation-controls .undo-mark-btn.enabled,
.customModal .annotation-controls .delete-mark-btn.enabled, .customModal * .change-page-btns .create-mark-btn.enabled,
.customModal * .change-page-btns .undo-mark-btn.enabled,
.customModal * .change-page-btns .delete-mark-btn.enabled,
.customModal * .zoom-controls .create-mark-btn.enabled,
.customModal * .zoom-controls .undo-mark-btn.enabled,
.customModal * .zoom-controls .delete-mark-btn.enabled,
.customModal * .annotation-controls .create-mark-btn.enabled,
.customModal * .annotation-controls .undo-mark-btn.enabled,
.customModal * .annotation-controls .delete-mark-btn.enabled {
  background-color: var(--SecondaryColor);
  color: var(--whiteColor);
  font-weight: 600;
}
.jeeta-app .change-page-btns .undo-mark-btn,
.jeeta-app .zoom-controls .undo-mark-btn,
.jeeta-app .annotation-controls .undo-mark-btn,
.jeeta-app * .change-page-btns .undo-mark-btn,
.jeeta-app * .zoom-controls .undo-mark-btn,
.jeeta-app * .annotation-controls .undo-mark-btn,
.customModal .change-page-btns .undo-mark-btn,
.customModal .zoom-controls .undo-mark-btn,
.customModal .annotation-controls .undo-mark-btn, .customModal * .change-page-btns .undo-mark-btn,
.customModal * .zoom-controls .undo-mark-btn,
.customModal * .annotation-controls .undo-mark-btn {
  color: var(--whiteColor);
  background-color: var(--peach);
}
.jeeta-app .change-page-btns .delete-mark-btn,
.jeeta-app .zoom-controls .delete-mark-btn,
.jeeta-app .annotation-controls .delete-mark-btn,
.jeeta-app * .change-page-btns .delete-mark-btn,
.jeeta-app * .zoom-controls .delete-mark-btn,
.jeeta-app * .annotation-controls .delete-mark-btn,
.customModal .change-page-btns .delete-mark-btn,
.customModal .zoom-controls .delete-mark-btn,
.customModal .annotation-controls .delete-mark-btn, .customModal * .change-page-btns .delete-mark-btn,
.customModal * .zoom-controls .delete-mark-btn,
.customModal * .annotation-controls .delete-mark-btn {
  color: var(--whiteColor);
  background-color: var(--tiger-lilly);
}
.jeeta-app .loadingPageWrapper,
.jeeta-app * .loadingPageWrapper,
.customModal .loadingPageWrapper, .customModal * .loadingPageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.jeeta-app .loadingPageWrapper .page-loading,
.jeeta-app * .loadingPageWrapper .page-loading,
.customModal .loadingPageWrapper .page-loading, .customModal * .loadingPageWrapper .page-loading {
  height: 100%;
}
@media all and (max-width: 500px) {
  .jeeta-app .reading-material-view,
  .jeeta-app * .reading-material-view,
  .customModal .reading-material-view, .customModal * .reading-material-view {
    width: 95%;
    height: 95%;
    padding: 0.5rem;
  }
  .jeeta-app .reading-material-view #close,
  .jeeta-app * .reading-material-view #close,
  .customModal .reading-material-view #close, .customModal * .reading-material-view #close {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    color: var(--PrimaryColor);
    align-self: center;
    font-size: 1.67rem;
  }
  .jeeta-app .reading-material-view #close:hover,
  .jeeta-app * .reading-material-view #close:hover,
  .customModal .reading-material-view #close:hover, .customModal * .reading-material-view #close:hover {
    color: var(--tiger-lilly);
  }
  .jeeta-app .pdf-controls,
  .jeeta-app * .pdf-controls,
  .customModal .pdf-controls, .customModal * .pdf-controls {
    padding: 0 1.5rem 0 0;
    gap: 0.5rem;
  }
  .jeeta-app .pdf-controls .zoom-controls,
  .jeeta-app .pdf-controls .change-page-btns,
  .jeeta-app .pdf-controls .annotation-controls,
  .jeeta-app * .pdf-controls .zoom-controls,
  .jeeta-app * .pdf-controls .change-page-btns,
  .jeeta-app * .pdf-controls .annotation-controls,
  .customModal .pdf-controls .zoom-controls,
  .customModal .pdf-controls .change-page-btns,
  .customModal .pdf-controls .annotation-controls, .customModal * .pdf-controls .zoom-controls,
  .customModal * .pdf-controls .change-page-btns,
  .customModal * .pdf-controls .annotation-controls {
    transform: scale(0.85);
  }
  .jeeta-app .pdf-controls .annotation-controls .undo-mark-btn,
  .jeeta-app .pdf-controls .annotation-controls .create-mark-btn,
  .jeeta-app .pdf-controls .annotation-controls .delete-mark-btn,
  .jeeta-app * .pdf-controls .annotation-controls .undo-mark-btn,
  .jeeta-app * .pdf-controls .annotation-controls .create-mark-btn,
  .jeeta-app * .pdf-controls .annotation-controls .delete-mark-btn,
  .customModal .pdf-controls .annotation-controls .undo-mark-btn,
  .customModal .pdf-controls .annotation-controls .create-mark-btn,
  .customModal .pdf-controls .annotation-controls .delete-mark-btn, .customModal * .pdf-controls .annotation-controls .undo-mark-btn,
  .customModal * .pdf-controls .annotation-controls .create-mark-btn,
  .customModal * .pdf-controls .annotation-controls .delete-mark-btn {
    padding: 0.5rem;
    border-radius: 50%;
  }
  .jeeta-app .pdf-controls .annotation-controls .undo-mark-btn p,
  .jeeta-app .pdf-controls .annotation-controls .create-mark-btn p,
  .jeeta-app .pdf-controls .annotation-controls .delete-mark-btn p,
  .jeeta-app * .pdf-controls .annotation-controls .undo-mark-btn p,
  .jeeta-app * .pdf-controls .annotation-controls .create-mark-btn p,
  .jeeta-app * .pdf-controls .annotation-controls .delete-mark-btn p,
  .customModal .pdf-controls .annotation-controls .undo-mark-btn p,
  .customModal .pdf-controls .annotation-controls .create-mark-btn p,
  .customModal .pdf-controls .annotation-controls .delete-mark-btn p, .customModal * .pdf-controls .annotation-controls .undo-mark-btn p,
  .customModal * .pdf-controls .annotation-controls .create-mark-btn p,
  .customModal * .pdf-controls .annotation-controls .delete-mark-btn p {
    display: none;
  }
}/*# sourceMappingURL=ReadingMaterialView.css.map */