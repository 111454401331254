.jeeta-app .question-card,
.jeeta-app .paragraph-card,
.jeeta-app * .question-card,
.jeeta-app * .paragraph-card,
.customModal .question-card,
.customModal .paragraph-card, .customModal * .question-card,
.customModal * .paragraph-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: none;
  color: var(--textColor);
  background-color: var(--whiteColor);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 35px 0.5rem 45px 0.5rem;
  gap: 0.5rem;
  width: 100%;
  font-size: 0.9rem;
}
.jeeta-app .question-card:hover,
.jeeta-app .paragraph-card:hover,
.jeeta-app * .question-card:hover,
.jeeta-app * .paragraph-card:hover,
.customModal .question-card:hover,
.customModal .paragraph-card:hover, .customModal * .question-card:hover,
.customModal * .paragraph-card:hover {
  cursor: pointer;
  background: var(--alice-blue);
  box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
  transition: 0.3s ease;
}
.jeeta-app .question-card:hover .delete-btn,
.jeeta-app .paragraph-card:hover .delete-btn,
.jeeta-app * .question-card:hover .delete-btn,
.jeeta-app * .paragraph-card:hover .delete-btn,
.customModal .question-card:hover .delete-btn,
.customModal .paragraph-card:hover .delete-btn, .customModal * .question-card:hover .delete-btn,
.customModal * .paragraph-card:hover .delete-btn {
  display: flex;
  justify-content: center;
  border: none;
  padding: 0.3rem;
  border-radius: 0.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--tiger-lilly);
  color: white;
}
.jeeta-app .question-card:hover .delete-btn .icon,
.jeeta-app .paragraph-card:hover .delete-btn .icon,
.jeeta-app * .question-card:hover .delete-btn .icon,
.jeeta-app * .paragraph-card:hover .delete-btn .icon,
.customModal .question-card:hover .delete-btn .icon,
.customModal .paragraph-card:hover .delete-btn .icon, .customModal * .question-card:hover .delete-btn .icon,
.customModal * .paragraph-card:hover .delete-btn .icon {
  font-size: 1.16rem;
}
.jeeta-app .question-card .question-text,
.jeeta-app .question-card .paragraph-text,
.jeeta-app .paragraph-card .question-text,
.jeeta-app .paragraph-card .paragraph-text,
.jeeta-app * .question-card .question-text,
.jeeta-app * .question-card .paragraph-text,
.jeeta-app * .paragraph-card .question-text,
.jeeta-app * .paragraph-card .paragraph-text,
.customModal .question-card .question-text,
.customModal .question-card .paragraph-text,
.customModal .paragraph-card .question-text,
.customModal .paragraph-card .paragraph-text, .customModal * .question-card .question-text,
.customModal * .question-card .paragraph-text,
.customModal * .paragraph-card .question-text,
.customModal * .paragraph-card .paragraph-text {
  width: 100%;
  margin: 0.5rem;
}
.jeeta-app .question-card .question-text span,
.jeeta-app .question-card .paragraph-text span,
.jeeta-app .paragraph-card .question-text span,
.jeeta-app .paragraph-card .paragraph-text span,
.jeeta-app * .question-card .question-text span,
.jeeta-app * .question-card .paragraph-text span,
.jeeta-app * .paragraph-card .question-text span,
.jeeta-app * .paragraph-card .paragraph-text span,
.customModal .question-card .question-text span,
.customModal .question-card .paragraph-text span,
.customModal .paragraph-card .question-text span,
.customModal .paragraph-card .paragraph-text span, .customModal * .question-card .question-text span,
.customModal * .question-card .paragraph-text span,
.customModal * .paragraph-card .question-text span,
.customModal * .paragraph-card .paragraph-text span {
  font-family: "Frank Ruhl Libre", serif;
}
.jeeta-app .question-card .question-image,
.jeeta-app .question-card .paragraph-image,
.jeeta-app .paragraph-card .question-image,
.jeeta-app .paragraph-card .paragraph-image,
.jeeta-app * .question-card .question-image,
.jeeta-app * .question-card .paragraph-image,
.jeeta-app * .paragraph-card .question-image,
.jeeta-app * .paragraph-card .paragraph-image,
.customModal .question-card .question-image,
.customModal .question-card .paragraph-image,
.customModal .paragraph-card .question-image,
.customModal .paragraph-card .paragraph-image, .customModal * .question-card .question-image,
.customModal * .question-card .paragraph-image,
.customModal * .paragraph-card .question-image,
.customModal * .paragraph-card .paragraph-image {
  display: block;
}
.jeeta-app .question-card .question-image image,
.jeeta-app .question-card .paragraph-image image,
.jeeta-app .paragraph-card .question-image image,
.jeeta-app .paragraph-card .paragraph-image image,
.jeeta-app * .question-card .question-image image,
.jeeta-app * .question-card .paragraph-image image,
.jeeta-app * .paragraph-card .question-image image,
.jeeta-app * .paragraph-card .paragraph-image image,
.customModal .question-card .question-image image,
.customModal .question-card .paragraph-image image,
.customModal .paragraph-card .question-image image,
.customModal .paragraph-card .paragraph-image image, .customModal * .question-card .question-image image,
.customModal * .question-card .paragraph-image image,
.customModal * .paragraph-card .question-image image,
.customModal * .paragraph-card .paragraph-image image {
  width: 100%;
  max-height: 30%;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .question-card .answers,
.jeeta-app .paragraph-card .answers,
.jeeta-app * .question-card .answers,
.jeeta-app * .paragraph-card .answers,
.customModal .question-card .answers,
.customModal .paragraph-card .answers, .customModal * .question-card .answers,
.customModal * .paragraph-card .answers {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.jeeta-app .question-card .answers .answer,
.jeeta-app .paragraph-card .answers .answer,
.jeeta-app * .question-card .answers .answer,
.jeeta-app * .paragraph-card .answers .answer,
.customModal .question-card .answers .answer,
.customModal .paragraph-card .answers .answer, .customModal * .question-card .answers .answer,
.customModal * .paragraph-card .answers .answer {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.2s;
}
.jeeta-app .question-card .answers .answer.correct,
.jeeta-app .paragraph-card .answers .answer.correct,
.jeeta-app * .question-card .answers .answer.correct,
.jeeta-app * .paragraph-card .answers .answer.correct,
.customModal .question-card .answers .answer.correct,
.customModal .paragraph-card .answers .answer.correct, .customModal * .question-card .answers .answer.correct,
.customModal * .paragraph-card .answers .answer.correct {
  border-color: #f1e740;
  background-color: #e8f5e9;
}
.jeeta-app .question-card .answers .answer.correct:hover,
.jeeta-app .paragraph-card .answers .answer.correct:hover,
.jeeta-app * .question-card .answers .answer.correct:hover,
.jeeta-app * .paragraph-card .answers .answer.correct:hover,
.customModal .question-card .answers .answer.correct:hover,
.customModal .paragraph-card .answers .answer.correct:hover, .customModal * .question-card .answers .answer.correct:hover,
.customModal * .paragraph-card .answers .answer.correct:hover {
  background-color: #c8e6c9;
}
.jeeta-app .question-card .answers .answer:hover,
.jeeta-app .paragraph-card .answers .answer:hover,
.jeeta-app * .question-card .answers .answer:hover,
.jeeta-app * .paragraph-card .answers .answer:hover,
.customModal .question-card .answers .answer:hover,
.customModal .paragraph-card .answers .answer:hover, .customModal * .question-card .answers .answer:hover,
.customModal * .paragraph-card .answers .answer:hover {
  background-color: #f5f5f5;
}
.jeeta-app .question-card .question-topics-list,
.jeeta-app .paragraph-card .question-topics-list,
.jeeta-app * .question-card .question-topics-list,
.jeeta-app * .paragraph-card .question-topics-list,
.customModal .question-card .question-topics-list,
.customModal .paragraph-card .question-topics-list, .customModal * .question-card .question-topics-list,
.customModal * .paragraph-card .question-topics-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-direction: row;
  gap: 0.3rem;
  padding: 0.5rem;
  overflow: auto;
}
.jeeta-app .question-card .question-topics-list .question-topic,
.jeeta-app .paragraph-card .question-topics-list .question-topic,
.jeeta-app * .question-card .question-topics-list .question-topic,
.jeeta-app * .paragraph-card .question-topics-list .question-topic,
.customModal .question-card .question-topics-list .question-topic,
.customModal .paragraph-card .question-topics-list .question-topic, .customModal * .question-card .question-topics-list .question-topic,
.customModal * .paragraph-card .question-topics-list .question-topic {
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  overflow: visible;
  white-space: nowrap;
}
.jeeta-app .question-card .question-topics-list .question-topic:hover,
.jeeta-app .paragraph-card .question-topics-list .question-topic:hover,
.jeeta-app * .question-card .question-topics-list .question-topic:hover,
.jeeta-app * .paragraph-card .question-topics-list .question-topic:hover,
.customModal .question-card .question-topics-list .question-topic:hover,
.customModal .paragraph-card .question-topics-list .question-topic:hover, .customModal * .question-card .question-topics-list .question-topic:hover,
.customModal * .paragraph-card .question-topics-list .question-topic:hover {
  background-color: var(--SecondaryColor);
  font-weight: 600;
}
.jeeta-app .question-card .question-topics-list::-webkit-scrollbar,
.jeeta-app .paragraph-card .question-topics-list::-webkit-scrollbar,
.jeeta-app * .question-card .question-topics-list::-webkit-scrollbar,
.jeeta-app * .paragraph-card .question-topics-list::-webkit-scrollbar,
.customModal .question-card .question-topics-list::-webkit-scrollbar,
.customModal .paragraph-card .question-topics-list::-webkit-scrollbar, .customModal * .question-card .question-topics-list::-webkit-scrollbar,
.customModal * .paragraph-card .question-topics-list::-webkit-scrollbar {
  height: 0.8rem;
  /* Adjust width as needed */
}
.jeeta-app .question-card .question-topics-list::-webkit-scrollbar-track,
.jeeta-app .paragraph-card .question-topics-list::-webkit-scrollbar-track,
.jeeta-app * .question-card .question-topics-list::-webkit-scrollbar-track,
.jeeta-app * .paragraph-card .question-topics-list::-webkit-scrollbar-track,
.customModal .question-card .question-topics-list::-webkit-scrollbar-track,
.customModal .paragraph-card .question-topics-list::-webkit-scrollbar-track, .customModal * .question-card .question-topics-list::-webkit-scrollbar-track,
.customModal * .paragraph-card .question-topics-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.5rem;
  /* Adjust track background color as needed */
}
.jeeta-app .question-card .question-topics-list::-webkit-scrollbar-thumb,
.jeeta-app .paragraph-card .question-topics-list::-webkit-scrollbar-thumb,
.jeeta-app * .question-card .question-topics-list::-webkit-scrollbar-thumb,
.jeeta-app * .paragraph-card .question-topics-list::-webkit-scrollbar-thumb,
.customModal .question-card .question-topics-list::-webkit-scrollbar-thumb,
.customModal .paragraph-card .question-topics-list::-webkit-scrollbar-thumb, .customModal * .question-card .question-topics-list::-webkit-scrollbar-thumb,
.customModal * .paragraph-card .question-topics-list::-webkit-scrollbar-thumb {
  background-color: #a7bad9;
  border-radius: 0.5rem;
  /* Adjust border-radius as needed */
  border: 1px solid #a7bad9;
  /* Adjust border and color as needed */
}
.jeeta-app .question-card .question-topics-list .question-tag,
.jeeta-app .paragraph-card .question-topics-list .question-tag,
.jeeta-app * .question-card .question-topics-list .question-tag,
.jeeta-app * .paragraph-card .question-topics-list .question-tag,
.customModal .question-card .question-topics-list .question-tag,
.customModal .paragraph-card .question-topics-list .question-tag, .customModal * .question-card .question-topics-list .question-tag,
.customModal * .paragraph-card .question-topics-list .question-tag {
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
  background-color: var(--orange);
  color: var(--whiteColor);
  overflow: visible;
  white-space: nowrap;
}
.jeeta-app .question-card .question-topics-list .question-tag:hover,
.jeeta-app .paragraph-card .question-topics-list .question-tag:hover,
.jeeta-app * .question-card .question-topics-list .question-tag:hover,
.jeeta-app * .paragraph-card .question-topics-list .question-tag:hover,
.customModal .question-card .question-topics-list .question-tag:hover,
.customModal .paragraph-card .question-topics-list .question-tag:hover, .customModal * .question-card .question-topics-list .question-tag:hover,
.customModal * .paragraph-card .question-topics-list .question-tag:hover {
  background-color: var(--amber);
  font-weight: 600;
}
.jeeta-app .question-card .question-info,
.jeeta-app .paragraph-card .question-info,
.jeeta-app * .question-card .question-info,
.jeeta-app * .paragraph-card .question-info,
.customModal .question-card .question-info,
.customModal .paragraph-card .question-info, .customModal * .question-card .question-info,
.customModal * .paragraph-card .question-info {
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;
}
.jeeta-app .question-card .question-id,
.jeeta-app .paragraph-card .question-id,
.jeeta-app * .question-card .question-id,
.jeeta-app * .paragraph-card .question-id,
.customModal .question-card .question-id,
.customModal .paragraph-card .question-id, .customModal * .question-card .question-id,
.customModal * .paragraph-card .question-id {
  background-color: var(--PrimaryColor);
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.87rem;
  font-weight: 600;
}
.jeeta-app .question-card #Physics,
.jeeta-app .paragraph-card #Physics,
.jeeta-app * .question-card #Physics,
.jeeta-app * .paragraph-card #Physics,
.customModal .question-card #Physics,
.customModal .paragraph-card #Physics, .customModal * .question-card #Physics,
.customModal * .paragraph-card #Physics {
  background-color: var(--Physics);
}
.jeeta-app .question-card #Physics:hover,
.jeeta-app .paragraph-card #Physics:hover,
.jeeta-app * .question-card #Physics:hover,
.jeeta-app * .paragraph-card #Physics:hover,
.customModal .question-card #Physics:hover,
.customModal .paragraph-card #Physics:hover, .customModal * .question-card #Physics:hover,
.customModal * .paragraph-card #Physics:hover {
  color: var(--Physics-accent);
}
.jeeta-app .question-card #Chemistry,
.jeeta-app .paragraph-card #Chemistry,
.jeeta-app * .question-card #Chemistry,
.jeeta-app * .paragraph-card #Chemistry,
.customModal .question-card #Chemistry,
.customModal .paragraph-card #Chemistry, .customModal * .question-card #Chemistry,
.customModal * .paragraph-card #Chemistry {
  background-color: var(--Chemistry);
}
.jeeta-app .question-card #Chemistry:hover,
.jeeta-app .paragraph-card #Chemistry:hover,
.jeeta-app * .question-card #Chemistry:hover,
.jeeta-app * .paragraph-card #Chemistry:hover,
.customModal .question-card #Chemistry:hover,
.customModal .paragraph-card #Chemistry:hover, .customModal * .question-card #Chemistry:hover,
.customModal * .paragraph-card #Chemistry:hover {
  color: var(--Chemistry-accent);
}
.jeeta-app .question-card #Mathematics,
.jeeta-app .paragraph-card #Mathematics,
.jeeta-app * .question-card #Mathematics,
.jeeta-app * .paragraph-card #Mathematics,
.customModal .question-card #Mathematics,
.customModal .paragraph-card #Mathematics, .customModal * .question-card #Mathematics,
.customModal * .paragraph-card #Mathematics {
  background-color: var(--Mathematics);
}
.jeeta-app .question-card #Mathematics:hover,
.jeeta-app .paragraph-card #Mathematics:hover,
.jeeta-app * .question-card #Mathematics:hover,
.jeeta-app * .paragraph-card #Mathematics:hover,
.customModal .question-card #Mathematics:hover,
.customModal .paragraph-card #Mathematics:hover, .customModal * .question-card #Mathematics:hover,
.customModal * .paragraph-card #Mathematics:hover {
  color: var(--Mathematics-accent);
}
.jeeta-app .question-card .question-saved,
.jeeta-app .paragraph-card .question-saved,
.jeeta-app * .question-card .question-saved,
.jeeta-app * .paragraph-card .question-saved,
.customModal .question-card .question-saved,
.customModal .paragraph-card .question-saved, .customModal * .question-card .question-saved,
.customModal * .paragraph-card .question-saved {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.2rem;
  gap: 0.2rem;
}
.jeeta-app .question-card .question-saved .saved-btn,
.jeeta-app .paragraph-card .question-saved .saved-btn,
.jeeta-app * .question-card .question-saved .saved-btn,
.jeeta-app * .paragraph-card .question-saved .saved-btn,
.customModal .question-card .question-saved .saved-btn,
.customModal .paragraph-card .question-saved .saved-btn, .customModal * .question-card .question-saved .saved-btn,
.customModal * .paragraph-card .question-saved .saved-btn {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
.jeeta-app .question-card .question-saved .saved-btn .icon,
.jeeta-app .paragraph-card .question-saved .saved-btn .icon,
.jeeta-app * .question-card .question-saved .saved-btn .icon,
.jeeta-app * .paragraph-card .question-saved .saved-btn .icon,
.customModal .question-card .question-saved .saved-btn .icon,
.customModal .paragraph-card .question-saved .saved-btn .icon, .customModal * .question-card .question-saved .saved-btn .icon,
.customModal * .paragraph-card .question-saved .saved-btn .icon {
  font-size: 1.6rem;
}
.jeeta-app .question-card .question-saved #star-btn,
.jeeta-app .paragraph-card .question-saved #star-btn,
.jeeta-app * .question-card .question-saved #star-btn,
.jeeta-app * .paragraph-card .question-saved #star-btn,
.customModal .question-card .question-saved #star-btn,
.customModal .paragraph-card .question-saved #star-btn, .customModal * .question-card .question-saved #star-btn,
.customModal * .paragraph-card .question-saved #star-btn {
  color: var(--PrimaryColor);
}
.jeeta-app .question-card .question-saved #review-btn,
.jeeta-app .paragraph-card .question-saved #review-btn,
.jeeta-app * .question-card .question-saved #review-btn,
.jeeta-app * .paragraph-card .question-saved #review-btn,
.customModal .question-card .question-saved #review-btn,
.customModal .paragraph-card .question-saved #review-btn, .customModal * .question-card .question-saved #review-btn,
.customModal * .paragraph-card .question-saved #review-btn {
  padding-top: 0.2rem;
  color: var(--kelly-green);
}
.jeeta-app .question-card .question-difficulty,
.jeeta-app .paragraph-card .question-difficulty,
.jeeta-app * .question-card .question-difficulty,
.jeeta-app * .paragraph-card .question-difficulty,
.customModal .question-card .question-difficulty,
.customModal .paragraph-card .question-difficulty, .customModal * .question-card .question-difficulty,
.customModal * .paragraph-card .question-difficulty {
  background-color: var(--SecondaryColor);
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.87rem;
  font-weight: 600;
}
.jeeta-app .question-card #easy,
.jeeta-app .paragraph-card #easy,
.jeeta-app * .question-card #easy,
.jeeta-app * .paragraph-card #easy,
.customModal .question-card #easy,
.customModal .paragraph-card #easy, .customModal * .question-card #easy,
.customModal * .paragraph-card #easy {
  background-color: var(--easy);
}
.jeeta-app .question-card #medium,
.jeeta-app .paragraph-card #medium,
.jeeta-app * .question-card #medium,
.jeeta-app * .paragraph-card #medium,
.customModal .question-card #medium,
.customModal .paragraph-card #medium, .customModal * .question-card #medium,
.customModal * .paragraph-card #medium {
  background-color: var(--medium);
}
.jeeta-app .question-card #hard,
.jeeta-app .paragraph-card #hard,
.jeeta-app * .question-card #hard,
.jeeta-app * .paragraph-card #hard,
.customModal .question-card #hard,
.customModal .paragraph-card #hard, .customModal * .question-card #hard,
.customModal * .paragraph-card #hard {
  background-color: var(--hard);
}
.jeeta-app .question-card .delete-btn,
.jeeta-app .paragraph-card .delete-btn,
.jeeta-app * .question-card .delete-btn,
.jeeta-app * .paragraph-card .delete-btn,
.customModal .question-card .delete-btn,
.customModal .paragraph-card .delete-btn, .customModal * .question-card .delete-btn,
.customModal * .paragraph-card .delete-btn {
  display: none;
}
.jeeta-app .question-card .answer-marks,
.jeeta-app .paragraph-card .answer-marks,
.jeeta-app * .question-card .answer-marks,
.jeeta-app * .paragraph-card .answer-marks,
.customModal .question-card .answer-marks,
.customModal .paragraph-card .answer-marks, .customModal * .question-card .answer-marks,
.customModal * .paragraph-card .answer-marks {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  gap: 0.2rem;
}
.jeeta-app .question-card .answer-marks .mark,
.jeeta-app .paragraph-card .answer-marks .mark,
.jeeta-app * .question-card .answer-marks .mark,
.jeeta-app * .paragraph-card .answer-marks .mark,
.customModal .question-card .answer-marks .mark,
.customModal .paragraph-card .answer-marks .mark, .customModal * .question-card .answer-marks .mark,
.customModal * .paragraph-card .answer-marks .mark {
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .question-card .answer-marks .mark p,
.jeeta-app .paragraph-card .answer-marks .mark p,
.jeeta-app * .question-card .answer-marks .mark p,
.jeeta-app * .paragraph-card .answer-marks .mark p,
.customModal .question-card .answer-marks .mark p,
.customModal .paragraph-card .answer-marks .mark p, .customModal * .question-card .answer-marks .mark p,
.customModal * .paragraph-card .answer-marks .mark p {
  font-size: 1rem;
  font-weight: 500;
}
.jeeta-app .question-card .answer-marks .mark .icon,
.jeeta-app .paragraph-card .answer-marks .mark .icon,
.jeeta-app * .question-card .answer-marks .mark .icon,
.jeeta-app * .paragraph-card .answer-marks .mark .icon,
.customModal .question-card .answer-marks .mark .icon,
.customModal .paragraph-card .answer-marks .mark .icon, .customModal * .question-card .answer-marks .mark .icon,
.customModal * .paragraph-card .answer-marks .mark .icon {
  font-size: 1.11rem;
}
.jeeta-app .question-card .answer-marks #correct .icon,
.jeeta-app .paragraph-card .answer-marks #correct .icon,
.jeeta-app * .question-card .answer-marks #correct .icon,
.jeeta-app * .paragraph-card .answer-marks #correct .icon,
.customModal .question-card .answer-marks #correct .icon,
.customModal .paragraph-card .answer-marks #correct .icon, .customModal * .question-card .answer-marks #correct .icon,
.customModal * .paragraph-card .answer-marks #correct .icon {
  color: var(--Chemistry);
}
.jeeta-app .question-card .answer-marks #incorrect .icon,
.jeeta-app .paragraph-card .answer-marks #incorrect .icon,
.jeeta-app * .question-card .answer-marks #incorrect .icon,
.jeeta-app * .paragraph-card .answer-marks #incorrect .icon,
.customModal .question-card .answer-marks #incorrect .icon,
.customModal .paragraph-card .answer-marks #incorrect .icon, .customModal * .question-card .answer-marks #incorrect .icon,
.customModal * .paragraph-card .answer-marks #incorrect .icon {
  color: var(--tiger-lilly);
}
.jeeta-app .question-card .answer-marks #unanswered .icon,
.jeeta-app .paragraph-card .answer-marks #unanswered .icon,
.jeeta-app * .question-card .answer-marks #unanswered .icon,
.jeeta-app * .paragraph-card .answer-marks #unanswered .icon,
.customModal .question-card .answer-marks #unanswered .icon,
.customModal .paragraph-card .answer-marks #unanswered .icon, .customModal * .question-card .answer-marks #unanswered .icon,
.customModal * .paragraph-card .answer-marks #unanswered .icon {
  color: var(--greyText);
}
.jeeta-app .question-card .delete-popup,
.jeeta-app .paragraph-card .delete-popup,
.jeeta-app * .question-card .delete-popup,
.jeeta-app * .paragraph-card .delete-popup,
.customModal .question-card .delete-popup,
.customModal .paragraph-card .delete-popup, .customModal * .question-card .delete-popup,
.customModal * .paragraph-card .delete-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background-color: var(--whiteColorDeam);
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.jeeta-app .question-card .delete-popup h3,
.jeeta-app .paragraph-card .delete-popup h3,
.jeeta-app * .question-card .delete-popup h3,
.jeeta-app * .paragraph-card .delete-popup h3,
.customModal .question-card .delete-popup h3,
.customModal .paragraph-card .delete-popup h3, .customModal * .question-card .delete-popup h3,
.customModal * .paragraph-card .delete-popup h3 {
  white-space: nowrap;
}
.jeeta-app .question-card .delete-popup .btns,
.jeeta-app .paragraph-card .delete-popup .btns,
.jeeta-app * .question-card .delete-popup .btns,
.jeeta-app * .paragraph-card .delete-popup .btns,
.customModal .question-card .delete-popup .btns,
.customModal .paragraph-card .delete-popup .btns, .customModal * .question-card .delete-popup .btns,
.customModal * .paragraph-card .delete-popup .btns {
  gap: 1rem;
}
.jeeta-app .questionCardListFilter,
.jeeta-app * .questionCardListFilter,
.customModal .questionCardListFilter, .customModal * .questionCardListFilter {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 1;
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .questionCardListFilter .filterTitle,
.jeeta-app * .questionCardListFilter .filterTitle,
.customModal .questionCardListFilter .filterTitle, .customModal * .questionCardListFilter .filterTitle {
  width: 100%;
  border-radius: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}
.jeeta-app .questionCardListFilter .filterTitle .title,
.jeeta-app * .questionCardListFilter .filterTitle .title,
.customModal .questionCardListFilter .filterTitle .title, .customModal * .questionCardListFilter .filterTitle .title {
  text-align: center;
  color: var(--SecondaryColor);
}
.jeeta-app .questionCardListFilter .filterTitle span,
.jeeta-app * .questionCardListFilter .filterTitle span,
.customModal .questionCardListFilter .filterTitle span, .customModal * .questionCardListFilter .filterTitle span {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}
.jeeta-app .questionCardListFilter .checkInput,
.jeeta-app * .questionCardListFilter .checkInput,
.customModal .questionCardListFilter .checkInput, .customModal * .questionCardListFilter .checkInput {
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  font-weight: 600;
  font-size: 0.94rem;
}
.jeeta-app .questionCardListFilter .checkInput .unattemptedInput,
.jeeta-app * .questionCardListFilter .checkInput .unattemptedInput,
.customModal .questionCardListFilter .checkInput .unattemptedInput, .customModal * .questionCardListFilter .checkInput .unattemptedInput {
  color: var(--peach);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .questionCardListFilter .checkInput .unattemptedInput .icon,
.jeeta-app * .questionCardListFilter .checkInput .unattemptedInput .icon,
.customModal .questionCardListFilter .checkInput .unattemptedInput .icon, .customModal * .questionCardListFilter .checkInput .unattemptedInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .questionCardListFilter .checkInput .starInput,
.jeeta-app * .questionCardListFilter .checkInput .starInput,
.customModal .questionCardListFilter .checkInput .starInput, .customModal * .questionCardListFilter .checkInput .starInput {
  color: var(--PrimaryColor);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .questionCardListFilter .checkInput .starInput .icon,
.jeeta-app * .questionCardListFilter .checkInput .starInput .icon,
.customModal .questionCardListFilter .checkInput .starInput .icon, .customModal * .questionCardListFilter .checkInput .starInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .questionCardListFilter .checkInput .reviewInput,
.jeeta-app * .questionCardListFilter .checkInput .reviewInput,
.customModal .questionCardListFilter .checkInput .reviewInput, .customModal * .questionCardListFilter .checkInput .reviewInput {
  color: var(--kelly-green);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .questionCardListFilter .checkInput .reviewInput .icon,
.jeeta-app * .questionCardListFilter .checkInput .reviewInput .icon,
.customModal .questionCardListFilter .checkInput .reviewInput .icon, .customModal * .questionCardListFilter .checkInput .reviewInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .questionCardListFilter .checkInput .incorrectInput,
.jeeta-app * .questionCardListFilter .checkInput .incorrectInput,
.customModal .questionCardListFilter .checkInput .incorrectInput, .customModal * .questionCardListFilter .checkInput .incorrectInput {
  color: var(--tiger-lilly);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .questionCardListFilter .checkInput .incorrectInput .icon,
.jeeta-app * .questionCardListFilter .checkInput .incorrectInput .icon,
.customModal .questionCardListFilter .checkInput .incorrectInput .icon, .customModal * .questionCardListFilter .checkInput .incorrectInput .icon {
  font-size: 0.95rem;
}
.jeeta-app .questionCardListFilter .filters,
.jeeta-app * .questionCardListFilter .filters,
.customModal .questionCardListFilter .filters, .customModal * .questionCardListFilter .filters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .questionCardListFilter .filters .filterSelect,
.jeeta-app .questionCardListFilter .filters .questionIDInput,
.jeeta-app .questionCardListFilter .filters .searchTextInput,
.jeeta-app * .questionCardListFilter .filters .filterSelect,
.jeeta-app * .questionCardListFilter .filters .questionIDInput,
.jeeta-app * .questionCardListFilter .filters .searchTextInput,
.customModal .questionCardListFilter .filters .filterSelect,
.customModal .questionCardListFilter .filters .questionIDInput,
.customModal .questionCardListFilter .filters .searchTextInput, .customModal * .questionCardListFilter .filters .filterSelect,
.customModal * .questionCardListFilter .filters .questionIDInput,
.customModal * .questionCardListFilter .filters .searchTextInput {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .questionCardListFilter .filters .filterSelect .title,
.jeeta-app .questionCardListFilter .filters .questionIDInput .title,
.jeeta-app .questionCardListFilter .filters .searchTextInput .title,
.jeeta-app * .questionCardListFilter .filters .filterSelect .title,
.jeeta-app * .questionCardListFilter .filters .questionIDInput .title,
.jeeta-app * .questionCardListFilter .filters .searchTextInput .title,
.customModal .questionCardListFilter .filters .filterSelect .title,
.customModal .questionCardListFilter .filters .questionIDInput .title,
.customModal .questionCardListFilter .filters .searchTextInput .title, .customModal * .questionCardListFilter .filters .filterSelect .title,
.customModal * .questionCardListFilter .filters .questionIDInput .title,
.customModal * .questionCardListFilter .filters .searchTextInput .title {
  padding: 0.35rem 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  color: var(--textColor);
}
.jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdown,
.jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdown,
.jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple,
.jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdown,
.jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
.jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdown,
.jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdown,
.jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple,
.jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdown,
.jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
.customModal .questionCardListFilter .filters .filterSelect .selectionDropdown,
.customModal .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.customModal .questionCardListFilter .filters .questionIDInput .selectionDropdown,
.customModal .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple,
.customModal .questionCardListFilter .filters .searchTextInput .selectionDropdown,
.customModal .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple, .customModal * .questionCardListFilter .filters .filterSelect .selectionDropdown,
.customModal * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdown,
.customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple,
.customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdown,
.customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple {
  flex-grow: 1;
}
.jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdown .css-13cymwt-control,
.jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdown .css-13cymwt-control,
.jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal .questionCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.customModal .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.customModal .questionCardListFilter .filters .questionIDInput .selectionDropdown .css-13cymwt-control,
.customModal .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal .questionCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.customModal .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control, .customModal * .questionCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.customModal * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdown .css-13cymwt-control,
.customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control {
  font-size: 0.95rem;
}
.jeeta-app .questionCardListFilter .filters .filterSelect input,
.jeeta-app .questionCardListFilter .filters .questionIDInput input,
.jeeta-app .questionCardListFilter .filters .searchTextInput input,
.jeeta-app * .questionCardListFilter .filters .filterSelect input,
.jeeta-app * .questionCardListFilter .filters .questionIDInput input,
.jeeta-app * .questionCardListFilter .filters .searchTextInput input,
.customModal .questionCardListFilter .filters .filterSelect input,
.customModal .questionCardListFilter .filters .questionIDInput input,
.customModal .questionCardListFilter .filters .searchTextInput input, .customModal * .questionCardListFilter .filters .filterSelect input,
.customModal * .questionCardListFilter .filters .questionIDInput input,
.customModal * .questionCardListFilter .filters .searchTextInput input {
  flex-grow: 1;
  border: hsl(0, 0%, 80%) solid 1px;
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: 0.95rem;
}
.jeeta-app .questionCardListFilter .filters .searchTextInput,
.jeeta-app * .questionCardListFilter .filters .searchTextInput,
.customModal .questionCardListFilter .filters .searchTextInput, .customModal * .questionCardListFilter .filters .searchTextInput {
  grid-column: span 2;
}
.jeeta-app .questionCardListFilter .btns,
.jeeta-app * .questionCardListFilter .btns,
.customModal .questionCardListFilter .btns, .customModal * .questionCardListFilter .btns {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .questionCardListFilter .btns #filter,
.jeeta-app .questionCardListFilter .btns #create,
.jeeta-app * .questionCardListFilter .btns #filter,
.jeeta-app * .questionCardListFilter .btns #create,
.customModal .questionCardListFilter .btns #filter,
.customModal .questionCardListFilter .btns #create, .customModal * .questionCardListFilter .btns #filter,
.customModal * .questionCardListFilter .btns #create {
  font-size: 0.83rem;
}
.jeeta-app .questionCardListFilter .btns #filter .icon,
.jeeta-app .questionCardListFilter .btns #create .icon,
.jeeta-app * .questionCardListFilter .btns #filter .icon,
.jeeta-app * .questionCardListFilter .btns #create .icon,
.customModal .questionCardListFilter .btns #filter .icon,
.customModal .questionCardListFilter .btns #create .icon, .customModal * .questionCardListFilter .btns #filter .icon,
.customModal * .questionCardListFilter .btns #create .icon {
  font-size: 1.05rem;
}
.jeeta-app .filter-type,
.jeeta-app * .filter-type,
.customModal .filter-type, .customModal * .filter-type {
  width: 95%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  gap: 0.01rem;
}
.jeeta-app .filter-type .filter,
.jeeta-app * .filter-type .filter,
.customModal .filter-type .filter, .customModal * .filter-type .filter {
  padding: 0.6rem 2rem 1.5rem 1rem;
  text-decoration: none;
  font-weight: 500;
  color: var(--whiteColor);
  background-color: var(--SecondaryColor);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  border-radius: 1rem;
  gap: 0.5rem;
}
.jeeta-app .filter-type .filter:hover,
.jeeta-app * .filter-type .filter:hover,
.customModal .filter-type .filter:hover, .customModal * .filter-type .filter:hover {
  cursor: pointer;
}
.jeeta-app .filter-type .filter .icon,
.jeeta-app * .filter-type .filter .icon,
.customModal .filter-type .filter .icon, .customModal * .filter-type .filter .icon {
  font-size: 1.2rem;
}
.jeeta-app .filter-type .filter.selected,
.jeeta-app * .filter-type .filter.selected,
.customModal .filter-type .filter.selected, .customModal * .filter-type .filter.selected {
  color: var(--whiteColor);
  background-color: var(--PrimaryColor);
  font-weight: 600;
}
@media all and (max-width: 1000px) {
  .jeeta-app .question-card,
  .jeeta-app * .question-card,
  .customModal .question-card, .customModal * .question-card {
    font-size: 0.83rem;
  }
  .jeeta-app .question-card:hover,
  .jeeta-app * .question-card:hover,
  .customModal .question-card:hover, .customModal * .question-card:hover {
    cursor: pointer;
    background: var(--alice-blue);
    box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
    transition: 0.3s ease;
  }
  .jeeta-app .question-card:hover .delete-btn,
  .jeeta-app * .question-card:hover .delete-btn,
  .customModal .question-card:hover .delete-btn, .customModal * .question-card:hover .delete-btn {
    display: flex;
    justify-content: center;
    border: none;
    padding: 0.3rem;
    border-radius: 0.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--tiger-lilly);
    color: white;
  }
  .jeeta-app .question-card:hover .delete-btn .icon,
  .jeeta-app * .question-card:hover .delete-btn .icon,
  .customModal .question-card:hover .delete-btn .icon, .customModal * .question-card:hover .delete-btn .icon {
    font-size: 1.16rem;
  }
  .jeeta-app .question-card .question-text,
  .jeeta-app * .question-card .question-text,
  .customModal .question-card .question-text, .customModal * .question-card .question-text {
    font-size: 0.95rem;
  }
  .jeeta-app .question-card .answers,
  .jeeta-app * .question-card .answers,
  .customModal .question-card .answers, .customModal * .question-card .answers {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .jeeta-app .question-card .answers .answer,
  .jeeta-app * .question-card .answers .answer,
  .customModal .question-card .answers .answer, .customModal * .question-card .answers .answer {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.2s;
  }
  .jeeta-app .question-card .answers .answer.correct,
  .jeeta-app * .question-card .answers .answer.correct,
  .customModal .question-card .answers .answer.correct, .customModal * .question-card .answers .answer.correct {
    border-color: #f1e740;
    background-color: #e8f5e9;
  }
  .jeeta-app .question-card .answers .answer.correct:hover,
  .jeeta-app * .question-card .answers .answer.correct:hover,
  .customModal .question-card .answers .answer.correct:hover, .customModal * .question-card .answers .answer.correct:hover {
    background-color: #c8e6c9;
  }
  .jeeta-app .question-card .answers .answer:hover,
  .jeeta-app * .question-card .answers .answer:hover,
  .customModal .question-card .answers .answer:hover, .customModal * .question-card .answers .answer:hover {
    background-color: #f5f5f5;
  }
  .jeeta-app .question-card .question-difficulty,
  .jeeta-app .question-card .question-id,
  .jeeta-app * .question-card .question-difficulty,
  .jeeta-app * .question-card .question-id,
  .customModal .question-card .question-difficulty,
  .customModal .question-card .question-id, .customModal * .question-card .question-difficulty,
  .customModal * .question-card .question-id {
    font-size: 0.94rem;
  }
  .jeeta-app .question-card .delete-btn,
  .jeeta-app * .question-card .delete-btn,
  .customModal .question-card .delete-btn, .customModal * .question-card .delete-btn {
    display: none;
  }
  .jeeta-app .question-card .delete-popup,
  .jeeta-app * .question-card .delete-popup,
  .customModal .question-card .delete-popup, .customModal * .question-card .delete-popup {
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem;
    background-color: var(--whiteColorDeam);
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .jeeta-app .question-card .delete-popup .btns,
  .jeeta-app * .question-card .delete-popup .btns,
  .customModal .question-card .delete-popup .btns, .customModal * .question-card .delete-popup .btns {
    gap: 1rem;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .questionCardListFilter .filterTitle span,
  .jeeta-app * .questionCardListFilter .filterTitle span,
  .customModal .questionCardListFilter .filterTitle span, .customModal * .questionCardListFilter .filterTitle span {
    font-size: 1.35rem;
  }
  .jeeta-app .questionCardListFilter .filters,
  .jeeta-app * .questionCardListFilter .filters,
  .customModal .questionCardListFilter .filters, .customModal * .questionCardListFilter .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .question-card,
  .jeeta-app * .question-card,
  .customModal .question-card, .customModal * .question-card {
    font-size: 0.8rem;
  }
  .jeeta-app .question-card .question-text,
  .jeeta-app * .question-card .question-text,
  .customModal .question-card .question-text, .customModal * .question-card .question-text {
    font-size: 0.9rem;
  }
  .jeeta-app .question-card .question-difficulty,
  .jeeta-app .question-card .question-id,
  .jeeta-app * .question-card .question-difficulty,
  .jeeta-app * .question-card .question-id,
  .customModal .question-card .question-difficulty,
  .customModal .question-card .question-id, .customModal * .question-card .question-difficulty,
  .customModal * .question-card .question-id {
    font-size: 0.83rem;
  }
  .jeeta-app .question-card .delete-btn,
  .jeeta-app * .question-card .delete-btn,
  .customModal .question-card .delete-btn, .customModal * .question-card .delete-btn {
    display: flex;
    justify-content: center;
    border: none;
    padding: 0.3rem;
    border-radius: 0.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--tiger-lilly);
    color: white;
  }
  .jeeta-app .question-card .delete-btn .icon,
  .jeeta-app * .question-card .delete-btn .icon,
  .customModal .question-card .delete-btn .icon, .customModal * .question-card .delete-btn .icon {
    font-size: 0.87rem;
  }
  .jeeta-app .question-card .delete-popup,
  .jeeta-app * .question-card .delete-popup,
  .customModal .question-card .delete-popup, .customModal * .question-card .delete-popup {
    transform: scale(0.9);
  }
  .jeeta-app .questionCardListFilter,
  .jeeta-app * .questionCardListFilter,
  .customModal .questionCardListFilter, .customModal * .questionCardListFilter {
    gap: 0.5rem;
  }
  .jeeta-app .questionCardListFilter .filterTitle span,
  .jeeta-app * .questionCardListFilter .filterTitle span,
  .customModal .questionCardListFilter .filterTitle span, .customModal * .questionCardListFilter .filterTitle span {
    font-size: 1.25rem;
  }
  .jeeta-app .questionCardListFilter .filters,
  .jeeta-app * .questionCardListFilter .filters,
  .customModal .questionCardListFilter .filters, .customModal * .questionCardListFilter .filters {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .jeeta-app .questionCardListFilter .filters .filterSelect,
  .jeeta-app .questionCardListFilter .filters .questionIDInput,
  .jeeta-app .questionCardListFilter .filters .searchTextInput,
  .jeeta-app * .questionCardListFilter .filters .filterSelect,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput,
  .customModal .questionCardListFilter .filters .filterSelect,
  .customModal .questionCardListFilter .filters .questionIDInput,
  .customModal .questionCardListFilter .filters .searchTextInput, .customModal * .questionCardListFilter .filters .filterSelect,
  .customModal * .questionCardListFilter .filters .questionIDInput,
  .customModal * .questionCardListFilter .filters .searchTextInput {
    padding: 0;
  }
  .jeeta-app .questionCardListFilter .filters .filterSelect .title,
  .jeeta-app .questionCardListFilter .filters .questionIDInput .title,
  .jeeta-app .questionCardListFilter .filters .searchTextInput .title,
  .jeeta-app * .questionCardListFilter .filters .filterSelect .title,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput .title,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput .title,
  .customModal .questionCardListFilter .filters .filterSelect .title,
  .customModal .questionCardListFilter .filters .questionIDInput .title,
  .customModal .questionCardListFilter .filters .searchTextInput .title, .customModal * .questionCardListFilter .filters .filterSelect .title,
  .customModal * .questionCardListFilter .filters .questionIDInput .title,
  .customModal * .questionCardListFilter .filters .searchTextInput .title {
    font-size: 0.9rem;
  }
  .jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdown,
  .jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .jeeta-app .questionCardListFilter .filters .filterSelect input,
  .jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdown,
  .jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple,
  .jeeta-app .questionCardListFilter .filters .questionIDInput input,
  .jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdown,
  .jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .jeeta-app .questionCardListFilter .filters .searchTextInput input,
  .jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdown,
  .jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .jeeta-app * .questionCardListFilter .filters .filterSelect input,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdown,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput input,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdown,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput input,
  .customModal .questionCardListFilter .filters .filterSelect .selectionDropdown,
  .customModal .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .customModal .questionCardListFilter .filters .filterSelect input,
  .customModal .questionCardListFilter .filters .questionIDInput .selectionDropdown,
  .customModal .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple,
  .customModal .questionCardListFilter .filters .questionIDInput input,
  .customModal .questionCardListFilter .filters .searchTextInput .selectionDropdown,
  .customModal .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .customModal .questionCardListFilter .filters .searchTextInput input, .customModal * .questionCardListFilter .filters .filterSelect .selectionDropdown,
  .customModal * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .customModal * .questionCardListFilter .filters .filterSelect input,
  .customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdown,
  .customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple,
  .customModal * .questionCardListFilter .filters .questionIDInput input,
  .customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdown,
  .customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .customModal * .questionCardListFilter .filters .searchTextInput input {
    font-size: 0.9rem;
  }
  .jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdown input,
  .jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .jeeta-app .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app .questionCardListFilter .filters .filterSelect input input,
  .jeeta-app .questionCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdown input,
  .jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdown .css-13cymwt-control,
  .jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple input,
  .jeeta-app .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app .questionCardListFilter .filters .questionIDInput input input,
  .jeeta-app .questionCardListFilter .filters .questionIDInput input .css-13cymwt-control,
  .jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdown input,
  .jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .jeeta-app .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app .questionCardListFilter .filters .searchTextInput input input,
  .jeeta-app .questionCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdown input,
  .jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .jeeta-app * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .filterSelect input input,
  .jeeta-app * .questionCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdown input,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdown .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple input,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput input input,
  .jeeta-app * .questionCardListFilter .filters .questionIDInput input .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdown input,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput input input,
  .jeeta-app * .questionCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .filterSelect .selectionDropdown input,
  .customModal .questionCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .customModal .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .filterSelect input input,
  .customModal .questionCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .questionIDInput .selectionDropdown input,
  .customModal .questionCardListFilter .filters .questionIDInput .selectionDropdown .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple input,
  .customModal .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .questionIDInput input input,
  .customModal .questionCardListFilter .filters .questionIDInput input .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .searchTextInput .selectionDropdown input,
  .customModal .questionCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .customModal .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .customModal .questionCardListFilter .filters .searchTextInput input input,
  .customModal .questionCardListFilter .filters .searchTextInput input .css-13cymwt-control, .customModal * .questionCardListFilter .filters .filterSelect .selectionDropdown input,
  .customModal * .questionCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .customModal * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .customModal * .questionCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .customModal * .questionCardListFilter .filters .filterSelect input input,
  .customModal * .questionCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdown input,
  .customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdown .css-13cymwt-control,
  .customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple input,
  .customModal * .questionCardListFilter .filters .questionIDInput .selectionDropdownMultiple .css-13cymwt-control,
  .customModal * .questionCardListFilter .filters .questionIDInput input input,
  .customModal * .questionCardListFilter .filters .questionIDInput input .css-13cymwt-control,
  .customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdown input,
  .customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .customModal * .questionCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .customModal * .questionCardListFilter .filters .searchTextInput input input,
  .customModal * .questionCardListFilter .filters .searchTextInput input .css-13cymwt-control {
    font-size: 0.9rem;
  }
  .jeeta-app .questionCardListFilter .checkInput,
  .jeeta-app * .questionCardListFilter .checkInput,
  .customModal .questionCardListFilter .checkInput, .customModal * .questionCardListFilter .checkInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.5rem;
    gap: 0.5rem;
  }
}/*# sourceMappingURL=QuestionCard.css.map */