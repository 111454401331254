.jeeta-app .loginForm,
.jeeta-app * .loginForm,
.customModal .loginForm,
.customModal * .loginForm {
  width: 800px;
  max-width: 80vw;
  height: -moz-max-content;
  height: max-content;
  min-height: 180px;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
}
.jeeta-app .loginForm .loginInfoInput,
.jeeta-app * .loginForm .loginInfoInput,
.customModal .loginForm .loginInfoInput,
.customModal * .loginForm .loginInfoInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}
.jeeta-app .loginForm .loginInfoInput .inputTitle,
.jeeta-app * .loginForm .loginInfoInput .inputTitle,
.customModal .loginForm .loginInfoInput .inputTitle,
.customModal * .loginForm .loginInfoInput .inputTitle {
  width: 1.5rem;
  text-align: center;
  font-weight: 500;
}
.jeeta-app .loginForm .loginInfoInput .idInput,
.jeeta-app .loginForm .loginInfoInput .pwInput,
.jeeta-app * .loginForm .loginInfoInput .idInput,
.jeeta-app * .loginForm .loginInfoInput .pwInput,
.customModal .loginForm .loginInfoInput .idInput,
.customModal .loginForm .loginInfoInput .pwInput,
.customModal * .loginForm .loginInfoInput .idInput,
.customModal * .loginForm .loginInfoInput .pwInput {
  align-items: center;
  width: 350px;
  height: 40px;
  padding: 0 1rem;
  background: var(--inputColor);
  border-radius: 3rem;
}
.jeeta-app .loginForm .loginInfoInput .idInput input,
.jeeta-app .loginForm .loginInfoInput .pwInput input,
.jeeta-app * .loginForm .loginInfoInput .idInput input,
.jeeta-app * .loginForm .loginInfoInput .pwInput input,
.customModal .loginForm .loginInfoInput .idInput input,
.customModal .loginForm .loginInfoInput .pwInput input,
.customModal * .loginForm .loginInfoInput .idInput input,
.customModal * .loginForm .loginInfoInput .pwInput input {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 500;
}
.jeeta-app .loginForm .loginInfoInput .idInput input::-moz-placeholder, .jeeta-app .loginForm .loginInfoInput .pwInput input::-moz-placeholder, .jeeta-app * .loginForm .loginInfoInput .idInput input::-moz-placeholder, .jeeta-app * .loginForm .loginInfoInput .pwInput input::-moz-placeholder, .customModal .loginForm .loginInfoInput .idInput input::-moz-placeholder, .customModal .loginForm .loginInfoInput .pwInput input::-moz-placeholder, .customModal * .loginForm .loginInfoInput .idInput input::-moz-placeholder, .customModal * .loginForm .loginInfoInput .pwInput input::-moz-placeholder {
  font-size: 0.9rem;
}
.jeeta-app .loginForm .loginInfoInput .idInput input::placeholder,
.jeeta-app .loginForm .loginInfoInput .pwInput input::placeholder,
.jeeta-app * .loginForm .loginInfoInput .idInput input::placeholder,
.jeeta-app * .loginForm .loginInfoInput .pwInput input::placeholder,
.customModal .loginForm .loginInfoInput .idInput input::placeholder,
.customModal .loginForm .loginInfoInput .pwInput input::placeholder,
.customModal * .loginForm .loginInfoInput .idInput input::placeholder,
.customModal * .loginForm .loginInfoInput .pwInput input::placeholder {
  font-size: 0.9rem;
}
.jeeta-app .loginForm .loginInfoInput .idInput .icon,
.jeeta-app .loginForm .loginInfoInput .pwInput .icon,
.jeeta-app * .loginForm .loginInfoInput .idInput .icon,
.jeeta-app * .loginForm .loginInfoInput .pwInput .icon,
.customModal .loginForm .loginInfoInput .idInput .icon,
.customModal .loginForm .loginInfoInput .pwInput .icon,
.customModal * .loginForm .loginInfoInput .idInput .icon,
.customModal * .loginForm .loginInfoInput .pwInput .icon {
  font-size: 1.3rem;
  color: var(--PrimaryColor) !important;
  margin-left: 10px;
}
.jeeta-app .loginForm .remember-forgot,
.jeeta-app * .loginForm .remember-forgot,
.customModal .loginForm .remember-forgot,
.customModal * .loginForm .remember-forgot {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.jeeta-app .loginForm .remember-forgot .remember-checkbox,
.jeeta-app .loginForm .remember-forgot .forgot-btn,
.jeeta-app * .loginForm .remember-forgot .remember-checkbox,
.jeeta-app * .loginForm .remember-forgot .forgot-btn,
.customModal .loginForm .remember-forgot .remember-checkbox,
.customModal .loginForm .remember-forgot .forgot-btn,
.customModal * .loginForm .remember-forgot .remember-checkbox,
.customModal * .loginForm .remember-forgot .forgot-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  gap: 0.3rem;
}
.jeeta-app .loginForm .remember-forgot .remember-checkbox input,
.jeeta-app .loginForm .remember-forgot .forgot-btn input,
.jeeta-app * .loginForm .remember-forgot .remember-checkbox input,
.jeeta-app * .loginForm .remember-forgot .forgot-btn input,
.customModal .loginForm .remember-forgot .remember-checkbox input,
.customModal .loginForm .remember-forgot .forgot-btn input,
.customModal * .loginForm .remember-forgot .remember-checkbox input,
.customModal * .loginForm .remember-forgot .forgot-btn input {
  cursor: pointer;
  accent-color: var(--tiger-lilly);
}
.jeeta-app .loginForm .remember-forgot .forgot-btn,
.jeeta-app * .loginForm .remember-forgot .forgot-btn,
.customModal .loginForm .remember-forgot .forgot-btn,
.customModal * .loginForm .remember-forgot .forgot-btn {
  padding-left: 1.5rem;
  color: var(--tiger-lilly);
  font-weight: 600;
  font-size: 0.9rem;
}
.jeeta-app .loginForm .remember-forgot .forgot-btn:hover,
.jeeta-app * .loginForm .remember-forgot .forgot-btn:hover,
.customModal .loginForm .remember-forgot .forgot-btn:hover,
.customModal * .loginForm .remember-forgot .forgot-btn:hover {
  cursor: pointer;
  text-decoration: underline;
}
.jeeta-app .loginForm .remember-forgot .forgot-btn .icon,
.jeeta-app * .loginForm .remember-forgot .forgot-btn .icon,
.customModal .loginForm .remember-forgot .forgot-btn .icon,
.customModal * .loginForm .remember-forgot .forgot-btn .icon {
  font-size: 1.2rem;
}
.jeeta-app .loginForm .btn,
.jeeta-app * .loginForm .btn,
.customModal .loginForm .btn,
.customModal * .loginForm .btn {
  width: -moz-max-content;
  width: max-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  border-radius: 3rem;
  border: none;
  margin: 0.5rem 0;
  padding: 0.6rem 2rem;
  background-color: var(--gradientColor);
  color: var(--whiteColor);
}
.jeeta-app .loginForm .btn:hover,
.jeeta-app * .loginForm .btn:hover,
.customModal .loginForm .btn:hover,
.customModal * .loginForm .btn:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
}
.jeeta-app .loginForm .btn .icon,
.jeeta-app * .loginForm .btn .icon,
.customModal .loginForm .btn .icon,
.customModal * .loginForm .btn .icon {
  font-size: 1.2rem;
  font-weight: 500;
}
.jeeta-app .loginForm .btn span,
.jeeta-app * .loginForm .btn span,
.customModal .loginForm .btn span,
.customModal * .loginForm .btn span {
  font-size: 1rem;
  font-weight: 500;
}
.jeeta-app .resetPasswordModal,
.jeeta-app * .resetPasswordModal,
.customModal .resetPasswordModal,
.customModal * .resetPasswordModal {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: var(--whiteColorDeam);
  padding: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.jeeta-app .resetPasswordModal .text-box,
.jeeta-app .resetPasswordModal .input-box,
.jeeta-app * .resetPasswordModal .text-box,
.jeeta-app * .resetPasswordModal .input-box,
.customModal .resetPasswordModal .text-box,
.customModal .resetPasswordModal .input-box,
.customModal * .resetPasswordModal .text-box,
.customModal * .resetPasswordModal .input-box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .resetPasswordModal .email-input,
.jeeta-app * .resetPasswordModal .email-input,
.customModal .resetPasswordModal .email-input,
.customModal * .resetPasswordModal .email-input {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}
.jeeta-app .resetPasswordModal .email-input label,
.jeeta-app * .resetPasswordModal .email-input label,
.customModal .resetPasswordModal .email-input label,
.customModal * .resetPasswordModal .email-input label {
  width: -moz-max-content;
  width: max-content;
  font-weight: 600;
}
.jeeta-app .resetPasswordModal .email-input input,
.jeeta-app * .resetPasswordModal .email-input input,
.customModal .resetPasswordModal .email-input input,
.customModal * .resetPasswordModal .email-input input {
  width: 300px;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 0.3rem;
}
.jeeta-app .resetPasswordModal .password-reset-message,
.jeeta-app * .resetPasswordModal .password-reset-message,
.customModal .resetPasswordModal .password-reset-message,
.customModal * .resetPasswordModal .password-reset-message {
  text-align: center;
  color: var(--textColor);
}
.jeeta-app .resetPasswordModal #close,
.jeeta-app * .resetPasswordModal #close,
.customModal .resetPasswordModal #close,
.customModal * .resetPasswordModal #close {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  color: var(--PrimaryColor);
  align-self: center;
}
.jeeta-app .resetPasswordModal #close:hover,
.jeeta-app * .resetPasswordModal #close:hover,
.customModal .resetPasswordModal #close:hover,
.customModal * .resetPasswordModal #close:hover {
  color: var(--tiger-lilly);
}
@media all and (max-width: 860px) {
  .jeeta-app .loginForm,
  .jeeta-app * .loginForm,
  .customModal .loginForm,
  .customModal * .loginForm {
    width: 80vw;
  }
  .jeeta-app .loginForm .loginInfoInput,
  .jeeta-app .loginForm .remember-forgot,
  .jeeta-app * .loginForm .loginInfoInput,
  .jeeta-app * .loginForm .remember-forgot,
  .customModal .loginForm .loginInfoInput,
  .customModal .loginForm .remember-forgot,
  .customModal * .loginForm .loginInfoInput,
  .customModal * .loginForm .remember-forgot {
    transform: scale(0.95);
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .loginForm,
  .jeeta-app * .loginForm,
  .customModal .loginForm,
  .customModal * .loginForm {
    gap: 0.5rem;
  }
  .jeeta-app .loginForm .loginInfoInput,
  .jeeta-app .loginForm .remember-forgot,
  .jeeta-app * .loginForm .loginInfoInput,
  .jeeta-app * .loginForm .remember-forgot,
  .customModal .loginForm .loginInfoInput,
  .customModal .loginForm .remember-forgot,
  .customModal * .loginForm .loginInfoInput,
  .customModal * .loginForm .remember-forgot {
    transform: scale(0.9);
  }
}/*# sourceMappingURL=LoginForm.css.map */