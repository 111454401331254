.jeeta-app .doubt-cluster-card,
.jeeta-app * .doubt-cluster-card {
  background-color: var(--PrimaryBkg);
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.jeeta-app .doubt-cluster-card:hover,
.jeeta-app * .doubt-cluster-card:hover {
  background-color: var(--SecondaryBkg);
  cursor: pointer;
}
.jeeta-app .doubt-cluster-card .doubt-cluster-text,
.jeeta-app * .doubt-cluster-card .doubt-cluster-text {
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--whiteColor);
}
.jeeta-app .doubt-cluster-card.not-expanded,
.jeeta-app * .doubt-cluster-card.not-expanded {
  justify-content: center;
  max-height: 3rem;
  overflow: hidden;
}
.jeeta-app .doubt-cluster-card.expanded,
.jeeta-app * .doubt-cluster-card.expanded {
  background-color: var(--SecondaryBkg);
  justify-content: flex-start;
  max-height: 18rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
.jeeta-app .doubt-cluster-card.expanded:hover,
.jeeta-app * .doubt-cluster-card.expanded:hover {
  background-color: var(--PrimaryBkg);
}
.jeeta-app .doubt-cluster-card.expanded .doubt-cluster-text,
.jeeta-app * .doubt-cluster-card.expanded .doubt-cluster-text {
  margin-bottom: 0.5rem;
}
.jeeta-app .doubt-card,
.jeeta-app * .doubt-card {
  background: var(--glass);
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.jeeta-app .doubt-card .doubt-text,
.jeeta-app * .doubt-card .doubt-text {
  font-size: 0.9rem;
  color: var(--textColor);
}
@media all and (max-width: 860px) {
  .jeeta-app .doubt-cluster-card .doubt-cluster-text,
  .jeeta-app * .doubt-cluster-card .doubt-cluster-text {
    text-align: center;
    font-weight: 600;
    font-size: 1.1rem;
    color: var(--whiteColor);
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .doubt-cluster-card,
  .jeeta-app * .doubt-cluster-card {
    padding: 0.5rem;
  }
  .jeeta-app .doubt-cluster-card .doubt-cluster-text,
  .jeeta-app * .doubt-cluster-card .doubt-cluster-text {
    font-size: 1.1rem;
  }
}/*# sourceMappingURL=Doubt.css.map */