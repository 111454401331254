.analytics-chart-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.analytics-chart-section {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 0.5rem;
}
.analytics-chart-section .analytics-chart-section-title {
  width: 100%;
  color: var(--textColor);
  text-align: center;
}

.treemap-leaf-container {
  position: relative;
}/*# sourceMappingURL=AnalyticsCharts.css.map */