.jeeta-app .navbar, .jeeta-app * .navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 2rem;
  background: var(--whiteColorDeam);
  box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);
  z-index: 1000;
}
.jeeta-app .navbar .navBtns, .jeeta-app * .navbar .navBtns {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 97%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jeeta-app .navbar .navBtns .leftBtns, .jeeta-app * .navbar .navBtns .leftBtns {
  align-items: center;
}
.jeeta-app .navbar .navBtns .leftBtns .logoDiv, .jeeta-app * .navbar .navBtns .leftBtns .logoDiv {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--PrimaryColor);
  font-weight: 500;
}
.jeeta-app .navbar .navBtns .leftBtns .logoDiv:hover, .jeeta-app * .navbar .navBtns .leftBtns .logoDiv:hover {
  cursor: pointer;
}
.jeeta-app .navbar .navBtns .leftBtns .logoDiv .companyLogo, .jeeta-app * .navbar .navBtns .leftBtns .logoDiv .companyLogo {
  width: 35px;
  height: 35px;
  transform: translateY(-2px);
}
.jeeta-app .navbar .navBtns .leftBtns .logoDiv .companyTitle, .jeeta-app * .navbar .navBtns .leftBtns .logoDiv .companyTitle {
  font-size: 1.5rem;
  font-weight: 700;
}
.jeeta-app .navbar .navBtns .rightBtns, .jeeta-app * .navbar .navBtns .rightBtns {
  align-items: center;
}
.jeeta-app .navbar .navBtns .rightBtns .icon, .jeeta-app * .navbar .navBtns .rightBtns .icon {
  font-size: 30px;
  color: var(--PrimaryColor);
  text-decoration: none;
}
.jeeta-app .navbar .navBtns .rightBtns .icon:hover, .jeeta-app * .navbar .navBtns .rightBtns .icon:hover {
  cursor: pointer;
  color: var(--SecondaryColor);
}
.jeeta-app .navbar .navBtns .rightBtns #toggleMenu, .jeeta-app * .navbar .navBtns .rightBtns #toggleMenu {
  display: none;
}
.jeeta-app .navbar .menu, .jeeta-app * .navbar .menu {
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  font-size: 15px;
  color: var(--textColor);
  z-index: 1001;
}
.jeeta-app .navbar .menu .navList, .jeeta-app * .navbar .menu .navList {
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}
.jeeta-app .navbar .menu .navList .navItem,
.jeeta-app .navbar .menu .navList .navItem-selected, .jeeta-app * .navbar .menu .navList .navItem,
.jeeta-app * .navbar .menu .navList .navItem-selected {
  padding: 0 0.5rem;
}
.jeeta-app .navbar .menu .navList .navItem .navLink,
.jeeta-app .navbar .menu .navList .navItem-selected .navLink, .jeeta-app * .navbar .menu .navList .navItem .navLink,
.jeeta-app * .navbar .menu .navList .navItem-selected .navLink {
  text-decoration: none;
  color: var(--textColor);
  font-weight: 500;
}
.jeeta-app .navbar .menu .navList .navItem .navLink:hover,
.jeeta-app .navbar .menu .navList .navItem-selected .navLink:hover, .jeeta-app * .navbar .menu .navList .navItem .navLink:hover,
.jeeta-app * .navbar .menu .navList .navItem-selected .navLink:hover {
  color: var(--PrimaryColor);
  cursor: pointer;
}
.jeeta-app .navbar .menu .navList .navItem #selected,
.jeeta-app .navbar .menu .navList .navItem-selected #selected, .jeeta-app * .navbar .menu .navList .navItem #selected,
.jeeta-app * .navbar .menu .navList .navItem-selected #selected {
  color: var(--PrimaryColor);
}
@media all and (max-width: 860px) {
  .jeeta-app .navbar, .jeeta-app * .navbar {
    padding: 0.5rem 2rem;
  }
  .jeeta-app .navbar .menu-iconVisible, .jeeta-app * .navbar .menu-iconVisible {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 15px;
    color: var(--textColor);
    z-index: 1001;
  }
  .jeeta-app .navbar .menu-iconVisible .navList, .jeeta-app * .navbar .menu-iconVisible .navList {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem, .jeeta-app * .navbar .menu-iconVisible .navList .navItem {
    padding: 0 0.5rem;
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem .navLink, .jeeta-app * .navbar .menu-iconVisible .navList .navItem .navLink {
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: var(--textColor);
    font-weight: 500;
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem .navLink:hover, .jeeta-app * .navbar .menu-iconVisible .navList .navItem .navLink:hover {
    color: var(--PrimaryColor);
    cursor: pointer;
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem .navLink .navIcon, .jeeta-app * .navbar .menu-iconVisible .navList .navItem .navLink .navIcon {
    transform: translateY(0.15rem);
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem .navLink .navIcon .icon, .jeeta-app * .navbar .menu-iconVisible .navList .navItem .navLink .navIcon .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem .navLink .navIcon span, .jeeta-app * .navbar .menu-iconVisible .navList .navItem .navLink .navIcon span {
    font-size: 0.7rem;
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem .navLink .navIcon:hover, .jeeta-app * .navbar .menu-iconVisible .navList .navItem .navLink .navIcon:hover {
    color: var(--PrimaryColor);
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem #selected, .jeeta-app * .navbar .menu-iconVisible .navList .navItem #selected {
    color: var(--PrimaryColor);
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem #selected .navIcon, .jeeta-app * .navbar .menu-iconVisible .navList .navItem #selected .navIcon {
    color: var(--PrimaryColor);
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .navbar .menu-iconVisible .navList, .jeeta-app * .navbar .menu-iconVisible .navList {
    gap: 0.5rem;
  }
  .jeeta-app .navbar .menu-iconVisible .navList .navItem, .jeeta-app * .navbar .menu-iconVisible .navList .navItem {
    padding: 0;
    transform: scale(0.95);
  }
}/*# sourceMappingURL=navbar.css.map */