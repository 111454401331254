.jeeta-app .Cards,
.jeeta-app * .Cards {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .cardContainer,
.jeeta-app * .cardContainer {
  flex: 1;
  max-width: 450px;
}
@media all and (max-width: 1200px) {
  .jeeta-app .Cards,
  .jeeta-app * .Cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 columns */
    grid-gap: 1rem;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .Cards,
  .jeeta-app * .Cards {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .jeeta-app .cardContainer,
  .jeeta-app * .cardContainer {
    min-width: 75%;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .cardContainer,
  .jeeta-app * .cardContainer {
    min-width: 90%;
  }
}/*# sourceMappingURL=Cards.css.map */